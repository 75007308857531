var $ = require('jquery');
require('nanoscroller');

var changePopup = function(way){
	var index = $('.team__popup.is-active').index();
	console.log('index', index);
	index += way;
	if ( index < 0 ) {
		index = $('.team__popup').length - 1;
	}
	if ( index >= $('.team__popup').length ) {
		index = 0;
	}
	$('.team__popup').removeClass('is-active').eq(index).addClass('is-active');
	$('.team__popup.is-active').find('.nano')
		.nanoScroller({ destroy: true })
		.nanoScroller({ alwaysVisible: true });
};

$( document ).on('click', '[data-js="open-people-popup"]', function(evt){
	var id = $(evt.currentTarget).attr('data-people-id');
	$('.team__popup')
		.removeClass('is-active')
		.filter('[data-people-popup-id="'+id+'"]')
		.addClass('is-active');
		$('.team__popup.is-active').find('.nano')
			.nanoScroller({ destroy: true })
			.nanoScroller({ alwaysVisible: true });
});

$( document ).on('click', '[data-js="close-people-popup"]', function(evt){
	$('.team__popup').removeClass('is-active');
});

$( document ).on('click', '[data-js="popup-prev"]', function(evt){
	changePopup(-1);
});

$( document ).on('click', '[data-js="popup-next"]', function(evt){
	changePopup(1);
});

$(window).on('site:initPage', function(evt, el){
	
});

