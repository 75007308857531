var $ = require('jquery');
var reveals = require('./0reveals');

reveals('bloc-accueil-1', function(el, tl){
    tl.fromTo( $(el).find('.bloc__accueil__1__img__mask'), 0.5, {
        transformOrigin: '0% 0%',
        scaleX: 0
    }, {
        scaleX: 1,
        ease: Circ.easeInOut
    }, 0.25);
    tl.fromTo( $(el).find('.bloc__contact__head__titre__rect'), 0.5, {
        transformOrigin: '100% 0%',
        scaleX: 0
    }, {
        scaleX: 1,
        ease: Circ.easeInOut
    }, 0.25);
    tl.fromTo( $(el).find('.bloc__accueil__1__img__wrap'), 0.8, {
        transformOrigin: '100% 0%',
        x: '100%'
    }, {
        x: '0%',
        ease: Circ.easeInOut
    }, 0); 
    tl.fromTo( $(el).find('.bloc__accueil__1__img__wrap img'), 0.8, {
        transformOrigin: '100% 0%',
        x: '-100%'
    }, {
        x: '0%',
        ease: Circ.easeInOut
    }, 0);
    tl.fromTo( $(el).find('.bloc__accueil__1__titre__bloc, .bloc__contact__head__titre__wrap__inner'), 0.5, {
        transformOrigin: '0% 0%',
        opacity: 0,
        x: '-10%'
    }, {
        x: '0%',
        opacity: 1,
        ease: Circ.easeInOut
    }, 0.45);
    tl.fromTo( $(el).find('.bandeau'), 0.5, {
        transformOrigin: '50% 50%',
        scaleY: 0,
        opacity: 0
        // x: '-10%'
    }, {
        // x: '0%',
        scaleY: 1,
        opacity: 1,
        ease: Circ.easeInOut
    }, 0.55);

    return tl;
});
