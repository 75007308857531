(function(window, factory) {
	'use strict';
	// if (typeof exports !== 'undefined') {
		module.exports = factory(
			window,
			require('jquery'),
			require('ismobile'),
			require('raf')
		);
	// } else {
	// 	factory(window, jQuery);
	// }

}(window, function(window, $, is_mobile, raf) {
	var canScroll = true,
		hasRequestedScroll = false,
		$window = $(window),
		scroll = 'scroll',
		namespace = 'cube',
		lastScrollTop = -2,
		scrollTop = -1;

	

	var onScrollEvent = function() {
		if (canScroll) {
			canScroll = false;
			scrollHandler();
			setTimeout(function() {
				if (hasRequestedScroll) {
					hasRequestedScroll = false;
					scrollHandler();
				}
				canScroll = true;
			}, 300);
		} else {
			hasRequestedScroll = true;
		}
	};

	var scrollHandler = function() {
		scrollTop = window.pageYOffset;

		if ( scrollTop !== lastScrollTop ) {
			$window.trigger(namespace+':'+scroll, [scrollTop]);
			lastScrollTop = scrollTop;
		}
	};

	var ticking = false;

	var onScrollEventRaf = function() {
		// console.log('onScrollEventRaf');
		// scrollTop = window.pageYOffset;
		// scrollTop = window.scrollY;
		scrollTop = window.scrollY;
		requestTick();
	};

	var requestTick = function() {
		if(!ticking) {
	        requestAnimationFrame(refresh);
	        ticking = true;
	    }
	};

	var refresh = function() {
		// console.log('refresh');
		// if ( scrollTop !== lastScrollTop ) {
			// console.log('scrollTop', scrollTop);
			ticking = false;
			$window.trigger(namespace+':'+scroll, [scrollTop]);
			lastScrollTop = scrollTop;
		// }
		// raf(refresh);
	};

	var use_raf = true;

	if ( use_raf ) {
		$window.on(scroll, onScrollEventRaf);
		refresh();
	}
	else {
		$window.on(scroll, onScrollEvent);
	}
	$window.trigger(scroll);





}));