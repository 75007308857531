var $ = require('jquery');
var reveals = require('./0reveals');

reveals('bloc-contact-us', function(el, tl){
    tl.fromTo( $(el).find('.contactus__rect'), 0.5, {
        transformOrigin: '100% 0%',
        scaleX: 0
    }, {
        scaleX: 1,
        ease: Circ.easeInOut
    }, 0);
    tl.staggerFromTo( $(el).find('.contactus__titre, .contactus__sstitre, .contactus__txt').get(), 0.5, {
        x: '10%',
        opacity: 0
    }, {
        x: '0%',
        opacity: 1,
        ease: Circ.easeOut
    }, 0.1, 0.3);
    tl.staggerFromTo( $(el).find('.contact__form__field, .form__submit').get(), 0.5, {
        y: '20rem',
        opacity: 0
    }, {
        y: '0rem',
        opacity: 1,
        ease: Circ.easeOut
    }, 0.1, 0.6);
    return tl;
});
