var $ = require('jquery');
var cymasmooth = require('cymasmooth');
var imageloaderadd = require('imagesloader');
// var imagesloadedwithsize = require('imagesloadedwithsize');

var cached_pages = {};
var hrefs = [];
var init_done = false;

var init_cache = function(el, undefined){
	if ( !init_done ) {
		var q = (el === undefined) ? '.footer a ' : el;
		$(q).each(function(i, el){
			if ( $(el).attr('target') != 'blank' && $(el).attr('target') != '_blank' ) {
				hrefs.push(el.href);
			}
		});
		init_done = true;
		cached_pages[window.location.toString()] = $('html').html();
	}
	// console.log('hrefs', hrefs);
	// console.log('cached_pages', cached_pages);
	checkNextPages();
};

var getBgSrc = function(el){
	var reURL = /url\((['"])?(.*?)\1\)/gi;
	var matches = reURL.exec( el.style.backgroundImage );
	if ( matches !== null ) {
		var url = matches && matches[2];
		if ( url ) {
			console.log('urlbg', url);
			return url;
		}
	}
	return '';
};

var checkNextPages = function(){
	// console.log('checkNextPages');
	// console.log('hrefs', hrefs);
	if ( hrefs.length > 0 ) {
		var href = hrefs[0];
		hrefs.shift();
		if ( !cached_pages[href] ) {
			getPageDatas(href);
		}
		else {
			checkNextPages();
		}
	}
	else {
		// console.log('cached_pages', cached_pages);
		// send all iages urls founded in cached pages to image loader
		for ( var k in cached_pages ) {
			if ( cached_pages[k] ) {
				var html = document.createElement( 'div' );
				html.innerHTML = cached_pages[k];
				$( html ).find('[data-asset="page"]').each(function(i, el){
					var src = el.src || getBgSrc(el);
					imageloaderadd(src);
				});
			}
		}
	}
};

var getPageDatas = function(href){
	// console.log('getPageDatas', href);
	$.ajax({url: href, context:{url: href}, success: ajaxSuccessCB});
};

var ajaxSuccessCB = function(rep){
	// console.log('ajaxSuccessCB');
	var url = this.url;
	console.log('url', url);
	cached_pages[url] = rep;
	checkNextPages();
};

cymasmooth.registerFunc('onEnd', function(evt, el){
	// alert('hh')
	// init_cache();
	$(window).trigger('cymasmooth:onEnd');
});

var onAjaxLoadBlocking = function(url){
	var can_continue = true;
	console.log('cached_pages', cached_pages);
	if ( cached_pages[url] ) {
		console.log('use cache');
		cymasmooth.ajaxSuccess(cached_pages[url], url);
		can_continue = false;
	}
	return can_continue;
};

cymasmooth.registerFunc('onAjaxLoadBlocking', onAjaxLoadBlocking);

module.exports = {
	init: init_cache,
	onAjaxLoadBlocking: onAjaxLoadBlocking
};

