var $ = require('jquery');
var logo_anim = require('logo-anim');
var cymasmooth = require('cymasmooth');
require('wrapmask');
var isLeft = require('is-left');
var MQ = require('mq');

var shuffle = require('shuffle');

$('body').on('reveal-instan-pourquoi', '.scroll-reveal', function(evt){
	var el = $(evt.currentTarget);
	var tl = new TimelineMax({paused : true});
	if ( MQ(768) ){
		tl.fadeUp( $(el).find('.home__pourquoi__blocs__item__wrap') );
	}
	else {
		tl.fadeUp( $(el) );
	}
	tl.play();
	// el.remove();
});

$('body').on('reveal-odeon-mosaique', '.scroll-reveal', function(evt){
	var el = $(evt.currentTarget);
	var tl = new TimelineMax({paused : true});
	var center_x = el.offset().left + ( el.width() * 0.5 );
	console.log('center_x', center_x);
	var center_y = el.offset().top + ( el.height() * 0.5 );
	console.log('center_y', center_y);
	var f = -100;
	$(shuffle($(el).find('.quartier__mosaique__visuels__item, .quartier__mosaique__titre').get())).each(function(i, el){
		el = $(el);
		var x = el.offset().left + ( el.width() * 0.5 );
		console.log('x', x);
		var y = el.offset().top + ( el.height() * 0.5 );
		console.log('y', y);
		x = x - center_x;
		console.log('x', x);
		y = y - center_y;
		console.log('y', y);
		var l = Math.sqrt( x * x + y * y );
		x = x * 1 / l;
		y = y * 1 / l;
		console.log('x', x);
		console.log('y', y);
		tl.from( el, 1.5, {
			opacity: 0,
			x: ( x * f ) + 'rem',
			y: ( y * f ) + 'rem',
			ease: 'power2.out'
		}, 0.2 + ( 0.03 * i ) );
		console.log('-----------');
	});
	tl.play();
	// el.remove();
});

$('body').on('reveal-odeon-plans-slide', '.scroll-reveal', function(evt){
	var el = $(evt.currentTarget);
	var tl = gsap.timeline({paused : true});
	var els = [];
	els.push( el.find('.plans__blocs__slides__items__nbs').get(0) );
	els.push( el.find('.plans__blocs__slides__items__wrap').get(0) );
	els.push( el.find('.plans__blocs__legendes').get(0) );
	tl.fadeUp( els );
	tl.play();
	// el.remove();
});

$('body').on('reveal-odeon-page-head', '.scroll-reveal', function(evt){
	var el = $(evt.currentTarget);
	var tl = gsap.timeline({paused : true});
	if ( el.find('.immeuble__header__visuel__inner').length ){
		tl.deroul( el.find('.immeuble__header__visuel__inner'), {
			duration: 1.5,
			ease: 'power2.inOut'
		});
	}
	if ( el.find('.contact__header__bg').length ){
		tl.from( el.find('.contact__header__bg'), {
			scaleY: 0,
			transformOrigin: '0% 0%',
			duration: 1.5,
			ease: 'power2.inOut'
		});
	}
	tl.fadeUp( el.find('.immeuble__header__texte, .immeuble__header__title__wrap, .contact__header__logo, .contact__header__visuels__items__bg, .contact__header__visuels__item'), 0.8 );
	tl.from( el.find('.immeuble__header____scrolldown'), {
		duration: 1,
		opacity: 0,
		y: '-100%'
	}, 1.3);
	tl.play();
	// el.remove();
});

$('body').on('reveal-deroul', '.scroll-reveal', function(evt){
	// var el = $(evt.currentTarget).addClass('reveal');
	var el = $(evt.currentTarget);
	var tl = new TimelineMax({paused : true});
	var direction = 'down';
	if ( el.attr('data-direction') ){
		direction = el.attr('data-direction');
	}
	// if ( MQ(768) ){
		tl.deroul( $(el).find('img'), {
			direction: direction,
			factor: 0.5
		}, 0.1);
	// }
	// else {
	// 	tl.fadeUp( $(el).find('img') );
	// }
	setTimeout(function() {
		tl.play();
	}, 10);
});

$('body').on('reveal-home-slideshow', '.scroll-reveal', function(evt){
	var el = $(evt.currentTarget);

	el.find('.home__slideshow__slides__item').each(function(i, el){
		var label = $(el).find('.home__slideshow__slides__page__link__label');
		new SplitText(label, {type:'chars'});
		$(el).find('.home__slideshow__slides__sous__titre > div').wrapmask({
			pos: 'outer'
		}).childs.addClass('inner');
	});

	var tl = new TimelineMax({paused : true});


	// var sp = new SplitText(el, {type:'lines,words,chars'});
	tl.from( el.find('.home__slideshow__slides__items'), {
		duration: 1,
		opacity: 0,
		ease: 'power2.in'
	}, 0);
	tl.add(function(){
		showSlideItem( el.find('.home__slideshow__slides__item').eq(0) ).timeScale(1.5).play()
	}, 0)
	tl.from( el.find('.home__slideshow__slides__items__arrows'), {
		duration: 1.5,
		opacity: 0
	}, 2.2);
	tl.from( el.find('.home__slideshow__slides__items__nav'), {
		duration: 1.5,
		opacity: 0,
		stagger: 0.12
	}, 2.5);
	tl.from( el.find('.home__slideshow__scrolldown'), {
		y: '-100%',
		duration: 1.5,
		opacity: 0
	}, 3);

	tl.play();
	// el.remove();
});

var showSlideItem = function(el){
	var tl = gsap.timeline({paused : true})
	tl.fromTo( el.find('.home__slideshow__slides__visuel'), {
		scale: 1.1,
		opacity: 1
	}, {
		duration: 3,
		scale: 1.001,
		opacity: 1
	}, 0 );
	tl.fromTo( el.find('.home__slideshow__slides__item__nb__wrap'), {
		opacity: 0
	}, {
		duration: 1.5,
		opacity: 1
	}, 1.5 );
	tl.fromTo( el.find('.home__slideshow__slides__sous__titre .inner'), {
		y: '101%'
	}, {
		duration: 1.5,
		y: '0%',
		stagger: 0.2
	}, 2 );
	tl.fromTo( el.find('.home__slideshow__slides__page__link__label div, .home__slideshow__slides__page__link__arrow'), {
		opacity: 0
	}, {
		duration: 2,
		stagger: 0.07,
		opacity: 1,
		ease: 'none'
	}, 2.7 );
	return tl;
};

$('body').on('reveal-odeon-logo', '.scroll-reveal', function(evt){
	var el = $(evt.currentTarget);
	var tl = new TimelineMax({paused : true});
	// var sp = new SplitText(el, {type:'lines,words,chars'});
	tl.from( el.find('path').get().reverse(), {
		y: '101%',
		duration: 0.8,
		stagger: 0.1,
		opacity: 0
	});
	tl.play();
	// el.remove();
});

$('body').on('reveal-odeon-slide-a', '.scroll-reveal', function(evt){
	var el = $(evt.currentTarget);
	var tl = new TimelineMax({paused : true});
	// var sp = new SplitText(el, {type:'lines,words,chars'});
	tl.from( el.find('.home__chiffres__visuels__bg'), {
		duration: 1.5,
		scale: 0.7,
		opacity: 0,
		ease: 'power2.out'
	}, 0);
	tl.from( el.find('.home__chiffres__visuels__items__arrow.next'), {
		duration: 1.5,
		x: '100%',
		opacity: 0,
		ease: 'power2.out'
	}, 0.9 )
	tl.from( el.find('.home__chiffres__visuels__item.slick-active'), {
		duration: 1,
		scale: 0.95,
		opacity: 0,
		ease: 'power2.inOut',
		stagger: 0.12
	}, 0.1 )
	tl.from( el.find('.home__chiffres__visuels__items__nbs'), {
		duration: 1,
		y: '30rem',
		opacity: 0,
		ease: 'power2.out'
	}, 0.7 )
	tl.from( el.find('.home__chiffres__visuels__items__nav '), {
		duration: 1,
		opacity: 0,
		ease: 'power2.out',
		stagger: 0.08
	}, 0.85 )
	tl.play();
	// el.remove();
});

$('body').on('reveal-odeon-slide-b', '.scroll-reveal', function(evt){
	var el = $(evt.currentTarget);
	var tl = new TimelineMax({paused : true});
	// var sp = new SplitText(el, {type:'lines,words,chars'});
	tl.from( el.find('.immeuble__lieu__visuels__items '), {
		duration: 1,
		scale: 0.95,
		opacity: 0,
		ease: 'power2.inOut',
		stagger: 0.12
	} )
	tl.from( el.find('.home__chiffres__visuels__items__arrow.prev'), {
		duration: 1.5,
		x: '100%',
		opacity: 0,
		ease: 'power2.out'
	}, 0.9 )
	tl.from( el.find('.home__chiffres__visuels__items__arrow.next'), {
		duration: 1.5,
		x: '-100%',
		opacity: 0,
		ease: 'power2.out'
	}, 0.9 )
	tl.play();
	// el.remove();
});

$('body').on('reveal-home-title', '.scroll-reveal', function(evt){
	var el = $(evt.currentTarget);
	var tl = new TimelineMax({paused : true});
	// var sp = new SplitText(el, {type:'lines,words,chars'});
	var sp = new SplitText(el, {type:'chars'});
	tl.staggerFromTo( sp.chars, 1, {
		opacity: 0
	}, {	
		opacity: 1,
		ease: Power0.easeOut
	}, 0.05, 0 );
	tl.fromTo( $('.svg__epi--left'), 1, {
		opacity: 0,
		x: '100%',
		ease: Power1.easeOut
	}, {
		opacity: 1,
		x: '0%',
		ease: Power1.easeOut
	}, '-=1' );
	tl.fromTo( $('.svg__epi--right'), 1, {	
		opacity: 0,
		x: '-100%',
		ease: Power1.easeOut
	}, {
		opacity: 1,
		x: '0%',
		ease: Power1.easeOut
	}, '-=1' );
	tl.play();
	TweenMax.fromTo( tl, 3, {
		progress: 0
	}, {
		progress: 1,
		ease: Power1.easeOut
	} );
	// el.remove();
});

$('body').on('reveal-slideshow-multi-items', '.scroll-reveal', function(evt){
	// var el = $(evt.currentTarget).addClass('reveal');
	var el = $(evt.currentTarget);
	var tl = new TimelineMax({paused : true});
	var slide_items = [];
	var items = $(el).find('.insta__bloc__img');
	var odd = 5;
	if ( MQ(-768) ) {
		odd = 2;
	}
	// var total = Math.ceil(items.length / odd);
	for (var i = 0; i < items.length; i++) {
		if ( !slide_items[i % odd] ){
			slide_items[i % odd] = [];
		}
		slide_items[i % odd].push(items.get(i));
	}

	var delay = 0;
	for (var i = 0; i < slide_items.length; i++) {
		tl.fadeUp( slide_items[i], {
			yOrigin: '30rem',
			stagger: 0.00,
			duration: 1.15
		}, delay);
		delay += 0.1;
	}
	slide_items = [];
	slide_items.push( $(el).find('.insta__arrows').get() );
	// slide_items.push( $(el).find('.insta__arrow.prev').get() );
	// slide_items.push( $(el).find('.insta__arrow.next').get() );
	// slide_items.push( $(el).find('[data-js="slick-nav-items"]').get() );
	console.log('slide_items', slide_items);
	tl.fadeUp( slide_items, {
		yOrigin: '30rem',
		stagger: 0.10,
		duration: 1.15
	}, delay);
	tl.call(function(){
		$(el).find('[data-js="slideshow-items"]').slick('slickPlay');
	})
	setTimeout(function() {
		tl.play();
	}, 10);
});

$('body').on('reveal-home-head', '.scroll-reveal', function(evt){
	var el = $(evt.currentTarget);
	var tl = new TimelineMax({paused : true});
	tl.fromTo($(el), 0.1, {
		opacity: 0,
	}, {
		opacity: 1
	}, 0);
	tl.to($(el).find('.home__video__content__mask'), 1.5, {
		y:'101%',
		ease: Power2.easeInOut
	}, 0.1);
	// tl.deroul( $(el), {
	// 	y: '-100%',
	// 	ease: Power2.easeInOut,
	// 	duration: 15.5
	// }, 0.5);
	setTimeout(function() {
		tl.play();
	}, 25);
});

$('body').on('reveal-page-head', '.scroll-reveal', function(evt){
	var el = $(evt.currentTarget);
	var tl = new TimelineMax({paused : true});
	tl.fromTo($(el), 0.1, {
		opacity: 0,
	}, {
		opacity: 1
	}, 0);
	tl.deroul( $(el).find('.page__header__content'), {
		y: '-100%',
		ease: Power2.easeInOut,
		duration: 1.1
	}, 0);
	setTimeout(function() {
		tl.play();
	}, 25);
	
});

$('body').on('reveal-bloc-about', '.scroll-reveal', function(evt){
	var el = $(evt.currentTarget);
	var tl = new TimelineMax({paused : true});
	if ( MQ(768) ) {
		var direction = 'right';
		var offset = '30rem';
		if ( isLeft($(el).find('.about__blocs__visuel')) ){
			direction = 'left';
			offset = '-30rem';
		}
		tl.deroul( $(el).find('.about__blocs__visuel'), {
			ease: Power2.easeInOut,
			duration: 1.1,
			direction: direction
		});
		tl.from( $(el).find('.about__blocs__txts').children(), {
			ease: Power2.easeOut,
			opacity: 0,
			x: offset,
			duration: 1.1,
			stagger: 0.1
		}, 0.1);
	}
	else {
		var els = $(el).find('.about__blocs__visuel').add($(el).find('.about__blocs__txts').children());
		var els = $(el).find('.about__blocs__visuel').get().concat($(el).find('.about__blocs__txts').children().get());
		tl.from( els, {
			ease: Power2.easeOut,
			opacity: 0,
			y: '30rem',
			duration: 1.1,
			stagger: 0.1
		}, 0.1);
	}
	tl.play();
	
});

$('body').on('reveal-bloc-prod', '.scroll-reveal', function(evt){
	var el = $(evt.currentTarget);
	var tl = new TimelineMax({paused : true});
	if ( MQ(768) ) {
		var direction = 'right';
		var offset = '30rem';
		if ( isLeft($(el).find('.prods__blocs__visuel')) ){
			direction = 'left';
			offset = '-30rem';
		}
		tl.deroul( $(el).find('.prods__blocs__visuel'), {
			ease: Power2.easeInOut,
			duration: 1.1,
			direction: direction
		});
		tl.from( $(el).find('.prods__blocs__logo'), {
			ease: Power2.easeOut,
			opacity: 0,
			x: offset,
			duration: 1.1,
			stagger: 0.1
		}, 0.15);
		tl.from( $(el).find('.prods__blocs__item__txts__inner'), {
			ease: Power2.easeOut,
			opacity: 0,
			y: '30rem',
			duration: 1.1,
			stagger: 0.1
		}, 0.3);
	}
	else {
		// var els = $(el).find('.about__blocs__visuel').add($(el).find('.about__blocs__txts').children());
		var els = $(el).find('.prods__blocs__visuel').get().concat($(el).find('.prods__blocs__logo').get());
		// els = els.get().concat($(el).find('.prods__blocs__item__txts__inner').children().get());
		els = els.concat($(el).find('.prods__blocs__item__txts__inner').children().get());
		tl.from( els, {
			ease: Power2.easeOut,
			opacity: 0,
			y: '30rem',
			duration: 1.1,
			stagger: 0.1
		}, 0.1);
	}
	tl.play();
	
});

$('body').on('reveal-bloc-news-head', '.scroll-reveal', function(evt){
	var el = $(evt.currentTarget);
	var tl = new TimelineMax({paused : true});
	var p = $(el).parent();
	// alert(p.get(0))
	tl.deroul( $(p).find('.news__top'), {
		y: '-100%',
		ease: Power2.easeInOut,
		duration: 1.5
	});
	
	if ( MQ(768) ) {
		
		var els = $(p).find('.newss__menu__item').get().concat($(p).find('.newss__list__top__item').get());
		var delay = $(p).find('.newss__menu__item').length * 0.15;
		tl.fadeUp( els, {
			yOrigin: '30rem',
			stagger: 0.15,
			duration: 1.15
		}, 0.95 - delay);
	}
	else {
		var els = $(p).find('.newss__list__item__top__pho').get().concat($(p).find('.newss__menu__item').get());
		tl.fadeUp( els, {
			yOrigin: '30rem',
			stagger: 0.15,
			duration: 1.15
		}, 0.5);
	}
	tl.play();
	
});

$('body').on('reveal-bloc-news-detail-head', '.scroll-reveal', function(evt){
	var el = $(evt.currentTarget);
	var tl = new TimelineMax({paused : true});
	var p = $(el).parent();
	// alert(p.get(0))
	tl.deroul( $(p).find('.news__top'), {
		y: '-100%',
		ease: Power2.easeInOut,
		duration: 1.5
	});
	
	if ( MQ(768) ) {
		var els = $(p).find('.newss__aside__inner').children().get().concat($(p).find('.newss__detail').get());
		var delay = $(p).find('.newss__aside__inner').children().length * 0.15;
		tl.fadeUp( els, {
			yOrigin: '30rem',
			stagger: 0.15,
			duration: 1.15
		}, 0.95 - delay);
	}
	else {
		var els = $(p).find('.newss__aside__inner').children().get().concat($(p).find('.newss__detail').get());
		tl.fadeUp( els, {
			yOrigin: '30rem',
			stagger: 0.15,
			duration: 1.15
		}, 0.5);
	}
	tl.play();
	
});

$('body').on('reveal-home-slideshow', '.scroll-reveal', function(evt){
	var el = $(evt.currentTarget);
	var tl = new TimelineMax({paused : true});
	var wrapmask = $(el).find('.home__slideshow__items').wrapmask({cn:'ovh rel',mask:'mask'});
	tl.from( wrapmask.parents, 1, {
		opacity: 0,
		ease: Power0.easeOut
	}, 1 )
	tl.to( wrapmask.masks, 2, {
		y: '100%',
		ease: Power2.easeInOut
	}, 0 )
	tl.from( wrapmask.childs, 2, {
		y: '-10%',
		ease: Power2.easeInOut
	}, 0 )
	tl.from( $(el).find('.home__slideshow__navs'), 1, {
		opacity: 0,
		ease: Power0.easeInOut
	}, 1.75 )
	tl.play()
	return;
	// var sp = new SplitText(el, {type:'lines,words,chars'});
	var sp = new SplitText(el, {type:'chars'});
	tl.staggerFromTo( sp.chars, 1, {
		opacity: 0
	}, {	
		opacity: 1,
		ease: Power0.easeOut
	}, 0.05, 0 );
	tl.fromTo( $('.svg__epi--left'), 1, {
		opacity: 0,
		x: '100%',
		ease: Power1.easeOut
	}, {
		opacity: 1,
		x: '0%',
		ease: Power1.easeOut
	}, '-=1' );
	tl.fromTo( $('.svg__epi--right'), 1, {	
		opacity: 0,
		x: '-100%',
		ease: Power1.easeOut
	}, {
		opacity: 1,
		x: '0%',
		ease: Power1.easeOut
	}, '-=1' );
	// tl.play();
	TweenMax.fromTo( tl, 2, {
		progress: 0
	}, {
		progress: 1,
		ease: Power1.easeOut
	} );
});


$('body').on('reveal-fadein', '.scroll-reveal', function(evt){
	// var el = $(evt.currentTarget);
	// TweenMax.fromTo( el, 0.75, {
	// 	opacity: 0
	// }, {
	// 	opacity: 1,
	// 	ease: Power1.easeOut
	// } );
});

$('body').on('reveal-fadeup', '.scroll-reveal', function(evt){
	var el = $(evt.currentTarget).addClass('reveal');
});

$('body').on('reveal-faderight', '.scroll-reveal', function(evt){
	var el = $(evt.currentTarget).addClass('reveal');
});

$('body').on('reveal-drawright', '.scroll-reveal', function(evt){
	var el = $(evt.currentTarget).addClass('reveal');
});

$('body').on('reveal-css', '.scroll-reveal', function(evt){
	var el = $(evt.currentTarget).addClass('reveal');
});

$('body').on('reveal-pp-picto', '.scroll-reveal', function(evt){
	var el = $(evt.currentTarget).addClass('reveal');
	gsap.set( el, {x:0,y:0} );
});

$('body').on('reveal-css-lines', '.scroll-reveal', function(evt){
	// var el = $(evt.currentTarget).addClass('reveal');
	var el = $(evt.currentTarget);
	var delay = parseFloat(el.attr('data-lines-delay')) || 0.1;
	var classname = el.attr('class').split(' ');
	for(var i=0,j=classname.length;i<j;i++){
		var d = classname[i];
		if ( d.indexOf('reveal-') === 0 ) {
			classname = d;
			break;
		}
	}
	el.attr('debug-classname', classname+' '+delay);
	var lines = new SplitText(el.get(0), {type:'lines'}).lines;
	$(lines).addClass('scroll-reveal is-revealed '+classname);
	$(lines).each(function(i, eel){
		eel.offsetWidth;
	})
	$(el).removeClass('scroll-reveal '+classname);
	setTimeout(function() {
		var tl = new TimelineMax({paused : true});
		tl.staggerTo( lines, 0, {
			className: '+=reveal',
			ease: Linear.easeNone
		}, delay );
		tl.play();
	}, 10);
});

$('body').on('reveal-css-chars', '.scroll-reveal', function(evt){
	// var el = $(evt.currentTarget).addClass('reveal');
	var el = $(evt.currentTarget);
	var chars = new SplitText(el.get(0), {type:'lines,words,chars'}).chars;
	var tl = gsap.timeline({paused : true})
	tl.from( chars, {
		duration: 1,
		opacity: 0,
		ease: 'power1.in',
		stagger: 0.07
	} )
	tl.play();
});

$('body').on('reveal-evidence-slideshow', '.scroll-reveal', function(evt){
	// var el = $(evt.currentTarget).addClass('reveal');
	var el = $(evt.currentTarget);
	var wrapmask = el.wrapmask({cn:'ovh rel',mask:''});
	var p = wrapmask.parents;
	p.addClass('ppppp');
	var wrapmask2 = wrapmask.parents.wrapmask({cn:'ovh rel',mask:''});
	setTimeout(function() {
		var tl = new TimelineMax({paused : true});
		tl.from( p, 1, {
			y: '-100%',
			ease: Power2.easeOut
		}, 0 );
		tl.from( el, 1, {
			y: '80%',
			ease: Power2.easeOut
		}, 0 );
		var txt = $(el).find('.home__slideshow__item__txts__inner').eq(0);
		if ( txt ) {
			var lines = new SplitText(txt, {type:'lines'});
			tl.from( lines.lines, 1.3, {
				x: '-100rem',
				opacity: 0,
				stagger: 0.15,
				ease: Power2.easeOut
			}, 0.3 );
		}
		tl.play();
	}, 10);
});

$('body').on('reveal-evidence-actu-visuold', '.scroll-reveal', function(evt){
	// var el = $(evt.currentTarget).addClass('reveal');
	var el = $(evt.currentTarget).find('img');
	var wrapmask = el.wrapmask({cn:'ovh rel',mask:''});
	var p = wrapmask.parents;
	p.addClass('ppppp');
	var wrapmask2 = wrapmask.parents.wrapmask({cn:'ovh rel',mask:''});
	setTimeout(function() {
		var tl = new TimelineMax({paused : true});
		tl.from( p, 1, {
			y: '-100%',
			ease: Power2.easeOut
		}, 0 );
		tl.from( el, 1, {
			y: '80%',
			ease: Power2.easeOut
		}, 0 );
		tl.play();
	}, 10);
});

$('body').on('reveal-evidence-actu-visu', '.scroll-reveal', function(evt){
	// var el = $(evt.currentTarget).addClass('reveal');
	var el = $(evt.currentTarget).find('img');
	var wrapmask = el.wrapmask({cn:'ovh rel',mask:''});
	var p = wrapmask.parents;
	p.addClass('ppppp');
	var wrapmask2 = wrapmask.parents.wrapmask({cn:'ovh rel',mask:''});
	setTimeout(function() {
		var tl = new TimelineMax({paused : true});
		tl.deroul( $(evt.currentTarget).find('img'), {
			y: '-100%',
			ease: Power2.easeOut
		});
		tl.from( $('.actu__title__bg'), 1, {
			scale: 0,
			opacity: 0,
			transformOrigin: '0% 0%',
			ease: Power2.easeOut
		}, 0.3 )
		var txt = $('.actu__title u').eq(0);
		var lines = new SplitText(txt, {type:'lines'});
		tl.from( lines.lines, 1.3, {
			x: '-100rem',
			opacity: 0,
			stagger: 0.15,
			ease: Power2.easeOut
		}, 0.6 );
		tl.play();
	}, 10);
});

$('body').on('reveal-evidence-service-bg-scaledown', '.scroll-reveal', function(evt){
	// var el = $(evt.currentTarget).addClass('reveal');
	var el = $(evt.currentTarget);
	setTimeout(function() {
		var tl = new TimelineMax({paused : true});
		tl.from( el, 1.3, {
			scaleY: 0,
			transformOrigin: '0% 0%',
			ease: Power2.easeOut
		});
		tl.play();
	}, 10);
});

$('body').on('reveal-evidence-service-line', '.scroll-reveal', function(evt){
	// var el = $(evt.currentTarget).addClass('reveal');
	var el = $(evt.currentTarget);
	var opts = {
		o: '100% 0%',
		direction: 'left'
	};
	if ( parseInt(el.attr('data-index'), 10) % 2 == 1 ) {
		opts = {
			o: '0% 0%',
			direction: 'right'
		};
	}
	var tl = new TimelineMax({paused : true});
	// tl.from( $(el).find('.service__bloc__item__visu__line'), 0.6, {
	// 	scaleX: 0,
	// 	transformOrigin: opts.o,
	// 	ease: Power2.easeOut
	// }, 0);
	tl.deroul( $(el).find('.service__bloc__item__visu__visu img'), {
		direction: opts.direction,
		factor: 0.5
	}, 0.1);
	tl.fadeUp( $(el).find('.service__bloc__item__txts > div'), {
		yOrigin: '30rem',
		stagger: 0.10
	}, 0.2 );
	setTimeout(function() {
		tl.play();
	}, 10);
});

$('body').on('reveal-evidence-slideshow-col', '.scroll-reveal', function(evt){
	// var el = $(evt.currentTarget).addClass('reveal');
	var el = $(evt.currentTarget);
	var tl = new TimelineMax({paused : true});
	tl.deroul( $(el), {
		direction: el.attr('data-way')
	}, 0);
	setTimeout(function() {
		tl.play();
	}, 10);
});

$('body').on('reveal-evidence-projet-timings', '.scroll-reveal', function(evt){
	// var el = $(evt.currentTarget).addClass('reveal');
	var el = $(evt.currentTarget);
	var tl = new TimelineMax({paused : true});
	tl.from( $(el).find('.projets__timings__col__bg'), 1, {
		scaleX: 0,
		transformOrigin: '100% 50%',
		ease: Power2.easeOut,
		clearProps: 'all'
	}, 0);
	tl.from( $(el).find('.projets__timings__title__inner, .projets__timings__item__dots, .projets__timings__item__picto, .projets__timings__item__gare__wrap, .projets__timings__item__arc__wrap'), 0.7, {
		x: '30rem',
		opacity: 0,
		ease: Power2.easeOut,
		stagger: 0.075
	}, 0.2);
	setTimeout(function() {
		tl.play();
	}, 10);
});

$('body').on('reveal-evidence-projet-chiffres', '.scroll-reveal', function(evt){
	// var el = $(evt.currentTarget).addClass('reveal');
	var el = $(evt.currentTarget);
	var tl = new TimelineMax({paused : true});
	tl.from( $(el).find('.projets__chiffres__bg'), 1, {
		scaleX: 0,
		transformOrigin: '0% 50%',
		ease: Power2.easeOut,
		clearProps: 'all',
	}, 0);
	tl.from( $(el).find('.projets__chiffres__title, .projets__chiffres__item__picto, .projets__chiffres__item__txts'), 0.7, {
		x: '-30rem',
		opacity: 0,
		ease: Power2.easeOut,
		stagger: 0.075
	}, 0.2);
	setTimeout(function() {
		tl.play();
	}, 10);
});

$('body').on('reveal-evidence-projet-certifs', '.scroll-reveal', function(evt){
	// var el = $(evt.currentTarget).addClass('reveal');
	var el = $(evt.currentTarget);
	var tl = new TimelineMax({paused : true});
	tl.from( $(el).find('.projets__certifs__bg'), 1, {
		scaleX: 0,
		transformOrigin: '100% 50%',
		ease: Power2.easeOut,
		clearProps: 'all'
	}, 0);
	tl.from( $(el).find('[data-move="1"]'), 0.7, {
		x: '30rem',
		opacity: 0,
		ease: Power2.easeOut,
		stagger: 0.075
	}, 0.3);
	setTimeout(function() {
		tl.play();
	}, 10);
});

$('body').on('reveal-evidence-projet-footer', '.scroll-reveal', function(evt){
	// var el = $(evt.currentTarget).addClass('reveal');
	var el = $(evt.currentTarget);
	var tl = new TimelineMax({paused : true});
	var els = $('.projets__footer__sep')
		.add('.projets__footer__item--0 .projets__footer__item__picto')
		.add('.projets__footer__item--0 .projets__footer__item__nb')
		.add('.projets__footer__item--0 .projets__footer__item__txtbold')
		.add('.projets__footer__item--0 .projets__footer__item__txt')
		.add('.projets__footer__item--0 .projets__footer__item__sep')
		.add('.projets__footer__item--1 .projets__footer__item__nb')
		.add('.projets__footer__item--1 .projets__footer__item__txtbold')
		.add('.projets__footer__item--1 .projets__footer__item__txt')
		.add('.projets__footer__item--1 .projets__footer__item__picto')
		.add('.projets__footer__item--1 .projets__footer__item__sep')
		.add('.projets__footer__item--2 .projets__footer__item__nb')
		.add('.projets__footer__item--2 .projets__footer__item__txtbold')
		.add('.projets__footer__item--2 .projets__footer__item__txt')
		.add('.projets__footer__item--2 .projets__footer__item__picto')
	tl.fadeUp( els, {
		yOrigin: '30rem',
		stagger: 0.07
	}, 0);
	setTimeout(function() {
		tl.play();
	}, 10);
});

$('body').on('reveal-evidence-header', '.scroll-reveal', function(evt){
	// var el = $(evt.currentTarget).addClass('reveal');
	var el = $(evt.currentTarget);
	var tl = new TimelineMax({paused : true});
	tl.to( $('.header__inner'), 0, {
		opacity: 1,
		ease: Power0.easeOut
	}, 0.01 );

	tl.fadeUp( $('.header__logo__wrap, .menu__item__li, .header__linkedin__wrap, .header__toggler__wrap '), {
		yOrigin: '-50rem',
		stagger: 0.10
	}, 0 );
	setTimeout(function() {
		tl.play();
	}, 10);
});

$('body').on('reveal-vin-head', '.scroll-reveal', function(evt){
	var tl = new TimelineMax({paused : true});
	tl.from( $('.vin__head__retour__svg'), 1, {
		opacity: 0
	}, 0 )
	tl.from( $('.vin__head__visuel__wrap'), 1.5, {
		opacity: 0
	}, 0.4 )
	tl.from( $('.vin__head__cat__title'), 1, {
		opacity: 0
	}, 0.4 )
	tl.from( $('.vin__head__title'), 1, {
		opacity: 0
	}, 0.6 )
	tl.from( $('.vin__head__menu'), 1, {
		opacity: 0
	}, 0.8 )
	tl.from( $('.vin__head__epi'), 1, {
		opacity: 0
	}, 1.2 )
	tl.from( $('.vin__head__retour__cta__wrap'), 1.5, {
		opacity: 0
	}, 1.4 )

	tl.play()
});

$('body').on('reveal-contact-form', '.scroll-reveal', function(evt){
	var el = $(evt.currentTarget);
	var tl = new TimelineMax({paused : true});
	var wrapmask = el.find('.form__group').wrapmask({cn:'ovh rel',mask:'mask'});
	tl.staggerTo( wrapmask.masks, 1, {
		// x: '100%',
		y: '100%',
		ease: Power2.easeOut
	}, 0.075 );

	tl.play()
});

$('body').on('reveal-header', '.scroll-reveal', function(evt){
	var el = $(evt.currentTarget);
	var tl = new TimelineMax({paused : true});
	// var wrapmask = el.find('.form__group').wrapmask({cn:'ovh rel',mask:'mask'});
	tl.fromTo( el, 1, {
		opacity: 0,
		y: '-25%'
	}, {
		opacity: 1,
		// x: '100%',
		y: '0%',
		ease: Power2.easeOut
	} );

	tl.play()
});

$('body').on('reveal-quote', '.scroll-reveal', function(evt){
	// set final bg position
	var el = $(evt.currentTarget);
	var ww = $(window).width();
	var elw = $(el).outerWidth();
	$(el).find('.domaine__quote__bg').css({
		left: Math.ceil( (ww - elw) / 2 ) * -1,
		right: 'auto',
		width: ww
	});
	var tl = new TimelineMax({paused : true});
	// var wrapmask = el.find('.form__group').wrapmask({cn:'ovh rel',mask:'mask'});
	tl.fromTo( $(el).find('.domaine__quote__bg'), 1, {
		opacity: 0,
		x: '-100%'
	}, {
		opacity: 1,
		x: '0%',
		ease: Circ.easeInOut
	} );

	tl.play()
});

$('body').on('reveal-equipe-main-item', '.scroll-reveal', function(evt){
	// set final bg position
	var el = $(evt.currentTarget);
	var decal = 0;
	if ( el.hasClass('equpe__main__item__wrap--1') ) {
		decal = 0.1;
	}
	var tl = new TimelineMax({paused : true});
	// var wrapmask = el.find('.form__group').wrapmask({cn:'ovh rel',mask:'mask'});
	tl.fromTo( $(el).find('.equpe__main__item__nom'), 0.7 + decal, {
		x:'-10rem',
		opacity: 0
	}, {
		x:'0rem',
		opacity: 1,
		ease: Circ.easeOut
	} );
	tl.fromTo( $(el).find('.equpe__main__item__visu__arrow__mask'), 0.75, {
		drawSVG:'0% 100%'
	}, {
		drawSVG:'100% 100%',
		ease: Circ.easeOut
	}, 0.25 + decal );
	tl.fromTo( $(el).find('.equpe__main__item__visu'), 1, {
		x:'0rem',
		opacity: 0
	}, {
		x:'0rem',
		opacity: 1,
		ease: Circ.easeOut
	}, 0.5 + decal );
	tl.fromTo( $(el).find('.equpe__main__item__txt'), 0.75, {
		y:'3rem',
		opacity: 0
	}, {
		y:'0rem',
		opacity: 1,
		ease: Circ.easeOut
	}, 0.75 + decal );
	
	// tl.timeScale(0.2);
	tl.play();
});

$('body').on('reveal-home-baseline', '.scroll-reveal', function(evt){
	// set final bg position
	var el = $(evt.currentTarget);
	var st =  new SplitText(el, {type:'lines,words,chars'});
	
	var tl = new TimelineMax({paused : true});
	// var wrapmask = el.find('.form__group').wrapmask({cn:'ovh rel',mask:'mask'});
	tl.staggerFrom( st.chars, 0.6, {
		// scale:0.5,
		opacity: 0,
		ease: Power0.easeOut
	}, 0.035 );

	// alert( cymasmooth.pagesViewed )

	var baseline_delay = 20;
	if ( cymasmooth.pagesViewed == 1 ) {
		logo_anim();
		baseline_delay = 4000;
	}

	
	// tl.timeScale(0.2);
	setTimeout(function() {
		tl.play();
	}, baseline_delay);
});

$('body').on('reveal-line-verti', '.scroll-reveal', function(evt){
	var el = $(evt.currentTarget);
	
	var tl = new TimelineMax({paused : true});
	tl.fromTo( $(el).find('.line__n__circle__circle'), 0.4, {
		opacity: 0
	}, {
		opacity: 1,
		ease: Power0.easeOut
	});
	var h = $(el).find('.line__n__circle__line').height();
	tl.fromTo( $(el).find('.line__n__circle__line'), h / 1000, {
		scaleY: 0,
		transformOrigin: '0% 0%'
	}, {
		scaleY: 1,
		ease: Power1.easeInOut
	}, 0.2);
	tl.play();
});
