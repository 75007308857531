var $ = require('jquery');
var MQ = require('mq');

var is_open = false;

var ttl = new TimelineMax({
    paused : true,
    onComplete: function(){}
});

// ttl.fromTo( $('.header__blocb'), 0.05, {
// 	x: '101%'
// 	// opacity: 0
// }, {
// 	x: '0%',
// 	// opacity: 1,
// 	ease: Power1.easeOut
// });

// // ttl.fromTo( $('.header__blocb__bg'), 1, {
// // 	x: '-101%'
// // 	// opacity: 0
// // }, {
// // 	x: '0%',
// // 	// opacity: 1,
// // 	ease: Power1.easeOut
// // });

// ttl.fromTo( $('.header__blocb__bg'), 1, {
// 	x: '20%',
// 	opacity: 0
// }, {
// 	x: '0%',
// 	opacity: 1,
// 	ease: Power1.easeOut
// });

// ttl.staggerFromTo( $('.header__blocb__toggler, .header__blocb .menu-item, .header__blocb .contact__cta__big'), 1, {
// 	opacity: 0,
// 	x: 50
// }, {
//     opacity: 1,
// 	x: 0,
// 	ease: Power2.easeOut
// }, 0.1, 0.5);




var toggleMenu = function(){
	// alert('b')
	// if ( !is_open ) {
	// 	if ( MQ(768) ){
	// 		var tl = gsap.timeline({paused : true})
	// 		tl.fromTo( $('.main__menu'), {
	// 			y: '-100%'
	// 		}, {
	// 			duration: 1.3,
	// 			y: '0%',
	// 			ease: 'power2.inOut'
	// 		}, 0 )
	// 		tl.fromTo( $('.main__menu__inner'), {
	// 			y: '100%'
	// 		}, {
	// 			duration: 1.3,
	// 			y: '0%',
	// 			ease: 'power2.inOut'
	// 		}, 0 )
	// 		tl.fadeUp($('.menu__item__name_n_nb, .main__menu__others'), {

	// 		}, 0.5);
	// 		tl.fromTo( $('.menu__item__line'), {
	// 			scaleX: 0.5,
	// 			opacity: 0
	// 		}, {
	// 			scaleX: 1.001,
	// 			opacity: 1,
	// 			duration: 1,
	// 			stagger: 0.1
	// 		}, 0.6 )
	// 		tl.play();
	// 	}
	// }
	// else {
	// 	if ( MQ(768) ){
	// 		var tl = gsap.timeline({paused : true})
	// 		tl.fromTo( $('.main__menu'), {
	// 			y: '0%'
	// 		}, {
	// 			duration: 1.3,
	// 			y: '-100%',
	// 			ease: 'power2.inOut'
	// 		}, 0 )
	// 		tl.fromTo( $('.main__menu__inner'), {
	// 			y: '0%'
	// 		}, {
	// 			duration: 1.3,
	// 			y: '100%',
	// 			ease: 'power2.inOut'
	// 		}, 0 )
	// 		tl.play();
	// 	}
	// }
	is_open = !is_open;
	$('body').toggleClass( 'is-main-menu-open' );
	$(window).trigger('is-main-menu-toggled', is_open);
};

// alert('a')
$( document ).on('click', '[data-js="toggle-main-menu"]', toggleMenu);

module.exports = function(){
	if ( is_open ) {
		toggleMenu();
	}
};