var $ = require('jquery');
var reveals = require('./0reveals');

reveals('bloc-discojobs', function(el, tl){
    var titre = $(el).find('.bloc__accueil__1__titre');
    var lines = new SplitText( $(el).find('.bloc__discojobs__titre'), {type:'lines'}).lines;
    lines.push( $(el).find('.bloc__discojobs__ctas').get(0) );

    tl.staggerFromTo( lines, 0.8, {
        opacity: 0,
        cycle: {
            x: function(i){
                var way = i % 2 == 0 ? 1 : -1;
                return way * $(this).width() * 0.1;
            }
        }
    }, {
        opacity: 1,
        x: 0,
        ease: Circ.easeOut
    }, 0.075, 0 );
    return tl;
});
