var $ = require('jquery');

module.exports = function(el) {
	return new TimelineMax({paused:true})
		.fromTo( $(el), 0.5, {
			opacity: 0,
			y: 50
		}, {
			opacity: 1,
			y: 0,
			ease: Power2.easeOut
		} )

		.staggerFrom( $(el).find('> div'), 0.5, {
			y: 30,
			opacity: 0,
			ease: Power2.easeOut
		}, 0.1, '-=0.2' )

};