var $ = require('jquery');
var ismobile = require('ismobile');
var scrollElements = $('.scroll-parallax');
$(window).on('resize orientationchange', resizeHandler).trigger('resize');
$('window').on('load', function() {
    $(window).trigger('resize')
});
var is_init = false;
var is_initb = false;
var resizeHandler = function() {
    if ( !ismobile ) {
        scrollParallaxResize();
    }
};

var scrollParallaxInit = function() {
    if ( !ismobile ) {
        scrollElements = $('.scroll-parallax');
        scrollElements.each(function() {
            var level = $(this).attr('data-level');
            if (level == undefined) {
                var level = $(this).css('zIndex');
                if (level == 'auto')
                    level = 1;
                if (level > 5)
                    level = 5;
                $(this).attr('data-level', level)
            } else if (level == 'rand') {
                level = Math.random();
                $(this).attr('data-level', level)
            }
        });
        is_init = true;
        scrollParallaxResize();
        if ( !is_initb ) {
            requestAnimationFrame(scrollParallaxMove);
            is_initb = true;
        }
    }
};

var scrollParallaxResize = function() {
    if ( !is_init ) {
        resizeHandler();
        return;
    }
    scrollElements.each(function() {
        var element = $(this);
        var container = element.closest('.site-section');
        var transform = element.css('transform');
        element.css({
            transform: ''
        });
        var level = Number(element.attr('data-level'));
        if ( level < 0 ) {
            element.attr('data-top', element.offset().top - $(window).height());
            element.attr('data-bottom', element.offset().top + element.outerHeight() + $(window).height() );
        }
        else {
            element.attr('data-top', element.offset().top);
            element.attr('data-bottom', element.offset().top + element.outerHeight() );
        }
        element.attr('data-start', element.offset().top - $(window).height());
        element.attr('data-stop', element.offset().top + element.outerHeight() );
        element.css({
            transform: transform
        })
    })
};

var scrollParallaxMove = function() {
    if (scrollElements != null) {
        var windowHeight = $(window).height();
        var windowScrollTop = $(window).scrollTop();
        scrollElements.each(function() {
            var element = $(this);
            var offsetTop = element.attr('data-top');
            var offsetBottom = element.attr('data-bottom');
            var limit = element.attr('data-limit');
            var level = Number(element.attr('data-level'));
            var amplitude = -windowHeight;
            var movement = amplitude / (5 / level);
            if (offsetTop > (windowScrollTop + windowHeight)) {
                // console.log('a');
                var newY = -movement * 0.5
            } else if (offsetBottom < windowScrollTop) {
                // console.log('b');
                var newY = movement * 0.5
            } else {
                var start = element.attr('data-start');
                var stop = element.attr('data-stop');
                var percent = (windowScrollTop - start) / (stop - start);
                percent = percent - 0.5;
                var destY = movement * percent;
                var currentY = 0;
                var transform = element.css('transform');
                if (transform != 'none')
                    currentY = parseFloat(element.css('transform').split(',')[5]);
                if (level > 0)
                    var newY = currentY + ((destY - currentY) * 0.1);
                else
                    var newY = currentY + ((destY - currentY) * 0.1)
            }
            if (limit == 'top' && newY < 0)
                newY = 0;
            else if (limit == 'bottom' && newY > 0)
                newY = 0;
            element.css({
                transform: 'translate3d(0, ' + (newY) + 'px, 0)'
            })
        })
    }
    requestAnimationFrame(scrollParallaxMove)
};

module.exports = scrollParallaxInit;

var scrollParallaxInito = function() {
    var scrollElements = $('.scroll-parallax');
    scrollElements.each(function() {
        var level = $(this).attr('data-level');
        if (level == undefined) {
            level = $(this).css('zIndex');
            if (level == 'auto')
                level = 1;
            if (level > 5)
                level = 5;
            $(this).attr('data-level', level)
        } else if (level == 'rand') {
            level = Math.random();
            $(this).attr('data-level', level)
        }
    });
    scrollParallaxResize()
};
var scrollParallaxResizeo = function() {
    $('.scroll-parallax').each(function() {
        var element = $(this);
        var level = Number(element.attr('data-level'));
        var transform = element.css('transform');
        element.css({
            transform: ''
        });
        element.attr('data-movement', element.height() / (5 / level));
        element.attr('data-start', element.offset().top - windowHeight);
        element.attr('data-stop', element.offset().top + element.height());
        element.css({
            transform: transform
        })
    })
};
var scrollParallaxMoveo = function() {
    var windowHeight = $(window).height();
    var windowScrollTop = scrollTop;
    var scrollElements = $('.scroll-parallax');
    if (scrollElements != null) {
        scrollElements.each(function() {
            var element = $(this);
            var offsetTop = element.attr('data-top');
            var offsetBottom = element.attr('data-bottom');
            var level = Number(element.attr('data-level'));
            var amplitude = -windowHeight;
            var movement = amplitude / (5 / level);
            var movementDown = -movement * 0.5;
            var movementUp = movement * 0.5;
            var start = element.attr('data-start');
            var stop = element.attr('data-stop');
            var percent = (windowScrollTop - start) / (stop - start);
            percent = percent - 0.5;
            var destY = Math.round(movement * percent);
            var currentY = 0;
            var transform = element.css('transform');
            if (transform != 'none')
                currentY = parseFloat(element.css('transform').split(',')[5]);
            if (level > 0)
                var newY = currentY + ((destY - currentY) * 0.1);
            else
                var newY = currentY + ((destY - currentY) * 0.5);
            TweenMax.set(element, {
                y: newY
            });
            var image = element.find('> .image, .image-container > .image, .media-container > .media-outer').not('.fixed');
            if (image.length == 1) {
                image.css({
                    top: 100 * percent
                })
            }
        })
    }
};