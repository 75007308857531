/*! atomic v1.0.0 | (c) 2015 @toddmotto | github.com/toddmotto/atomic */
(function(window, factory) { 'use strict';
	if (typeof exports !== 'undefined') {
		module.exports = factory(
			window,
			require('jquery')
		);
	} else {
		factory(window, jQuery);
	}

}(window, function(window, $) {

	var css_em_ratio = 0.2; // used in css to fit in em : XXXXpx = (XXXX * ratio) em;

	if ( $('[em-resize-css-em-ratio]').size() ) {
		css_em_ratio = parseFloat( $('[em-resize-css-em-ratio]').attr('em-resize-css-em-ratio') );
	}
	
	function e(a, b, minw, maxw, minh, maxh, forcemaxw, forcemaxh) {
		var min = minw / minh;
		var max = maxw / maxh;
		// console.log('min', min);
		// console.log('max', max);
		// console.log(arguments);
		var c = a / b,
			d, e, m;
		switch (!0) {
			case min > c:
				e = a;
				m = Math.round(a / min);
				// if ( m > forcemaxh ) {
				// 	m = forcemaxh;
				// }
				d = Math.round(.5 * (b - m));
				c = 0;
				break;
			case max < c:
				m = b;
				e = Math.round(max * b);
				// if ( e > forcemaxw ) {
				// 	e = forcemaxw;
				// }
				c = Math.round(.5 * (a - e));
				d = 0;
				break;
			default:
				e = a,
					m = b,
					d = c = 0
		}
		var ret = {
			x: c,
			y: d,
			width: e,
			height: m
		}
		// console.log('ret', ret);
		return ret;
	}

	var resizeb = function($els, only_font_size) {
		console.log('resizeb');
		var args = [];
		for(var i=0,j=arguments.length;i<j;i++){
			args.push( arguments[i] );
		}
		$els.each( function( i, el ){
			if ( $(el).attr('data-js-emresize-in-parent') == '1' ){
				var pw = $(el).parent().width();
				var ph = $(el).parent().height();
				console.log('ph', pw, ph);
				resize( el, only_font_size, pw, ph );
			}
			else {
				if ( args.length < 4 ) {
					resize( el, only_font_size );
				}
				else {
					resize( el, only_font_size, args[2], args[3] );
				}
			}
		} );
	};

	var resize = function(el, only_font_size) {
		console.log('resize');
		var DEBUG = true;
		var DEBUG = false;
		if ( $(el).attr('data-emresize-debug') == '1' ) {
			DEBUG = true;
		}
		if ( $(el).data('init') == 1 ) {
			// return;
		}
		console.log('xx', $(el).attr('data-js-emresize-onlyfont'));
		if ( $(el).attr('data-js-emresize-onlyfont') == '1' ) {
			only_font_size = true;
			console.log('only_font_size', only_font_size);
			if ( DEBUG ) {
				// console.log('emresize1');
			}
		}
		

		// if ( window.matchMedia("only screen and (max-width: 920px)").matches ) {
		// 	$(el).css({
		// 		width: '100%',
		// 		height: '100%',
		// 		top: 0,
		// 		left: 0
		// 	});
		// 	return;
		// }

		$(el).data('init', 1);

		var design_height = parseInt($(el).attr('data-js-design-height'), 10);

		var minw = parseInt($(el).attr('data-js-minw'), 10);
		var minh = parseInt($(el).attr('data-js-minh'), 10);

		var maxw = parseInt($(el).attr('data-js-maxw'), 10);
		var maxh = parseInt($(el).attr('data-js-maxh'), 10);
		var forcemaxw = parseInt($(el).attr('data-js-force-maxw'), 10);
		var forcemaxh = parseInt($(el).attr('data-js-force-maxh'), 10);
		// alert(minw)
		var tww = 0;
		var twh = 0;
		if ( arguments.length == 4 ) {
			tww = arguments[2];
			twh = arguments[3];
			if ( DEBUG ) {
				// console.log('emresize2');
			}
		}
		else {
			tww = $(window).width();
			twh = $(window).height();
			if ( DEBUG ) {
				// console.log('emresize3');
			}
		}
		var parent_query = $(el).attr('data-js-emresize-parent');
		if ( parent_query && parent_query.length ) {
			var parent = $(parent_query);
			if ( parent.size() > 0 ) {
				tww = parent.width();
				twh = parent.height();
				if ( DEBUG ) {
					console.log('emresize:has parent size', tww, twh);
				}
			}
		}

		var max_top = 9999;
		if ( $(el).attr('data-js-em-stack-top') == '1' ) {
			max_top = 0;
			if ( DEBUG ) {
				// console.log('emresize4');
			}
		}

		// console.log('tww', tww, twh);
		if ( tww <= 1280 && tww <= twh ) {
			if ( tww > 599 ) {
				// design_height = 1010;
				// minw = 768;
				// maxw = 1010;

				// minh = 1010;
				// maxh = 1010;

				// is_device = 'is-not-pho is-tab';
				// next_device = 'tab';
				if ( DEBUG ) {
					// console.log('emresize6');
				}
			}
			else {
				// only_font_size = true;

				// is_device = 'is-pho';
				// next_device = 'pho';

				if ( $(el).attr('data-js-emresize-xs') == 'only-font-size' ) {
					only_font_size = true;
					if ( DEBUG ) {
						// console.log('emresize7');
					}
				}
				else {
					design_height = parseInt($(el).attr('data-js-design-height-xs'), 10) || design_height;
					console.log('design_height 0', design_height);

					var minw = parseInt($(el).attr('data-js-minw-xs'), 10) || minw;
					var minh = parseInt($(el).attr('data-js-minh-xs'), 10) || minh;

					var maxw = parseInt($(el).attr('data-js-maxw-xs'), 10) || maxw;
					var maxh = parseInt($(el).attr('data-js-maxh-xs'), 10) || maxh;
					var forcemaxw = parseInt($(el).attr('data-js-force-maxw-xs'), 10) || forcemaxw;
					var forcemaxh = parseInt($(el).attr('data-js-force-maxh-xs'), 10) || forcemaxh;
					if ( DEBUG ) {
						// console.log('emresize8');
					}
				}

			}
		}

		if ( twh <= tww ) { // landscape so check height
			if ( DEBUG ) {
				// console.log('emresize9');
			}
			if ( twh < 599 ) {
				var design_height_temp = parseInt($(el).attr('data-js-design-height-xsl'), 10);
				if ( DEBUG ) {
					// console.log('emresize10');
				}
				if ( design_height_temp && design_height_temp > 0 ) {
					design_height = design_height_temp
					// console.log('design_height 1', design_height);

					minw = parseInt($(el).attr('data-js-minw-xsl'), 10);
					minh = parseInt($(el).attr('data-js-minh-xsl'), 10);

					maxw = parseInt($(el).attr('data-js-maxw-xsl'), 10);
					maxh = parseInt($(el).attr('data-js-maxh-xsl'), 10);
					forcemaxw = parseInt($(el).attr('data-js-force-maxw-xsl'), 10);
					forcemaxh = parseInt($(el).attr('data-js-force-maxh-xsl'), 10);
					if ( DEBUG ) {
						// console.log('emresize11');
					}
				}
			}
		}

		// var tww = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
		// var twh = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

		// console.log('tww', tww);
		// console.log('twh', twh);

		// console.log('ww', ww);
		// console.log('wh', wh);

		var font_size_ratio = design_height * css_em_ratio;
		console.log('design_height', {design_height:design_height, tww:tww, twh:twh, minw:minw, maxw:maxw, minh:minh, maxh:maxh, forcemaxw:forcemaxw, forcemaxh:forcemaxh, css_em_ratio:css_em_ratio});
		
		var h = e(tww, twh, minw, maxw, minh, maxh, forcemaxw, forcemaxh);
		console.log('h', h);
		var fs = h.height / font_size_ratio;
		console.log('fs', fs);
		console.log('--------------------------------------------');
		// console.log('el', el);
		if ( only_font_size === true ) {
			$(el).css({
				"font-size": fs + "px"
			});
		}
		else if ( $(el).attr('data-js-em-stack') == 'bottom' ) {
			console.log('font_size_ratio', design_height, css_em_ratio);
			$(el).css({
				"font-size": fs + "px",
				width: h.width + "px",
				height: h.height + "px",
				bottom: Math.min(max_top, h.y) + "px ",
				left: h.x + "px "
			});
		}
		else {
			
			$(el).css({
				"font-size": fs + "px",
				width: h.width + "px",
				height: h.height + "px",
				top: Math.min(max_top, h.y) + "px ",
				left: h.x + "px "
			});
		}
	};

	return {
		resize: resize,
		resizeb: resizeb
	};

}));