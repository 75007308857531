var $ = require('jquery');

module.exports = function(el) {
	var split = new SplitText($(el).find('.page__title__txt .titrepage1') , {type:'lines,words,chars'});
	return new TimelineMax({paused:true})
		.fromTo( $(el).find('.page__title__picto'), 0.5, {
			opacity: 0,
			x: -30
		}, {
			opacity: 1,
			x: 0,
			ease: Power2.easeOut
		} )

		.staggerFrom( split.chars, 0.5, {
			// y: 30,
			opacity: 0,
			ease: Power0.easeOut
		}, 0.02, '-=0.4' )

		.from( $(el).find('.page__close'), 0.5, {
			// y: 30,
			opacity: 0,
			ease: Power0.easeOut
		}, '-=0.4' )

};