var $ = require('jquery');
var popup = require('mypop');
var cymasmooth = require('cymasmooth');

var current_href = 'https://www.google.fr';
var title = '';

var showPopup = function(){
	$('.outside__link__popup__wrap').addClass('is-active');
};

// $('.outside__link__popup__wrap').find('[data-js="continue"]').click(function(){
// 	popup( current_href, title, $(window).width(), $(window).height() );
// });

$('.outside__link__popup__wrap').find('[data-js="cancel"]').click(function(){
	console.log('click');
	$('.outside__link__popup__wrap').removeClass('is-active');
});

$('.outside__link__popup__bg, .outside__link__popup__close').click(function(){
	console.log('click');
	$('.outside__link__popup__wrap').removeClass('is-active');
});

// console.log('LLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLL');
// console.log($('.outside__link__popup__bg, .outside__link__popup__close').length);

cymasmooth.registerFuncMulti('onLinkClickedBlocking', function(evt, el){
	if ( $(el).attr('data-target') == '_blank' ) {
		evt.preventDefault();
		showPopup();
		current_href = $(el).attr('href');
		console.log('current_href', current_href);
		$('[data-js="continue"]').attr('href', current_href);
		return false;
	}
	return true;
});
