var $ = require('jquery');
require('./mask.js');
var reveals = require('./0reveals');
var closeCtaAnim = require('./closectaanim.js');
var arrowAnim = require('./arrowanim.js');
var pictoAnim = require('./pictoanim.js');
var bulleAnim = require('./bulleanim.js');
var titleAnim = require('./titleanim.js');

reveals('brochuremodelanim', function(el, tl, start_label){
	tl.to( el, 0.5, {
		opacity: 1
	}, start_label );

	var ttl = new TimelineMax({paused:true});

	// ttl.fromTo( $(el).find('.brochure__slide__line'), 0.6, {
	// 	scaleY: 0,
	// 	transformOrigin: '50% 0%'
	// }, {
	// 	scaleY: 1,
	// 	ease: Power0.easeOut
	// } )

	// ttl.fromTo( $(el).find('.brochure__slide__line'), 1.2, {
	// 	y: '-100%',
	// 	transformOrigin: '50% 0%'
	// }, {
	// 	y: '100%',
	// 	ease: Power0.easeInOut
	// } )

	// ttl.addLabel( 'partb' )

	// ttl.to( $(el).find('.brochure__slide__line').eq(0), 1, {
	// 	x: '-0%',
	// 	ease: Power1.easeOut
	// }, 0.6 )

	// ttl.to( $(el).find('.brochure__slide__line'), 1, {
	// 	opacity: 0,
	// 	ease: Power1.easeIn
	// }, 0.6 )

	ttl.to( $(el).find('.brochure__slide__mask').eq(0), 1, {
		x: '-99%',
		ease: Power0.easeOut
	}, 'partb' )

	// ttl.to( $(el).find('.brochure__slide__line').eq(1), 1, {
	// 	x: '100%',
	// 	ease: Power1.easeOut
	// }, 0.6 )

	ttl.to( $(el).find('.brochure__slide__mask').eq(1), 1, {
		x: '99%',
		ease: Power0.easeOut
	}, 'partb' )

	ttl.addLabel( 'partc' )

	ttl.to( $(el).find('.brochure__slide__mask'), 1, {
		scaleY: 0,
		transformOrigin: '50% 50%',
		ease: Power0.easeOut
	}, 'partc' )

	ttl.addLabel( 'partd', '-=0.5' )

	ttl.fromTo( arrowAnim( $(el).find('.brochure__slide__nav--left') ), 1, {
		progress:0
	}, {
		progress:1,
		ease: Power0.easeInOut
	}, 'partd' )

	ttl.fromTo( arrowAnim( $(el).find('.brochure__slide__nav--right') ), 1, {
		progress:0
	}, {
		progress:1,
		ease: Power0.easeInOut
	}, 'partd' )


	tl.fromTo( titleAnim( $(el).find('.page__head') ), 1.2, {
		progress: 0
	}, {
		progress: 1,
		ease: Power0.easeOut
	}, start_label+'+=0.4');

	tl.fromTo( ttl, 1.5, {
		progress:0
	}, {
		progress:1,
		ease: Power1.easeInOut
	}, start_label+'+=0.4');

	tl.fromTo( bulleAnim( $(el).find('.we__txt__bloc') ), 1.2, {
		progress: 0
	}, {
		progress: 1,
		ease: Power0.easeOut
	}, start_label+'+=1.4');

	tl.fromTo( pictoAnim( $(el).find('.brochure__picto') ), 1.2, {
		progress: 0
	}, {
		progress: 1,
		ease: Power0.easeOut
	}, start_label+'+=1.6');

	tl.fromTo( $(el).find('.brochure__share__wrap'), 1.2, {
		y: 50,
		opacity: 0,
		ease: Power2.easeOut
	}, {
		y: 0,
		opacity: 1
	}, start_label+'+=1.8');

	tl.fromTo( $(el).find('.brochure__action__a'), 1.2, {
		// y: 50,
		opacity: 0,
		ease: Power2.easeOut
	}, {
		y: 0,
		opacity: 1
	}, start_label+'+=2.2');

	// tl.fromTo( $(el).find('.brochure__other__lang__wrap'), 1.5, {
	// 	opacity: 0,
	// 	ease: Power0.easeOut
	// }, {
	// 	opacity: 1,
	// 	ease: Power0.easeOut
	// }, start_label+'+=2.25');

	// tl.fromTo( $(el).find('.brochure__action__mask--dl'), 1, {
	// 	x:'0%',
	// 	ease: Power2.easeOut
	// }, {
	// 	x:'-110%',
	// 	ease: Power2.easeOut
	// }, start_label+'+=2.25');

	// tl.fromTo( $(el).find('.brochure__action__mask--fs'), 1, {
	// 	x:'0%',
	// 	ease: Power2.easeOut
	// }, {
	// 	x:'110%',
	// 	ease: Power2.easeOut
	// }, start_label+'+=2.25');

	// var lines = new SplitText( $(el).find('.bg__text'), { type: "lines" });
	// console.log('lines.lines', lines.lines);
	// lines = $(lines.lines).wrapp('jq__mask').childs;
	// lines = $(el).find('.bg__text__text').wrapp('jq__mask').childs;

	// tl.staggerFromTo(lines, 0.75*1.2, {
	// 	y:'110%',
	// 	ease: Power2.easeOut
	// }, {
	// 	y:'0%',
	// 	ease: Power2.easeOut
	// }, 0.15*1.2, start_label+'+=2.75');

	// tl.staggerFromTo( $(el).find('.bg__text__picto'), 1.5, {
	// 	opacity: 0
	// }, {
	// 	opacity: 1,
	// 	ease: Power2.easeInOut
	// }, 0.1, start_label+'+=2.85' );

	// closeCtaAnim( tl, el, start_label+'+=1.6' );
});
