var $ = require('jquery');
require('./mask.js');
var reveals = require('./0reveals');
var shuffle = require('shuffle');
var pictoAnim = require('./pictoanim.js');
require('wrapmask');
var first_pass = true;
var cymasmooth = require('cymasmooth');

reveals('homemodelanim', function(el, tl, start_label){
	// alert('home')
	tl.to( el, 1.75, {
		opacity: 1
	}, start_label );

	// try {
	// 	// body...
	// 	alert(cymasmooth.prev_page.model);
	// 	alert(cymasmooth.next_page.model);
	// } catch(e){}

	// if ( (first_pass && cymasmooth.next_page.model != 'collagenforcequizz') || cymasmooth.prev_page.model == 'collagenforcequizz' ) {
	if ( first_pass ) {
		first_pass = false;
		tl.to( $('.header'), 0.1, {
			opacity: 1
		}, start_label );
		tl.fromTo( $('.header__bg'), 0.75, {
			y: '-101%',
			opacity: 0
		}, {
			y: '0%',
			opacity: 1
		}, start_label );
		// tl.fromTo( $('.header__logo__wrap, .header__toggler__wrap, .header__contact'), 0.9, {
		// 	y: '-50rem',
		// 	opacity: 0
		// }, {
		// 	y: '0rem',
		// 	stagger: 0.15,
		// 	opacity: 1
		// }, start_label+'+=0.25' );

		return;
		// tl.fromTo( $('.header__menu__ul__a > li'), 0.5, {
		// 	y: '-50rem',
		// 	opacity: 0
		// }, {
		// 	y: '0rem',
		// 	opacity: 1
		// }, start_label+'+=0.25' );
		var linea = $('.header__menu__ul__a > li').get().reverse();
		// linea.push.apply(linea, lineb);
		tl.fromTo( linea, {
			opacity: 0,
			x: '25rem',
		}, {
			opacity: 1,
			x: '0rem',
			stagger: 0.1,
			duration: 0.75,
			ease: Power2.easeOut
		}, start_label+'+=0.35' )
		tl.from( $('.header__bloca__inner').find('.header__questions, .header__resos, .header__langs'), {
			opacity: 0,
			y: '-101%',
			stagger: 0.1,
			duration: 0.75,
			ease: Power2.easeOut
		}, start_label+'+=0.75' )
		tl.from( $('.pagehead__drlocator__innerr, .pagehead__scrolltop'), {
			opacity: 0,
			y: '101%',
			stagger: 0.25,
			duration: 1,
			ease: Power2.easeOut
		}, start_label+'+=0.95' )
		// tl.from( $('.header__logo__bg'), 0.9, {
		// 	x: '-100%',
		// 	y: '-100%',
		// 	ease: 'power2.out'
		// }, start_label+'+=0.5' );
		// tl.from( $('.header__logo__link__wrap, .header__slogan__inner, .header__toggler__wrap'), 0.9, {
		// 	opacity: 0,
		// 	y: '-60rem',
		// 	ease: 'power2.out',
		// 	stagger: 0.1
		// }, start_label+'+=0.75' );
		first_pass = false;
		
	}

	if ( cymasmooth.next_page.model == 'collagenforcequizz' ){
		tl.to( $('.header'), 0.5, {
			opacity: 0
		}, start_label );
		tl.fromTo( $('.header__bg'), 0.5, {
			y: '0%',
			opacity: 1
		}, {
			y: '-101%',
			opacity: 0
		}, start_label );
	}


	return;

	tl.from( $('.header__line'), {
		scaleX: 0,
		duration: 1,
		opacity: 0,
		transformOrigin: '50% 50%',
		ease: Power2.easeOut
	}, '>-0.5' )

	var ways = [
		{x:'-115%',y:'0%'},
		{x:'0%',y:'115%'},
		{x:'-115%',y:'0%'},
		{x:'0%',y:'-115%'}
	];

	var ways = [
		{x:'-115%',y:'0%'},
		{x:'0%',y:'115%'},
		{x:'0%',y:'-115%'}
	];

	// var ways = [
	// 	{x:'0%',y:'115%'}
	// ];

	$('.home__intro__yas g').wrapmasksvg({
		offset: 1
	}).els.each(function(i, g){
		tl.from( g, 0.8, {
			x: ways[i % ways.length ].x,
			y: ways[i % ways.length ].y,
		}, start_label + '+=' + ( 0.5 + i * 0.05 ) );


	})

	tl.addLabel( 'introtxts', '-=0.1' );

	// tl.from( $('.home__intro__txta'), 1.1, {
	// 	opacity: 0,
	// 	ease: Power0.easeOut
	// }, 'introtxts' );

	// tl.from( $('.home__intro__txtb'), 1.1, {
	// 	opacity: 0,
	// 	ease: Power0.easeOut
	// }, 'introtxts+=0.1' );

	var lines_a = new SplitText($('.home__intro__txta'), {type:'lines'}).lines;
	var lines_b = new SplitText($('.home__intro__txtb'), {type:'lines'}).lines;

	tl.from( $(lines_a).add(lines_b), 1.1, {
		opacity: 0,
		stagger: 0.033,
		ease: Power0.easeOut
	}, 'introtxts' );

	tl.from( $('.home__intro__scroll__down'), 1.1, {
		opacity: 0,
		ease: Power0.easeOut
	}, 'introtxts+=0.2' );

	

	// tl.from( $('.header__menu__li, .header__language__list li, .header__logo, .header__logo__n__toggle, .header__toggler__wrap'), 1.1, {
	// 	opacity: 0,
	// 	// y: '-30rem',
	// 	stagger: 0.05,
	// 	ease: Power0.easeOut
	// }, start_label + '+=0.1' );
	// }, 'introtxts+=0.3' );

	tl.from( $('.home__intro__y__bg'), 2.5, {
		scale: 1.25,
		transformOrigin: '50% 50%',
		ease: Power1.easeOut
	}, start_label );

	// var els = [];
	// els.push( $(el).find('.home__bg__wrap').get(0) );
	// els.push( $(el).find('.home__visu__wrap').get(0) );
	// els.push( $(el).find('.home__baseline__wrap').get(0) );
	// els.push( $(el).find('.home__txt__picto').get(0) );
	// els.push( $(el).find('.home__txt').get(0) );
	// els.push( $(el).find('.foot__menu').get(0) );
	// els.push( $(el).find('.home__cta__enter__wrap').get(0) );

	// tl.staggerFromTo( els, 2, {
	// 	opacity: 0
	// }, {
	// 	opacity: 1,
	// 	ease: Power1.easeInOut
	// }, 0.25,  start_label + '+=0')

	// tl.addLabel( 'lines', start_label+'+=0.2' );



	

	// tl.fromTo( pictoAnim( $(el).find('.home__picto') ), 0.8, {
	// 	progress: 0
	// }, {
	// 	progress: 1,
	// 	ease: Power0.easeOut
	// }, 'lines' )

	// tl.staggerFromTo( $(el).find('.we__txt__bloc, .home__menu__link '), 0.9, {
	// 	scale: 0.5,
	// 	opacity: 0
	// }, {
	// 	scale: 1.01,
	// 	opacity: 1,
	// 	ease: Power2.easeOut
	// }, 0.05, 'lines' )



});
