var $ = require('jquery');
var imagesLoaded = require('imagesloadedwithsize');
var NProgress = require('nprogresscustom');

var cb_bu = false;
var loop = 2;

var start = function(cb){
	cb_bu = cb;
	startAnim();
};

var imgs_loaded = false;

var startAnim = function(){
	// return;
	// loop--;
	// if ( loop > 0 ) {
	// console.log('imgs', $('[data-asset="page"]').get());
	gsap.to( $('.site__loader__img'), 0.5, {opacity:1, ease: Power0.easeOut} );

	var src = [];
	$('img[data-src]').each(function(i, el){
		src.push( $(el).attr('data-src') );
	})
	$('[data-bg-src]').each(function(i, el){
		src.push( $(el).attr('data-bg-src') );
	})

	// imagesLoaded( $('[data-asset="page"]').get(), {
	imagesLoaded( src, {
		background: true
	}, function() {
		$('img[data-src], [data-bg-src]').each(function(i, el){
			console.log('el', el);
			if ( $(el).attr('data-bg-src')  ) {
				$(el).css('background-image', 'url('+$(el).attr('data-bg-src')+')');
				$(el).removeAttr('data-bg-src');
			}
			else {
				console.log($(el).attr('data-src'));
				$(el).attr('src', $(el).attr('data-src'));
				$(el).removeAttr('data-src');
			}
		});
		NProgress.done();
		setTimeout(function() {
			// imgs_loaded = true;
				// return;
				finalStep();
				// cb_bu();
		// }, 10000);
		}, 200);
	});
		// $(el).data('preloadimp', '1');
		// alert('loaded')
		// preloadVisibleAssetsDone(callback);
		// setTimeout(function() {
		// 	createAnim();
		// }, 100);
	// });
	// }
	// else {
	// 	createEndAnim();
	// }

	var update = function(n) {
		// $('.loader__img__prog__bar').get(0).style.left = (n*100)+'%';
		console.log('n', n);
		gsap.to( $('.loader__rect'), {duration:0.5,xPercent:(n)*100,transformOrigin: '0% 0%'} );
		// TweenMax.set( $('.loader__rect'), {scaleX:n,transformOrigin: '0% 0%'} );
	};
	NProgress.configure({
		'cb': function(el, n){
			update(n);
		},
		'cbDone': function(el){
			update(1);
		},
		'cbEl': ''
	});
	NProgress.start();
};

var loop = 2;
var finalStep = function () {
	// return;
	var tl = new TimelineMax({paused : true});
	tl.to( $('.site__loader__wrap'), 1.5, {
		opacity: 0,
		scale:1.2,
		ease: Power0.easeOut
	} )
	tl.add( function(){
		cb_bu();
	}, '-=0.1' )
	tl.add( function(){
		setTimeout(function() {
			$('body').addClass('is-loading-done');
		}, 1000);
		$('.site__loader__wrap').remove();
	} )
	tl.call(function(){
		// $('.header').addClass('is-loaded');
	}, null, null, '-=0.2')
	tl.play();

};

module.exports = start;
