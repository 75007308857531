var $ = require('jquery');
var reveals = require('./0reveals');

reveals('bloc-contact-1', function(el, tl){
    // var lines = new SplitText($(el).find('.news__detail__title'), {type:'lines,words,chars'}).lines;
    // $(lines).addClass('news__detail__title__line');
    tl.staggerFromTo( $(el).find('.bloc__contact__list__titre, .bloc__contact__item').get(), 0.5, {
        opacity: 0,
        x: '5%'
    }, {
        opacity: 1,
        x: '0%',
        ease: Circ.easeOut
    }, 0.1, 0 );
    // var line = $(el).find('.about__assocs__titre__line');
    // var bloc_left = $(el).find('.about__assocs__titre');
    // var bloc_right = $(el).find('.about__assocs__sstitre');
    // tl.fromTo( line, 1, {
    //     scaleY: 0,
    //     transformOrigin: '50% 0%'
    // }, {
    //     scaleY: 1,
    //     ease: Power3.easeInOut
    // });
    // tl.addLabel('end', '-=0.6');
    // tl.fromTo( bloc_left, 1.5, {
    //     opacity: 0,
    //     x: 20
    // }, {
    //     opacity: 1,
    //     x: 0,
    //     ease: Power2.easeOut
    // }, 'end');
    // tl.fromTo( bloc_right, 1.5, {
    //     opacity: 0,
    //     x: -20
    // }, {
    //     opacity: 1,
    //     x: 0,
    //     ease: Power2.easeOut
    // }, 'end+=0.15');
    return tl;
});
