var $ = require('jquery');

var wwh = $(window).width() / 2;

$(window).on('resize', function(){
	wwh = $(window).width() / 2;
});

var isLeft = function( el ) {
	var el_left = $(el).offset().left;
	console.log('el_left', el_left);
	var el_right = el_left + $(el).width();
	console.log('el_right', el_right);
	var check = ( el_left * 0.5 ) + ( el_right * 0.5 );
	console.log('check', check);
	console.log('wwh', wwh);
	var ret = ( check < wwh );
	console.log('ret', ret);
	return ret;
};

module.exports = isLeft;