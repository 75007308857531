// (function(window, factory) { 'use strict';
// 	if (typeof exports !== 'undefined') {
// 		require('lightslide.js');
// 		require('fancybox');

// 		require('./about-slider.js');
// 		require('./refs-slider.js');
// 		require('./filters-logos.js');
// 		require('./frise-animation.js');

// 		module.exports = factory(
// 			window,
// 			require('jquery'),
// 			require('cymasmooth'),
// 			require('./compo/reveal.js'),
// 			require('./wpcf.js'),
// 			require('./form-select.js'),
// 			require('./form-file.js'),
// 			require('./corposlidechange.js'),
// 			require('swiper')
// 			// require('./homeslidechange.js'),
// 			// require('fdb'),
// 			// require('fetcho')
// 		);
// 	} else {
// 		factory(window, jQuery);
// 	}

// }(window, function(window, $, cymasmooth, reveal, wpContactForm, formSelect, formFile, corposlidechange, Swiper) {

	// console.log('reveal', reveal);
	// console.log('reveal', require('cymasmooth'));

	// $.fancybox.defaults.hideScrollbar = false;

			// var reveal = require('./compo/reveal.js');
			var cymasmooth = require('cymasmooth');
			var paralax = require('paralax');
			var reveal = require('reveal');
			var MQ = require('mq');
			// var fileInput = require('form-file-input-wp');
			// require('./codic-scultures-grid.js');
			// require('./codic-filter-grid.js'); 

	var $ = require('jquery');
	var scrollmenu = require('scrollmenu');
	require('approche-titre');

	var first_pass = true;

	function blockScroll(event) {
		event.preventDefault();
		event.stopPropagation();
		return false;
	}
	
	var site = {
		// isTransitioning: false
		isUiFreezed: false,
		cymaSmooth: true,
		hasCachedVideo: false
	};

	site.freezeUI = function(){
		// this.isTransitioning = true;
		if ( !this.isUiFreezed ) {
			alert('freeze')
			$('html').addClass('is-ui-freezed').on("scroll touchmove mousewheel", blockScroll);
			this.isUiFreezed = true;
		}
	};

	site.unfreezeUI = function(){
		// this.isTransitioning = false;
		if ( this.isUiFreezed ) {
			alert('unfreeze')
			$('html').removeClass('is-ui-freezed').off("scroll touchmove mousewheel", blockScroll);
			this.isUiFreezed = false;
		}
		
	};

	site.initPage = function( el ) {

		// setTimeout(function() {
		// 	reveal( true );
		// }, 200);
		// fileInput( $('body') );

		// scrollmenu.setMenuItems($(el).find('.home__scroll__menu__item').get());

		$(window).trigger('site:initPage', el);

		// $(".nano").nanoScroller({ alwaysVisible: true });
		// $('.valeurs__scultures__items').codicsculturegrid();
		// $(el).find('[data-js="isotope-items"]').codicfiltergrid();

		// if ( MQ(768) ) {
		// 	$('[data-post-hover-id-title]').hover(function(){
		// 		var id = $(this).attr('data-post-hover-id-title');
		// 		$('[data-post-id-visu="'+id+'"]').addClass('is-active');
		// 		// $(this).addClass('is-active');
		// 	}, function(){
		// 		var id = $(this).attr('data-post-hover-id-title');
		// 		$('[data-post-id-visu="'+id+'"]').removeClass('is-active');
		// 	});
		// }

		if ( cymasmooth.next_page.model == 'home' ) {
			// need to have defaut first menu item for parts not used so
			// home, approche, home, actu, contact
			var menu_items = $('.menu__item__li').get();
			var menu_items_final = [];
			var scroll_items_final = [];
			menu_items_final.push( menu_items[0] ); // home
			scroll_items_final.push( $('.home__head').get(0) ); // home
			menu_items_final.push( menu_items[1] ); // approche
			scroll_items_final.push( $('#approche').get(0) ); // approche
			menu_items_final.push( menu_items[0] ); // home
			scroll_items_final.push( $('.home__projets__slideshow__wrap').get(0) ); // home
			menu_items_final.push( menu_items[4] ); // actu
			scroll_items_final.push( $('#actualites').get(0) ); // actu
			// menu_items_final.push( menu_items[5] ); // contact
			// scroll_items_final.push( $('.footer').get(0) ); // contact
			// scrollmenu.setMenuItems(menu_items_final, {
			// 	scrollitems: scroll_items_final,
			// 	classname: 'is-current'
			// });
		}
		else {
			// scrollmenu.setMenuItems([]); // disable
		}

		if ( cymasmooth.gotopagewithanchor ) {
			console.log('aa', cymasmooth.gotopagewithanchor)
			console.log('aa',  $('#'+cymasmooth.gotopagewithanchor).offset().top )
			var anchor = cymasmooth.gotopagewithanchor + '';
			// tl.add(function(){
				console.log('aaa',  $('#'+anchor).offset().top )
				// $('html, body').scrollTop( $('#'+anchor).offset().top );
				setTimeout(function() {
					$('html, body').animate({scrollTop:$('#' + anchor).offset().top}, 10, 'easeInOutQuart');

					
				}, 50);
				// setTimeout(function() {
				// 	var tl = new TimelineMax({paused : true})
				// 	tl.fromTo($('.page__loader__mover').get(0), 0.9, {
				// 		x:'0%',
				// 		y:'0%',
				// 		ease: Power2.easeOut
				// 	}, {
				// 		x:'100%',
				// 		y:'0%',
				// 		ease: Power2.easeOut
				// 	}, 0);
				// 	tl.play()


				// }, 200);

				// $(old).remove();
				// $(el).css({position:'relative'});
			// }, '+=0.5');
			cymasmooth.gotopagewithanchor = false;
		}

		// alert(first_pass)
		if ( !first_pass ) {
			// var tl = new TimelineMax();
			// var from = '-20%';
			// var to = '120%';
			// if ( MQ(-766) ) {
			// 	from = '-50%';
			// 	to = '140%';
			// }

			var tl = new TimelineMax({paused:true});
			// tl.fromTo( $('.page__loader__mover__wrap'), 0.6, {
			// 	// height:'100%',
			// 	x:from,
			// 	opacity:1
			// }, {
			// 	// height:'100%',
			// 	x:to,
			// 	opacity:1,
			// 	ease: Power1.easeOut
			// }, 0.4 );
			tl.call( function (reveal, paralax) {
				// reveal( true );
				// paralax();
			}, [reveal, paralax], null, 0.1)
			// alert('aaaa')
			tl.play();

			
		}
		else {
			var tl = new TimelineMax({paused:true});
			// tl.fromTo( $('.header__inner'), 0.3, {
			// 	// height:'100%',
			// 	opacity:0
			// }, {
			// 	// height:'100%',
			// 	opacity:1,
			// 	ease: Power0.easeOut
			// }, 0 );
			// tl.play();

			first_pass = false;
			// if ( MQ(-768) ) {
			// 	reveal( true );
			// 	paralax();
			// }
		}

		return;

		$('.header').removeClass('force-show-menu');

		// alert('b')
		// alert( $(el).html() )
		// alert( $(el).find('[data-js="refs-slider"]').size() )
		$(el).find('[data-js="about-slider"]').aboutSlider();
		$(el).find('[data-js="refs-slider"]').refsSlider();
		wpContactForm( el );
		formSelect( el );
		formFile( el );

		$(el).find('[data-js="corpo-slide"]').lightSlider({
			'itemsSelector' : '.bloc__corpo__slide__item',
			'arrowsItem' : $( el ).find( '[data-js="corpo-slide-arrow"]' ).get(),
			'arrowLeftClass' : 'bloc__corpo__slide__nav--prev',
			'onshowitem': corposlidechange,
			'forceSameHeight' : function(a, b, c, $items) {
				var p = $items.parent();
				p.css({height:'auto'});
				var new_items = $items.clone().css({
					position: 'absolute',
					width: '100%',
					visibility : 'hidden',
					// opacity : 0,
					display: 'flex'
				}).appendTo(p);
				setTimeout(function() {
					var maxh = 0;
					new_items.each(function(i, eel){
						eel.offsetWidth;
						maxh = Math.max( maxh, $(eel).outerHeight() );
						// console.log('debug', $(eel).outerHeight(), $(eel).outerWidth());
						// console.log('debug', $(eel).find('.bloc__corpo__slide__img img').outerHeight(), $(eel).find('.bloc__corpo__slide__img').outerWidth(), $(eel).find('.bloc__corpo__slide__img img').get(0).offsetHeight, $(eel).find('.bloc__corpo__slide__img img').height());
					});
					p.height(maxh);
					new_items.remove();
				}, 100);
			}
		});

		$(el).find('[data-js="corpo-people-slide"]').each(function(i, eel){
			var spd = $(eel).attr('data-js-slide-spd') || 800;
			spd = parseInt( spd, 10 );
			var delay = $(eel).attr('data-js-slide-delay') || 0;
			delay = parseInt( delay, 10 );
			var opts = {
				slidesPerView: 'auto',
				centeredSlides: true,
				spaceBetween: 30,
				navigation: {
					prevEl: $(el).find('[data-js="corpo-people-slide-prev"]').get(0),
					nextEl: $(el).find('[data-js="corpo-people-slide-next"]').get(0)
				},
				loop: true,
				speed: spd
				// autoplay: {
				//     delay: 1000
				// }
				// pagination: {
				// 	el: '.swiper-pagination',
				// 	clickable: true,
				// }
			};
			if ( delay > 0 ) {
				opts.autoplay = {'delay':delay};
			}

			new Swiper( eel, opts );
		});

		setTimeout(function() {
			$(el).find('[data-js-isotope]').filterslogos();
		}, 1000);
		$(el).find('[data-js="frise-animation"]').friseanimation();
	};

	module.exports = site;

// }));

