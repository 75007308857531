var $ = require('jquery');
var imagesloadedwithsize = require('imagesloadedwithsize');

var hrefs = [];
var all_hrefs = [];
var is_loading = false;

var add_url = function(urls){
	if ( !$.isArray(urls) ) {
		urls = [urls];
	}
	for(var ii=0,jj=urls.length;ii<jj;ii++){
		var url = urls[ii];
		var founded = false;
		for(var i=0,j=all_hrefs.length;i<j;i++){
			if ( all_hrefs[i] == url ) {
				founded = true;
			}
		}
		if ( !founded ) {
			all_hrefs.push(url);
			hrefs.push(url);
			// console.log('add_url', hrefs);
		}
	}
	checkNextImage();
};

var checkNextImage = function(){
	// console.log('checkNextImage', is_loading);
	if ( !is_loading ) {
		// console.log('checkNextImage hrefs', hrefs);
		if ( hrefs.length > 0 ) {
			var href = hrefs.shift();
			is_loading = true;
			// console.log('load href', href);
			imagesloadedwithsize( [href], function() {
				console.log('loaded !! href', href);
				is_loading = false;
				checkNextImage();
			});
		}
	}
};

module.exports = add_url;

