// :root {--vh: 100vh;}
// vh < h:var(--vh);h:calc( 100nounit * var(--vh) );

var realResize = require('resize-wo-toolbar');

var $ = require('jquery');
var windowHeight = false;

var resizeHandler = function() {
    windowWidth = $(window).width();
    if (windowHeight != window.innerHeight) {
        var vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', vh + 'px');
        document.documentElement.style.setProperty('--vhnopx', vh * 100);
        $(window).trigger('vh:done');
    }
    var vw = $('body').width() * 0.01;
    document.documentElement.style.setProperty('--vw', vw + 'px');
    document.documentElement.style.setProperty('--vwnopx', vw * 100);
    windowHeight = window.innerHeight;
};

realResize.add( resizeHandler );

// $(window).on('resize orientationchange', resizeHandler);
$(window).on('paralax:play', resizeHandler);
resizeHandler();
setTimeout(function() {
    resizeHandler();
}, 100);

