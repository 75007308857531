var $ = require('jquery');

module.exports = function(evt, el, url){
	if ( url == History.getState().url && $('[data-is-webapp="1"]').size() === 0 && $(el).attr('data-js-cymasmooth') != 'force-same-url-process' ) {
		if ( $('html').attr('data-cymasmooth-same-url-reload') == '1' ) {
			window.location = url;
		}
		// alert('same')
		// prevent link to make his default action
		evt.preventDefault();
		$(window).trigger('cymasmooth:linkclickedsameurl');
		// stop here
		return false;
	}
	return true;
};