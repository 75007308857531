var $ = require('jquery');
require('./mask.js');
var reveals = require('./0reveals');
var shuffle = require('shuffle');
var pictoAnim = require('./pictoanim.js');
var first_pass = true;

reveals('privatemodelanim', function(el, tl, start_label){
	tl.to( el, 0.5, {
		opacity: 1
	}, start_label );

	tl.addLabel( 'lines', start_label+'+=0.2' );

	tl.staggerFromTo( $(el).find('.page__content'), 0.9, {
		opacity: 0
	}, {
		opacity: 1,
		ease: Power2.easeOut
	}, 0.05, 'lines' );
});
