var $ = require('jquery');
require('wpcf7');

$(window).on('site:initPage', function(evt, el){

// var wpContactForm = function(el, undefined) {
	$(el).find('form.wpcf7-form').each(function(i, form) {
		// return;
		var wpcf7 = window.wpcf7;
		console.log('wpcf7', wpcf7);
		if (wpcf7 !== undefined) {
			wpcf7.preinit();
			var $form = $(form);
			if ($form.find('.ajax-loader').size() === 0) {
				wpcf7.initForm($form);
				if (wpcf7.cached) {
					wpcf7.refill($form);
				}
			}
		}
	});
// };
});


$(window).on('load', function(){
		
	document.addEventListener('wpcf7mailsent', function (event) {
		var ww = $('.wpcf7-submit').outerWidth();
		var w = $('.wpcf7-submit').width();
		console.log('contact form widths', w, ww);
		$('.form__submit__ok').css( {width:ww} );
		var svg = '<svg class="check__svg" viewBox="0 0 17.64 13.85"><path class="check__path" d="M1.81,6.5L6.64,11.33L15.81,1.58"/></svg>';
		var t = $('.form__submit__ok').text();
		$('.form__submit__ok').html('');
		$('.form__submit__ok').append( $(svg) );
		$('.form__submit__ok').append(t);
		$('.form__submit__ok').append( $(svg) );
		setTimeout(function() {
			$('.form__submit').addClass('is-submitted');
		}, 50);
		setTimeout(function() {
			$('.form__submit').addClass('is-submittedb');
		}, 550);
	});

	// wpContactForm( $('body') );

});

// module.exports = wpContactForm;
