var $ = require('jquery');
var ismobile = require('ismobile');

// ismobile = true; // DEBUG



var canReveal = false;
var wh = 1000;
var bh = 1000;
var ww = 1000;
var bw = 1000;
var head_limit = 0;
var scrollTop = 0;
var toReveal = [];
var toRevealGroup = [];
var previousScrollTop = 0;

var limit = 0.85;
// limit = 1;
if ( ismobile ) {
	limit = 1;
}

var io = false;
var ioOpts = {
	/* Using default options. Details below */
	// rootMargin: '-30% 0px -15% 0px'
	rootMargin: '0px 0px 0px 0px',
	threshold: 0
	// trackVisibility: true,
	// delay: 100
};
var ioCallback = function(entries, ioagain){
	console.log('IO entries', canReveal, entries);
	// var done_together = 0;
	if ( !canReveal ) {
		return;
	}
	toReveal = [];
	for(var i=0,j=entries.length;i<j;i++){
		var d = entries[i];
		// console.log('d', d);
		if ( d.isIntersecting ) {
			ioagain.unobserve( d.target );
			toReveal.push( d.target );
			// $(d.target).trigger('onreveal');
			// console.log('done', done_together);
			// done_together++;
		}
	}
	$(toReveal).each(function(index) {
		var element = $(this);
		if (element.data('wait-reveal'))
			return;
		element.data('wait-reveal', !0)
		setTimeout(function() {
			// alert('bb')
			element.trigger('reveal')
		}, 100 * index)
	});
};

var useImmediateAnim = true;
if ( $('html').attr('data-reveal-use-immediate-anim') === '0' ) {
	useImmediateAnim = false;
}
if ( $('body').attr('data-reveal-use-immediate-anim') === '0' ) {
	useImmediateAnim = false;
}





var isIoSupported = function(){
	if ('IntersectionObserver' in window &&
		'IntersectionObserverEntry' in window &&
		'intersectionRatio' in window.IntersectionObserverEntry.prototype ) {
		return true;
	}
	return false;
};

var setIoElements = function(){
	console.log('setioelements', ismobile, isIoSupported());
	if ( ismobile && isIoSupported() ) {
		if ( !io ) {
			io = new IntersectionObserver( ioCallback, ioOpts );
			console.log('io', io);
		}
		// io.observe( $('.scroll-reveal').not('.is-revealed').get(0) );
		$('.scroll-reveal').not('.is-revealed').each(function(i, el){
			console.log('add element to observe', el, io);
			io.observe(el);
		});
	}
};

$(window).on('addScrollRevealElements', function(){
	setIoElements();
});

var window_size = {
	'x': 1000,
	'y': 1000
};
var offsets = {
	'x': 'left',
	'y': 'top'
};

var resizeHandler = function() {
	console.log('resizeHandler');
	wh = $(window).height();
	bh = $('body').height();
	if ( ismobile ) {
		bh = bh - 150;
	}
	// console.log('bh', bh);
	// console.log('wh', wh);
	if ( $('.head__limit').size() ) {
		head_limit = $('.head__limit').offset().top;
	}
	document.documentElement.style.setProperty("--wh", wh);
	document.wh = wh;
	document.documentElement.style.setProperty("--bh", bh);
	document.bh = bh;
	document.documentElement.style.setProperty("--bhminuswh", bh - wh);
	document.bhminuswh = bh - wh;
	// alert('aaaa')
	ww = $(window).width();
	bw = $('body').width();
	if ( ismobile ) {
		bw = bw - 150;
	}
	// console.log('bw', bw);
	// console.log('ww', ww);
	// if ( $('.head__limit').size() ) {
	// 	head_limit = $('.head__limit').offset().top;
	// }
	document.documentElement.style.setProperty("--ww", ww);
	document.ww = ww;
	document.documentElement.style.setProperty("--bw", bw);
	document.bw = bw;
	document.documentElement.style.setProperty("--bwminusww", bw - ww);
	document.bwminusww = bw - ww;

	window_size['x'] = ww;
	window_size['y'] = wh;
	// $(window).trigger('scroll');
	scrollHandler();
	// setIoElements();
};

if ( window.ResizeObserver ) {
	
	var resizeObserver = new ResizeObserver(function(entries){
		console.log('entries', entries);

		var scroll_top_offset = 0;
		var scroll_left_offset = 0;
		if ( $('#smooth-content').size() > 0 ) {
			scroll_top_offset = $('#smooth-content').offset().top;
			scroll_left_offset = $('#smooth-content').offset().left;
			// console.log('scroll_top_offset', scroll_top_offset);
		}
		$('.scroll-reveal').not('.is-revealed').each(function(){
			this.el_offset_top = $(this).offset().top - scroll_top_offset;
			this.el_offset_left = $(this).offset().left - scroll_left_offset;
		});

		resizeHandler();
	});

	resizeObserver.observe($('body').get(0));
	
}

var index_to;
var main_index = 0;
var main_main_index = 0;
var el_offset_top = 0;
var direction = 'y';
var flickity = false;
$(window).on('reveal:setDirection', function(evt, datas){
	console.log('reveal:setDirection')
	console.log('datas', datas);
	direction = datas.direction;
});
$(window).on('reveal:setFlickityAsScrollManager', function(evt, datas){
	console.log('reveal:setFlickityAsScrollManager')
	flickity = datas.flickity;
	datas.flickity.on('scroll', function( evt, progress ){
		scrollHandler(progress);
		// console.log('progress', progress);
		// $('body').toggleClass('is-navbar-open', progress > 20);
	});
});
var scrollHandler = function(progress, undefined) {
	// alert('aaaaaa')
	// console.log('scrollHandler');
	if ( !canReveal ) {
		return;
	}
	// performance.mark('beginScrollHandler');
	// alert('aaaaaaaa')
	if ( progress !== undefined && !isNaN(progress) ){
		scrollTop = progress;
	}
	else if ( flickity ){ // needed when when page appear on anchor so scrolltop is > 0
		scrollTop = flickity.getProgress();
	}
	else {
		scrollTop = $(window).scrollTop();
	}
	// console.log('reveal scroll', scrollTop);

	// document.documentElement.style.setProperty("--scrolltop", scrollTop);
	document.scrollTop = scrollTop;
	// console.log('previousScrollTop', previousScrollTop);
	// console.log('scrollTop', scrollTop);
	// console.log('head_limit', head_limit);
	if (scrollTop > head_limit && previousScrollTop < head_limit) {
		$('body').addClass('is-scrolled-down-head-limit');
	} else if ( scrollTop <= head_limit && previousScrollTop > head_limit ) {
		$('body').removeClass('is-scrolled-down-head-limit');
	}
	 if (previousScrollTop == 0 && scrollTop > 0) {
		$('body').addClass('is-scrolled-down');
	} else if ( scrollTop == 0 && previousScrollTop > 0 ) {
		$('body').removeClass('is-scrolled-down');
	}
	if (scrollTop > previousScrollTop && scrollTop > 5) {
		$('body').addClass('is-scrolling-down');
	} else {
		$('body').removeClass('is-scrolling-down');
	}
	if ( scrollTop >= bh - wh - 10 ) {
		$('body').addClass('is-scrolled-to-bottom');
	}
	else {
		$('body').removeClass('is-scrolled-to-bottom');
	}
	$('body').toggleClass('is-scrolling-up', scrollTop < previousScrollTop );
	if (scrollTop > 500) {
		$('body').addClass('is-scrolling-down-later');
	} else {
		$('body').removeClass('is-scrolling-down-later');
	}
	if (scrollTop > 100) {
		$('body').addClass('is-scrolling-down-later-100');
	} else {
		$('body').removeClass('is-scrolling-down-later-100');
	}
	if (scrollTop > 50) {
		$('body').addClass('is-scrolling-down-later-50');
	} else {
		$('body').removeClass('is-scrolling-down-later-50');
	}
	previousScrollTop = scrollTop;
	if ( ismobile && isIoSupported() ) {
		return;
	}
	var a = 0;
	$('.scroll-reveal').not('.is-revealed').each(function(){
		a++;
	});
	// performance.mark('beginScrollReveal');
	// var revealLimit = wh * limit + scrollTop;
	// console.log('window_size[direction] * limit', window_size[direction] * limit);
	// console.log('scrollTop', scrollTop);
	var revealLimit = window_size[direction] * limit + scrollTop;
	// console.log('revealLimit', revealLimit);
	toReveal = [];
	toRevealGroup = [];
	// var should_use_index = true;
	var index = 0; // manual index to speed up anims of above blocs not visible (when page appear on anchor so scrolltop is > 0)
	// ... otherwise we have to wait before something is visible because previous anim are played with stagger
	var scroll_offset = 0;
	if ( $('#smooth-content').size() > 0 ) {
		scroll_offset = $('#smooth-content').offset()[offsets[direction]];
		// console.log('scroll_offset', scroll_offset);
	}
	if ( $('.flickity-slider').size() > 0 && direction == 'x' ) {
		scroll_offset = $('.flickity-slider').last().offset()[offsets[direction]];
		// console.log('scroll_offset', scroll_offset);
	}
	// var count = $('.scroll-reveal').not('.is-revealed').size();
	// console.log('count', count);
	$('.scroll-reveal').not('.is-revealed').each(function(){
		var element = $(this);
		var thisRevealLimit = element.attr('data-trigger');
		if (thisRevealLimit) {
			thisRevealLimit = window_size[direction] * Number(thisRevealLimit) + scrollTop
		} else {
			thisRevealLimit = revealLimit
		}
		// if ( !this.el_offset_top ) {
		// 	return;
		// }
		el_offset_top = element.offset()[offsets[direction]] - scroll_offset;
		// console.log('thisRevealLimit', thisRevealLimit);
		// console.log('el_offset_top', el_offset_top);
		// el_offset_top = this.el_offset_top;
		if ( element.attr('data-reveal-debug') == '1' ) {
			// console.log('thisRevealLimit', thisRevealLimit);
			// console.log('el_offset_top', el_offset_top);
			// console.log('scrollTop', scrollTop);
			// console.log('wh * Number(thisRevealLimit)', wh * Number(thisRevealLimit));
			// console.log('wh', wh);
			// console.log('thisRevealLimit', thisRevealLimit);
		}
		if (thisRevealLimit >= el_offset_top) {
			var p = element.closest('[data-reveal-group]');
			// var p = [];
			if ( p.length > 0 ) {
				// alert('h')
				// alert(el_offset_top)
				// alert(scroll_offset)
				// alert(element.get(0).className)
				console.log('p', p);
				console.log('element', element);
				if (p.data('wait-reveal') != true) {
					p.data('wait-reveal', true)
					var els = [];
					p.find('.scroll-reveal').each(function(){
						els.push( this )
					});
					var group_decal = 0;
					var group_decal_attr = parseFloat( p.attr('data-reveal-group-decal') ) || 0;
					if ( group_decal_attr ) {
						group_decal = group_decal_attr;
					}
					index = parseFloat( p.attr('data-group-index') ) || index;
					var el_size = direction == 'y' ? p.height() : p.width();
					// if ( thisRevealLimit >= el_offset_top + el_size ) {
					if ( el_offset_top + el_size < scrollTop && useImmediateAnim ) {
						$(p).attr('forceindexzero', 1);
						$(element).attr('forceindexzero', 1);
						console.log('reset index');
						index = 0; // immediate anim
					}
					toRevealGroup.push({
						groupIndex: index,
						groupDecal: group_decal_attr,
						decal: parseFloat(p.attr('data-reveal-group')),
						els: els
					})
				}
				else { // cause la boucle passe sur tous les sous elements du group mais ne remet pas l'index a zero cause il zappe le if juste au dessus
					index--;
				}
			}
			else {
				// console.log('why');
				var el_size = direction == 'y' ? element.height() : element.width();
				$(element).attr('thisRevealLimit', thisRevealLimit);
				$(element).attr('el_offset_top', el_offset_top);
				$(element).attr('scroll_offset', scroll_offset);
				$(element).attr('el_size', el_size);
				$(element).attr('el_offset_topel_h', el_offset_top + el_size);
				$(element).attr('scrollTop', scrollTop);
				$(element).attr('diff', scroll_offset - (el_offset_top + el_size));
				$(element).attr('diffb', (el_offset_top + el_size) - scroll_offset);
				$(element).attr('diffc', el_offset_top - (el_offset_top + el_size));
				$(element).attr('diffd', (el_offset_top + el_size) - el_offset_top);
				// if ( thisRevealLimit >= el_offset_top + el_size && useImmediateAnim ) {
				if ( el_offset_top + el_size < scrollTop && useImmediateAnim ) {
				// if ( thisRevealLimit >= el_offset_top + el_size && useImmediateAnim ) {
					// si la position de fin de l'element est inferieur l'extremité haute de la fenetre
					// if el.offset().left + el_size < scrolltop
					$(element).attr('forceindexzero', 1);
					console.log('reset index');
					index = 0; // immediate anim
				}
				// toReveal.push(element)
				toReveal.push({
					groupIndex: index,
					// groupDecal: group_decal_attr,
					// decal: parseFloat(p.attr('data-reveal-group')),
					el: element
				})
			}
		}
		index++;
		// console.log('index', index, $(element).attr('class'));
	});
	// performance.mark('endScrollReveal');
	var temp_index = main_index;
	$(toReveal).each(function() {
		var element = $(this.el);
		if (element.data('wait-reveal'))
			return;
		element.data('wait-reveal', !0)
		var decal = 0;
		var attr_decal = parseFloat( element.attr('data-reveal-decal') );
		element.attr('reveal-normal', '1')
		if ( attr_decal > 0 ) {
			decal = decal + attr_decal * 1000;
		}
		element.attr('reveal-decal', decal)
		var fdecal = 100 * (this.groupIndex + main_index) + decal;
		element.attr('reveal-fdecal', fdecal);
		element.attr('reveal-groupIndex', this.groupIndex);
		element.attr('reveal-main_index', main_index);
		element.attr('reveal-decal', decal);
		element.attr('reveal-main_main_index', main_main_index);
		setTimeout(function() {
			// alert('bb')
			element.trigger('reveal')
		}, fdecal)
		temp_index++;
	});
	main_main_index++;
	main_index = temp_index;
	clearTimeout( index_to );
	index_to = setTimeout(function() {
		main_index = 0;
	}, 100);
	// performance.mark('endScrollReveal2');
	$(toRevealGroup).each(function() {
		var main_decal = this.decal * 1000;
		var group_index = this.groupIndex;
		var group_decal = this.groupDecal * 1000;
		var els = this.els;
		// console.log('els', els);
		$(els).each(function(index){
			var element = $(this);
			if (element.data('wait-reveal'))
				return;
			element.data('wait-reveal', !0)
			var decal = main_decal * index;
			var attr_decal = element.attr('data-reveal-group-decal');
			// console.log('attr_decal', attr_decal);
			if ( attr_decal && attr_decal.length > 0 ) {
				attr_decal = parseFloat( attr_decal );
				decal = attr_decal * 1000;
			}
			element.attr('reveal-group', 100 * group_index + decal + group_decal)
			element.attr('reveal-group-index', group_index)
			element.attr('reveal-group-decal', decal)
			element.attr('reveal-group-decalb', group_decal)
			// console.log('attr_decal', decal, index);
			setTimeout(function() {
				// alert('bb')
				element.trigger('reveal')
			}, 100 * group_index + decal + group_decal)
		})
	});
	// performance.mark('endScrollReveal3');
	// performance.measure('scrollRevealScrollHandler','beginScrollHandler', 'beginScrollReveal');
	// performance.measure('scrollRevealCreateGroup','beginScrollReveal', 'endScrollReveal');
	// performance.measure('scrollRevealTriggerElement','endScrollReveal', 'endScrollReveal2');
	// performance.measure('scrollRevealTriggerGroup','endScrollReveal2', 'endScrollReveal3');

	// console.log('scrollRevealScrollHandler', performance.getEntriesByName('scrollRevealScrollHandler'));
	// console.log('scrollRevealCreateGroup', performance.getEntriesByName('scrollRevealCreateGroup'));
	// console.log('scrollRevealTriggerElement', performance.getEntriesByName('scrollRevealTriggerElement'));
	// console.log('scrollRevealTriggerGroup', performance.getEntriesByName('scrollRevealTriggerGroup'));
};

var scrollRevealHandler = function() {
	// alert('bbbb')
	var element = $(this);
	element.attr('data-ev', 'ok')
	if (element.hasClass('is-revealed'))
		return;
	element.attr('data-evb', 'okb')
	var animation = element.attr('data-reveal');
	console.log('scroll reveal element', $(this).get(0).className, animation, $(this).size());
	element.trigger('reveal-'+animation)
	element.addClass('is-revealed');
};

$('body').on('reveal', '.scroll-reveal', scrollRevealHandler);
$(window).on('resize orientationchange', resizeHandler).trigger('resize');
$(window).on('scroll', scrollHandler);
// $(window).trigger('reveal:reveal');
$(window).on('reveal:reveal', resizeHandler);
$('window').on('load', function() {
	// $(window).trigger('resize');
	resizeHandler();
	// setIoElements();
});
$(window).on('reveal:changelimit', function(a, val, c) {
	// alert(val)
	// alert(b)
	// alert(c)
	limit = val;
	// $(window).trigger('resize');
	// resizeHandler();
	// setIoElements();
});

module.exports = function(to_set) {
	console.log('change reveal', to_set);
	canReveal = to_set;
	if ( to_set === true ) {
		is_intro = true;
		resizeHandler();
		setIoElements();
	}
};