var $ = require('jquery');

module.exports = function(evt, el, url){
	// console.log('eeeeeeeeeeeeeeeeeeeel', el);
	// alert('x')
	// alert($(el).attr('role'))
	// alert(el)
	if ( $(el).attr('target') == '_blank' ) return false;
	if ( $(el).attr('data-no-ajax') == '1' ) return false;
	if ( $(el).attr('role') == 'button' ) {
		evt.preventDefault();
		return false;
	}
	url = $(el).attr('href');
	// alert( url )
	// if ( url.indexOf('tel:') >= 0 || url.indexOf('mailto:') >= 0 || url == '#' || url == 'javascript:void(0);' ) {
	if ( url == 'javascript:void(0);' ) {
		evt.preventDefault();
		return false;
	}
	// console.log('retrun TRUUUUUUUUUUUUUUUUUUUUUUE');
	// if nothing to block, return true to contiue the others processes
	return true;
};