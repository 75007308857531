var $ = require('jquery');

module.exports = function(el) {
	return new TimelineMax({paused:true})
	.fromTo( $(el).find('.svg__arrow__right__lineh'), 0.5, {
		drawSVG:"100% 100%"
	}, {
		drawSVG:"0% 100%",
		ease: Power0.easeIn
	} )

	.fromTo( $(el).find('.svg__arrow__right__curvebot, .svg__arrow__right__curvetop'), 0.5, {
		drawSVG:"100% 100%"
	}, {
		drawSVG:"0% 100%",
		ease: Power0.easeOut
	}, 0.5 )

	.fromTo( $(el).find('.svg__arrow__right__pointe'), 0.5, {
		drawSVG:"50% 50%"
	}, {
		drawSVG:"0% 100%",
		ease: Power0.easeOut
	}, 0.5 )

	.fromTo( $(el).find('.svg__arrow__right__circle'), 0.75, {
		opacity: 0
	}, {
		opacity: 1,
		ease: Power0.easeOut
	}, 0.85 )

};