var $ = require('jquery');
require('./mask.js');
var reveals = require('./0reveals');
var closeCtaAnim = require('./closectaanim.js');
var bulleAnim = require('./bulleanim.js');
var pictoAnim = require('./pictoanim.js');

reveals('citymodelanim', function(el, tl, start_label){
	tl.to( el, 0.5, {
		opacity: 1
	}, start_label );

	tl.fromTo( bulleAnim( $(el).find('.we__txt__bloc') ), 1.2, {
		progress: 0
	}, {
		progress: 1,
		ease: Power0.easeOut
	}, start_label+'+=0.4');

	tl.fromTo( pictoAnim( $(el).find('.city__map__simple') ), 1.2, {
		progress: 0
	}, {
		progress: 1,
		ease: Power0.easeOut
	}, start_label+'+=0.6');

	tl.staggerFromTo( $(el).find('.we__title__bloc--city > div, .we__shad__bloc--city'), 1, {
		opacity: 0,
		y: 30
	}, {
		opacity: 1,
		y: 0,
		ease: Power2.easeOut
	}, 0.07, start_label+'+=0.5');

	tl.staggerFromTo( $(el).find('.city__page__nav'), 1, {
		opacity: 0,
		y: 30
	}, {
		opacity: 1,
		y: 0,
		ease: Power2.easeOut
	}, 0.1, start_label+'+=1.4');

	var is_phone_portrait = $('.head__logo__a__simple').is(':visible') && $(window).width() < $(window).height() ? true : false;
});
