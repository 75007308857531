(function(window, factory) { 'use strict';
	if (typeof define === 'function' && define.amd) {
		define(['jquery'], factory);
	} else if (typeof exports !== 'undefined') {
		module.exports = factory(
			window, 
			require('jquery'),
			require('imagesloadedwithsize'),
			require('touchswipe')
		);
	} else {
		factory(window, jQuery);
	}

}(window, function(window, $, imagesLoaded) {

var lightSliderFactory = function(opt){
return function () {
	var options = $.extend({}, lightSliderFactory_DEFAULTS, opt);
	var $container = 0;
	var prev_item = 0;
	var cur_item = 0;
	var $items = [];
	var $nav_items = [];
	var item_count = 0;
	var datas = {};
	var auto_play = options.autoPlay;
	var auto_play_timer = 0;
	var navs_clicked = false;

	var init = function(el){
		// alert('bb')
		$container = $(el);
		$items = $container.find(options.itemsSelector);
		if ( $items.size() < 2 ) {
			return;
		}
		item_count = $items.size();
		if ( options.navsItem.length > 0 ) {
			$nav_items = $(options.navsItem);
		}
		else {
			$nav_items = $container.find(options.navsSelector);
		}
		$nav_items.bind('click', slideNavClicked);
		datas.navItems = $nav_items;
		if ( options.arrowsItem.length > 0 ) {
			$(options.arrowsItem).bind('click', slideArrowClicked);
		}
		else {
			$container.find(options.arrowsSelector).bind('click', slideArrowClicked);
		}
		if ( options.useTouchEvents && $.fn.swipe ) {
			$container.swipe({
				swipeLeft:function(event, direction, distance, duration, fingerCount) {
					navs_clicked = true;
					stopAutoPlay();
					decalItem(1);
				},
				swipeRight:function(event, direction, distance, duration, fingerCount) {
					navs_clicked = true;
					stopAutoPlay();
					decalItem(-1);
				}
			});
		}

		if ( options.autoindex ) {
			var t = $items.filter(':visible');
			var index = t.index();
			// console.log('index', index, t.get());
			cur_item = index;
		}

		if ( options.autoindexquery ) {
			var t = $items.filter(options.autoindexquery);
			var index = Math.max(0, t.index());
			// console.log('index', index, t.get());
			cur_item = index;

			// alert(cur_item)
		}

		options.oninit && options.oninit.call(null, $container, el, options, $items, datas, showItem, decalItem);
		if ( auto_play ) {
			if ( options.stopOnHover ) {
				$container.hover(function(){
					navs_clicked = false;
					stopAutoPlay();
				}, function(){
					if ( options.autoPlayForceRestart || navs_clicked === false ) {
						startAutoPlay();
					}
				});
			}
			startAutoPlay();
		}

		if ( options.forceSameHeight ) {
			$(window).on('resize', function(){
				options.forceSameHeight.call(null, $container, el, options, $items);
			})
			// setTimeout(function() {
			// 	options.forceSameHeight.call(null, $container, el, options, $items);
			// }, 5000);
			
			// load all img inside the slide and once done, do resize again
			var imgs = $container.find('img').get();
			if ( imgs && imgs.length > 0 ){
				imagesLoaded( imgs, function() {
					// $(el).data('preloadimp', '1');
					// alert('loaded')
					options.forceSameHeight.call(null, $container, el, options, $items);
				});
			}
			// else {
			// 	preloadVisibleAssetsDone(callback);
			// }
		}

		refreshNavItems();
	};

	var startAutoPlayo = function(){
		console.log('startAutoPlay');
		auto_play_timer = setInterval(function() {
			decalItem(1);
		}, options.autoPlayDelay);
	};

	var stopAutoPlayo = function(){
		clearInterval(auto_play_timer);
		console.log('stop auto play');
	};

	var startAutoPlay = function(){
		auto_play_timer = setTimeout(function() {
			if ( !isHidden() ) {
				decalItem(1);
				startAutoPlay();
			}
		}, options.autoPlayDelay);
		$container.trigger('lightslide:startautoplay', [cur_item, item_count, ( cur_item + item_count + 1 ) % item_count, options.autoPlayDelay]);
	};

	var stopAutoPlay = function(){
		clearTimeout(auto_play_timer);
		$container.trigger('lightslide:stopautoplay', [cur_item, item_count, ( cur_item + item_count + 1 ) % item_count, options.autoPlayDelay]);
		console.log('stop auto play');
	};

	var getHiddenProp = function(){
	    var prefixes = ['webkit','moz','ms','o'];
	    
	    // if 'hidden' is natively supported just return it
	    if ('hidden' in document) return 'hidden';
	    
	    // otherwise loop over all the known prefixes until we find one
	    for (var i = 0; i < prefixes.length; i++){
	        if ((prefixes[i] + 'Hidden') in document) 
	            return prefixes[i] + 'Hidden';
	    }

	    // otherwise it's not supported
	    return null;
	};

	var isHidden = function() {
	    var prop = getHiddenProp();
	    // console.log('prop', prop);
	    if (!prop) return false;
	    
	    return document[prop];
	};

	var slideNavClicked = function(ev){
		navs_clicked = true;
		stopAutoPlay();
		if ( !options.nowait && $container.hasClass(options.cantAnimClass) ) {
			return;
		}
		var $el = $(ev.currentTarget);
		// var data_nb = $el.attr(options.itemNbAttr);
		// var data_nb = $el.index();
		prev_item = cur_item;
		cur_item = $el.index() % item_count;
		if ( cur_item != prev_item ) {
			var way = 1;
			if ( prev_item > cur_item ) {
				way = -1;
			}
			showItem(way);
		}
	};

	var changeItem = function(index){
		navs_clicked = true;
		stopAutoPlay();
		if ( !options.nowait && $container.hasClass(options.cantAnimClass) ) {
			return;
		}
		prev_item = cur_item;
		cur_item = index;
		if ( cur_item != prev_item ) {
			showItem();
		}
	};

	var slideArrowClicked = function(ev){
		navs_clicked = true;
		stopAutoPlay();
		var $el = $(ev.currentTarget);
		var way = ($el.hasClass(options.arrowLeftClass)) ? -1 : 1;
		decalItem(way);
	};

	var decalItem = function(way){
		if ( !options.nowait && $container.hasClass(options.cantAnimClass) ) {
			return;
		}
		prev_item = cur_item;
		cur_item += way;
		if ( cur_item < 0 ) {
			cur_item = item_count - 1;
		}
		else if ( cur_item >= item_count ) {
			cur_item = 0;
		}
		showItem(way);
	};

	var showItem = function(way){
		$container.addClass(options.cantAnimClass);
		var wayb = 1;
		if ( prev_item - cur_item == 1 ) {
			wayb = -1;
		}
		else if ( prev_item === 0 && cur_item  == $items.size() - 1 ) {
			wayb = -1;
		}
		if ( !way ) {
			way = wayb;
		}
		// callback qui permet de gerer la transition
		options.onshowitem.call(null, $container, prev_item, cur_item, options, $items, datas, way);

		refreshNavItems();
	};

	var refreshNavItems = function(){
		$nav_items.removeClass(options.navsClassSelected)
			.eq(cur_item).addClass(options.navsClassSelected);
		$items.attr('tabindex', '-1');
		$items.find('[tabindex]').attr('tabindex', '-1');
		$items.attr('aria-hidden', 'true');
		$items.eq(cur_item).attr('tabindex', '0');
		$items.eq(cur_item).find('[tabindex]').attr('tabindex', '0');
		$items.eq(cur_item).attr('aria-hidden', 'false');
	};

	return {
		'init' : init,
		'decalItem' : decalItem,
		'stopAutoPlay' : stopAutoPlay,
		'startAutoPlay' : startAutoPlay,
		'changeItem' : changeItem,
		'goTo' : changeItem
	};

}();
}

var lightSliderFactory_DEFAULTS = {
	'itemsSelector' : '.js-slideitem',
	'navsSelector' : '.js-slideitemnav',
	'arrowsSelector' : '.js-slidearrow',
	'navsItem' : [],
	'arrowsItem' : [],
	'arrowLeftClass' : 'js-slidearrow--left',
	'cantAnimClass' : 'cantanim',
	'itemNbAttr' : 'data-nb',
	'navsClassSelected' : 'is-selected',
	'useTouchEvents' : true,
	'autoPlay' : false,
	'autoPlayForceRestart' : false,
	'autoPlayDelay' : 5000,
	'nowait' : false, // prevent slide to change if previous change anim is not finished
	'autoindex' : false,
	'autoindexquery' : false,
	'stopOnHover' : true,
	'oninit' : function(){},
	'onshowitem' : function(){},
	'forceSameHeight' : false
};

// =======================
$.fn.lightSlider = function (option, param) {
	return this.each(function () {
		var $this   = $(this);
		var data    = $this.data("cyma.lightSlider");
		var options = typeof option === "object" && option;

		if (!data) {
			// alert('aaaa')
			data = lightSliderFactory(options);
			data.init(this);
			$this.data("cyma.lightSlider", data);
		}
		if (typeof option === "string") {
			console.log('data', data);
			data[option](param);

		}
	});
};

}));

