var $ = require('jquery');
require('./mask.js');
var reveals = require('./0reveals');
var shuffle = require('shuffle');
var pictoAnim = require('./pictoanim.js');
var first_pass = true;

reveals('pagemodelanim', function(el, tl, start_label){
	tl.to( el, 0.5, {
		opacity: 1
	}, start_label );

	var els = [];
	if ( $(el).find('.vocation__head__bg__wrap').size() > 0 ) {
		els.push( $(el).find('.vocation__head__bg__wrap').get(0) );
		els.push( $(el).find('.vocation__head__visu__wrap').get(0) );
		els.push( $(el).find('.vocation__head__logo').get(0) );
		els.push( $(el).find('.vocation__head__title__wrap').get(0) );
		els.push( $(el).find('.contact__cta__big').get(0) );
	}

	tl.staggerFromTo( els, 2, {
		opacity: 0
	}, {
		opacity: 1,
		ease: Power1.easeInOut
	}, 0.25,  start_label + '+=0')

	// tl.addLabel( 'lines', start_label+'+=0.2' );



	

	// tl.fromTo( pictoAnim( $(el).find('.home__picto') ), 0.8, {
	// 	progress: 0
	// }, {
	// 	progress: 1,
	// 	ease: Power0.easeOut
	// }, 'lines' )

	// tl.staggerFromTo( $(el).find('.we__txt__bloc, .home__menu__link '), 0.9, {
	// 	scale: 0.5,
	// 	opacity: 0
	// }, {
	// 	scale: 1.01,
	// 	opacity: 1,
	// 	ease: Power2.easeOut
	// }, 0.05, 'lines' )



});
