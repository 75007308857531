var anims = {};

module.exports = function(a, b, undefined) {
    // console.log('reveals', a, b, anims);
    if ( b === undefined ) {
        return anims[a];
    }
    else {
        anims[a] = b;
    }
};