var $ = require('jquery');
require('./mask.js');
var reveals = require('./0reveals');
var closeCtaAnim = require('./closectaanim.js');

reveals('menumodelanim', function(el, tl, start_label){
	tl.to( el, 0.5, {
		opacity: 1
	}, start_label );

	tl.staggerFromTo( $(el).find('.page__menu__title, .page__menu__li '), 1.2, {
		y: -30,
		opacity: 0
	}, {
		y: 0,
		opacity: 1,
		ease: Power2.easeOut
	}, 0.1, start_label+'+=0.4' )

	// var lines = new SplitText( $(el).find('.bg__text'), { type: "lines" });
	// console.log('lines.lines', lines.lines);
	// lines = $(el).find('.bg__text__text').wrapp('jq__mask').childs;

	// tl.staggerFromTo(lines, 0.75*1.2, {
	// 	y:'110%',
	// 	ease: Power2.easeOut
	// }, {
	// 	y:'0%',
	// 	ease: Power2.easeOut
	// }, 0.15*1.2, start_label+'+=1.65');

	// closeCtaAnim( tl, el, start_label+'+=1.75' );
});
