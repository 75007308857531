(function(window, factory) {
	'use strict';
	if (typeof exports !== 'undefined') {
		require('slick');
		module.exports = factory(
			window,
			require('jquery'),
			require('leaflet07'),
			require('ismobile'),
			require('./leaflet-markers.js')
		);
	} else {
		factory(window, jQuery);
	}

}(window, function(window, $, L, ismobile) {

	L.CRS.CustomZoom = L.extend({}, L.CRS.Simple, {
		scale: function(zoom) {
			// return Math.pow(1.25, zoom);
			return Math.pow(1.15, zoom);
		}
	});

	// var getMarker = function(data, marker_data){
	var getMarker = function( nb, color, el, fact ){
		// if ( data.markertype == '1' ) {
			nb = nb + 1;
			var marker_svg_name = $(el).attr('data-pictosvgname') || $(el).attr('data-pictosvgname-parent') || 'velo';
			console.log('marker_svg_name', marker_svg_name);
			var marker_html = '';
			marker_html += '<div class="hw__marker__wrap '+$(el).attr('data-marker-uid')+'">';
			marker_html += '<div class="hw__marker">'+$('[data-js="marker-svg-'+marker_svg_name+'"]').html()+'</div>';
			if ( $(el).attr('data-catmodel') == 'markerlayerbus' ) {
				marker_html += '<div class="hw__marker__txt">'+$(el).attr('data-name')+'</div>';
				var bus_lines = $(el).attr('data-lignes').split(' ');
				bus_lines = '<div class="bus__line__item"><div>'+bus_lines.join('</div></div><div class="bus__line__item"><div>')+'</div></div>';
				var classname = 'hw__marker__popupbus '+$(el).attr('data-classname');
				marker_html += '<div class="'+classname+'"><div class="bus__line__items">'+bus_lines+'</div></div>';
			}
			else {
				var classname = 'hw__marker__popup '+$(el).attr('data-classname');
				var popup_html = '<div class="hw__marker__adr__title">'+$(el).attr('data-name')+'</div>';
				popup_html += '<div class="hw__marker__adr__txt">'+$(el).attr('data-adr')+'</div>';
				marker_html += '<div class="'+classname+'"><div class="hw__marker__adr">'+popup_html+'</div></div>';
			}
			marker_html += '</div>';
			return L.divIcon({
				className: 'my-div-icon',
				// iconSize: [data.markerw, data.markerh],
				iconSize: [55 * fact, 55 * fact],
				// iconAnchor: [data.markerw/2, data.markerh],
				iconAnchor: [27.5 * fact, 27.5 * fact],
				popupAnchor: [60 * fact, 0 * fact],
				// html: '<img src="'+data.markersrc+'"/>'
				// html: '<div class="hw__marker__wrap" data-loc-open-marker-detail="'+$(el).attr('data-marker-uid')+'"><div class="hw__marker__bg"></div><span class="hw__marker__label">'+nb+'</span></div>'
				html: marker_html
			});
		// }
	};

	$( document ).on('click', '[data-loc-open-marker-detail]', function(evt) {
		var el = $(evt.currentTarget);
		var uid = el.attr('data-loc-open-marker-detail');
		$('[data-loc-popup-uid]').removeClass('is-active');
		$('[data-loc-popup-uid="'+uid+'"]').addClass('is-active');
	});

	$( document ).on('click', '[data-js="loc-popup-close"]', function(evt) {
		$('[data-loc-popup-uid]').removeClass('is-active');
	});

	var Plugin = function(container) {

		// alert('leaflet')

		var el = $(container).find('[data-js="map-container"]').get(0);
		el = $('<div style="width:100%;height:100%;" />').appendTo($(el)).get(0);
		var w = 3000;
		var w = 4000;
		var h = 2168;
		var h = 2869;
		var h = 2855;
		w = parseInt( $(container).attr('data-map-w'), 10 ) || 4000;
		h = parseInt( $(container).attr('data-map-h'), 10 ) || 3305;
		console.log('w', w);
		// alert(w)
		// alert(h)



		var maxBounds = [[0,0], [h, w]];
		var map = L.map(el, {
			crs: L.CRS.CustomZoom,
			minZoom: -100,
			maxBounds: maxBounds,
			maxBoundsResistance: 1.0,
			maxBoundsViscosity: 1.0,
			scrollWheelZoom: false
		});
		map.fitBounds(maxBounds);





		var maxzoom_add = 10;
		if ( ismobile ) {
			maxzoom_add = 15;
		}
		// alert( ismobile )
		// alert( maxzoom_add )
		var contain = false;
		var cover_zoom = map.getBoundsZoom(maxBounds, (contain != 1));
		// alert(cover_zoom)
		map.setZoom(cover_zoom + 1);
		map.options.minZoom = cover_zoom;
		map.options.maxZoom = cover_zoom + maxzoom_add;
		map.zoomOut();
		// alert(SITE_ROOT+'si/'+id+'-big.jpg')
		// var i = L.imageOverlay(SITE_ROOT+'si/map-bg-full.png', maxBounds);
		$(container).find('[data-js="map-bg"]').each(function(i, el){
			var i = L.imageOverlay($(el).find('img').attr('src'), maxBounds);
			console.log('i', i);
		    var r = i.addTo(map);
		    console.log('r', r);
		});





	    // L.imageOverlay(SITE_ROOT+'si/map-metros.png', maxBounds)
	    // 	.addTo(map)

	    // L.imageOverlay(SITE_ROOT+'si/map-pictos.png', maxBounds)
	    // 	.addTo(map)

	     // L.imageOverlay(SITE_ROOT+'si/map-timings.png', maxBounds)
	    	// .addTo(map)

	    // enable scroll wheel zoom once map is focus
	    // map.once('focus', function() { map.scrollWheelZoom.enable(); });

	    // L.imageOverlay(SITE_ROOT+'si/map-rues-6000.png', maxBounds)
	    	// .addTo(map);

	    // L.imageOverlay(SITE_ROOT+'si/map-logo.png', maxBounds)
	    	// .addTo(map);


	    var svg_w = 2700;
	    var svg_h = 2276;
	    var fact = w / svg_w;
	    console.log('fact', fact);
	    // create markers groups
	    var layer_groups = {};
	    var layer_ids = [];
    	console.log('map layers');
	    $(container).find('.layer__datas').not('[data-model="simplelayer"]').each(function(i, el){
	    	console.log('map layer');
	    	var id = $(el).attr('data-uid');
	    	var color = $(el).attr('data-color');
	    	layer_groups[id] = L.layerGroup($.map($(el).find('i'), function(mel, ii) {
				var posx = parseInt($(mel).attr('data-x'), 10) * fact;
				var posy = parseInt($(mel).attr('data-y'), 10) * fact;
				// reverse y as map origin is on the bottom of the image
				posy = h - posy;
				return L.marker(L.latLng([ posy, posx ]), {
					// icon: getMarker(data, marker_data),
					icon: getMarker( ii, color, mel, fact )
					// clickable: true
				})
				// .bindPopup({
				// 	autoPan: false,
				// 	maxWidth: 400,
				// 	popupBottom: false
				// })
				// .setInfos({
				// 	html: '<div class="ll__popup__content">test</div>'
				// })
			}))
			layer_ids.push(id);
				// .addTo(map)
	    });

	    // $(container).find('.map__with__layers__layers [data-model="maplayer"]').each(function(i, el){
	    $(container).find('[data-layer-id]').each(function(i, el){
	    	console.log( $(el).attr('data-layer-id') );
	    	var id = $(el).attr('data-layer-id');
	    	var src = $(el).find('img').attr('src');
	    	// alert(src)
	    	layer_groups[id] = L.layerGroup([
	    		// L.imageOverlay(src, maxBounds, {opacity:0})
	    		L.imageOverlay(src, maxBounds)
	    	])
				// .addTo(map)
			layer_ids.push(id);
	    });

	    // setTimeout(function() {
		    for(var i=0,j=layer_ids.length;i<j;i++){
		    	var id = layer_ids[i];
		    	layer_groups[id].addTo(map)
		    }
		    // for(var i=1,j=layer_ids.length;i<j;i++){
		    // 	var id = layer_ids[i];
		    // 	TweenMax.set( layer_groups[id].getLayers()[0]._image, {
		    // 		opacity: 0.001
		    // 	} );
		    // }
	    	
	    // }, 10);


	    // return;

	    var displayMarker = function(){
	    	$('.my-div-icon.is-visible').not(this).removeClass('is-visible');
	    	$(this).toggleClass('is-visible');
	    };

	    // var markers = $('.hw__marker__wrap');
	    // console.log('markers.size()', markers.size());
	    if ( ismobile ) {
		    $('.my-div-icon').on('click', displayMarker);
	    }

	    var getPrevStates = function(){
	    	var old_layer_id = $('.envi__menu__item.is-active').attr('data-uid');
	    	var prev_states = {};
	    	$('.envi__menu__item').not('[data-uid="all"]').each(function(i, el){
	    		var layer_id = $(el).attr('data-uid');
	    		var active = $(el).hasClass('is-active');
	    		if ( old_layer_id == 'all' ) {
	    			active = true;
	    		}
	    		prev_states[layer_id] = active;
	    	});
	    	console.log('prev_states', prev_states);
	    	return prev_states;
	    };

	    var toggleLayerOneLayerVisibleOnlyEvent = function(){
	    	toggleLayerOneLayerVisibleOnly( $(this) );
	    };

	    var toggleLayerOneLayerVisibleOnly = function(el){

	    	console.log('-------------------------------');
	    	// console.log('prev_states', prev_states);
	    	main_layer_id = '';
	    	// if this is an event
	    	// if ( prev_states == undefined ) {
		    	var main_layer_id = $(el).attr('data-uid');
		    	console.log('main_layer_id', main_layer_id);
		    	if ( $(el).hasClass('is-active') ) {
		    		return;
		    	}
		    	prev_states = getPrevStates();

		    	$('.envi__menu__item').removeClass('is-active');
		    	$(el).toggleClass('is-active');
	    	// }


	    	var next_states = {};
	    	$('.envi__menu__item').not('[data-uid="all"]').each(function(i, el){
	    		var layer_id = $(el).attr('data-uid');
	    		var active = $(el).hasClass('is-active');
	    		if ( main_layer_id == 'all' ) {
	    			active = true;
	    		}
	    		next_states[layer_id] = active;
	    	});
	    	console.log('next_states', next_states);

	    	refreshLayersVisibility(main_layer_id, prev_states, next_states);


	    };

	    var toggleLayerMultiLayerVisibleEvent = function(){
	    	toggleLayerMultiLayerVisible( $(this) );
	    };

	    var toggleLayerMultiLayerVisible = function(el){

	    	console.log('-----------xxxx------------');
	    	var main_layer_id = $(el).attr('data-layer-id');
	    	$(el).toggleClass('is-active');

	    	$(container).find('[data-layer-id]').each(function(i, el){
	    		var layer_id = $(el).attr('data-layer-id');
	    		var opacity = $(el).hasClass('is-active') ? 1 : 0;
    			TweenMax.to( layer_groups[layer_id].getLayers()[0]._image, 0.7, {
    				opacity: opacity
    			} );
		    		
	    	});


	    };

	    var refreshLayersVisibility = function(main_layer_id, prev_states, next_states){

	    	$('.envi__menu__item').not('[data-uid="all"]').each(function(i, el){
	    		console.log('----');
	    		var layer_id = $(el).attr('data-uid');
	    		if ( layer_id == 'all' ) {
	    			return;
	    		}
	    		var model = $(el).attr('data-model');
	    		var toggle = $(el).hasClass('is-active');
	    		console.log('toggle', toggle);
	    		console.log('main_layer_id', main_layer_id);
	    		if ( main_layer_id == 'all' ) {
	    			toggle = true;
	    		}
	    		console.log('toggle', toggle);
	    		console.log('layer_id', layer_id);
	    		console.log('model', model);

	    		if ( prev_states[layer_id] == next_states[layer_id] ) {
	    			return;
	    		}

	    		console.log('layer_groups', layer_groups);

		    	if ( model == 'maplayer' ) {
		    		if ( toggle ) {
		    			TweenMax.to( layer_groups[ layer_id].getLayers()[0]._image, 0.7, {
		    				opacity: 1
		    			} );
		    		}
		    		else {
		    			TweenMax.to( layer_groups[ layer_id].getLayers()[0]._image, 0.7, {
		    				opacity: 0
		    			} );
		    		}
		    	}
		    	else {
		    		var els = $.map( layer_groups[ layer_id].getLayers(), function(el, i){
		    			// return el;
		    			return el._icon.firstChild;
		    		} );
		    		if ( toggle ) {
			    		$(els).addClass('is-active');
		    			TweenMax.staggerFromTo( els, 0.8, {
		    				opacity: 0,
		    				y: -30
		    			}, {
		    				opacity: 1,
		    				y: 0,
		    				ease: Power2.easeOut
		    			}, 0.05 );
		    		}
		    		else {
		    			$(els).removeClass('is-active');
		    			TweenMax.staggerFromTo( els, 0.6, {
		    				opacity: 1,
		    				y: 0
		    			}, {
		    				opacity: 0,
		    				y: -30,
		    				ease: Power2.easeOut
		    			}, 0.05 );
		    		}
		    	}
	    	});

	    };

	    var toggleLayerMultiLayerVisibleOld = function(){

	    	var main_layer_id = $(this).attr('data-uid');
	    	console.log('-------------------------------');
	    	console.log('main_layer_id', main_layer_id);
	    	if ( $(this).hasClass('is-active') ) {
	    		// return;
	    	}
	    	
	    	$(this).toggleClass('is-active');

	    	

	    	$(this).not('[data-uid="all"]').each(function(i, el){
	    		console.log('----');
	    		var layer_id = $(el).attr('data-uid');
	    		if ( layer_id == 'all' ) {
	    			return;
	    		}
	    		var model = $(el).attr('data-model');
	    		var toggle = $(el).hasClass('is-active');
	    		console.log('toggle', toggle);
	    		console.log('main_layer_id', main_layer_id);
	    		
	    		console.log('toggle', toggle);
	    		console.log('layer_id', layer_id);
	    		console.log('model', model);

	    		

		    	if ( model == 'maplayer' ) {
		    		if ( toggle ) {
		    			TweenMax.to( layer_groups[ layer_id].getLayers()[0]._image, 0.7, {
		    				opacity: 1
		    			} );
		    		}
		    		else {
		    			TweenMax.to( layer_groups[ layer_id].getLayers()[0]._image, 0.7, {
		    				opacity: 0
		    			} );
		    		}
		    	}
		    	else {
		    		var els = $.map( layer_groups[ layer_id].getLayers(), function(el, i){
		    			// return el;
		    			return el._icon.firstChild;
		    		} );
		    		if ( toggle ) {
			    		$(els).addClass('is-active');
		    			TweenMax.staggerFromTo( els, 0.8, {
		    				opacity: 0,
		    				y: -30
		    			}, {
		    				opacity: 1,
		    				y: 0,
		    				ease: Power2.easeOut
		    			}, 0.05 );
		    		}
		    		else {
		    			$(els).removeClass('is-active');
		    			TweenMax.staggerFromTo( els, 0.6, {
		    				opacity: 1,
		    				y: 0
		    			}, {
		    				opacity: 0,
		    				y: -30,
		    				ease: Power2.easeOut
		    			}, 0.05 );
		    		}
		    	}
	    	});




	    };

	    var zoomIn = function(){
	    	map.zoomIn();
	    };

	    var zoomOut = function(){
	    	map.zoomOut();
	    };

	    $(container).find('[data-layer-id]').on('click', toggleLayerMultiLayerVisibleEvent);
	    	// .eq(0).addClass('is-active');
	    $(container).find('[data-action="zoom-in"]').on('click', zoomIn);
	    $(container).find('[data-action="zoom-out"]').on('click', zoomOut);

	    // 1pulsion, set last menu item active (first in apparance cause list is reversed


	    // var prev_states = getPrevStates();
	    // console.log('prev_states', prev_states);
	    // $('.envi__menu__item').last().addClass('is-active');
	    // var p = $(container).parents('.pulsion__bloc');
	    setTimeout(function() {
		    // toggleLayerOneLayerVisibleOnly( $(p).find('.envi__menu__item').eq(0) );   
		    // toggleLayerMultiLayerVisible( $(container).find('[data-layer-id]').eq(0) );
	    }, 1000);


	    // L.marker(L.latLng([ marker_data.y, marker_data.x ]), {
	  //   L.layerGroup([
	  //   L.marker(L.latLng([ 647, 1213 ]), {
			// 	// icon: getMarker(data, marker_data),
			// 	icon: getMarker()
			// 	// clickable: true
			// })
			// .bindPopup({
			// 	autoPan: false,
			// 	maxWidth: 400,
			// 	popupBottom: false
			// })
			// .setInfos({
			// 	html: '<div class="ll__popup__content">test</div>'
			// })
	    // ])
			// .addTo(map);
	};
	// =======================
	$.fn['leafletfilters'] = function(option, args) {
		return this.each(function() {
			var name = 'plugin_' + 'leafletfilters';
			var data = $.data(this, name);
			var opts = typeof option === "object" && option;
			if (!data) {
				$.data(this, name, (data = Plugin(this, opts)));
			}
			if (typeof option === "string") {
				data[option](args);
			}
		});
	};

	$(window).on('site:initPage', function(evt, el){
		$(el).find('[data-js="map"]').leafletfilters();
	});

}));

