// https://github.com/bradvin/social-share-urls
var $ = require('jquery');
var popup = require('mypop');
var copyToClipboard = require('copy-to-clipboard');


var rs_urls = {
	'fb' : 'https://www.facebook.com/sharer/sharer.php?u=%URL%', // https://developers.facebook.com/docs/sharing/web
	'tw' : 'https://twitter.com/intent/tweet?text=%TITLE%&url=%URL%',
	'li' : 'https://www.linkedin.com/shareArticle?mini=true&url=%URL%&title=%TITLE%', // &summary=%TEXT%
	'litxt' : 'https://www.linkedin.com/shareArticle?mini=true&url=%URL%&title=%TITLE%&summary=%TXT%',
	'wa' : 'https://api.whatsapp.com/send?text=%URL%',
	'watxt' : 'https://api.whatsapp.com/send?text=%TXT%',
	'we' : 'http://service.weibo.com/share/share.php?url=%URL%&appkey=&title=%TITLE%&pic=%PIC%&ralateUid=',
	'mailurl' : 'mailto:?subject=%TITLE%&body=%URL%',
	'mailtxt' : 'mailto:?subject=%TITLE%&body=%TXT%',
	'msgr' : 'fb-messenger://share?link=%URL%&app_id=%APPID%',
	'msgrdk' : 'https://www.facebook.com/dialog/send?app_id=%APPID%&link=%URL%&redirect_uri=%URL%',
	'copypasta' : ''
};

$( document ).on('click', '[data-share]', function(evt) {
	var rs = $(this).attr('data-share');
	if ( rs == 'copypasta' ){
		return copyToClipboard(window.location.href);
	}
	var url = $(this).attr('data-share-url') || window.location.toString();
	var post_url = $(this).attr('data-share-post-url') || '';

	var title = $(this).attr('data-share-title') || $(document).find("title").text();
	

	var popup_w = parseInt( $(this).attr('data-share-popup-w'), 10 ) || 580;
	var popup_h = parseInt( $(this).attr('data-share-popup-h'), 10 ) || 470;
	// weibo img
	var img = '';
	var img_att = $(this).attr('data-share-pic') || '';
	if ( img_att.length ) {
		var root = url.split('/');
		root = root[0] + '//' + root[2] + '/';
		img = root + $(this).attr('data-share-pic') || '';
	}

	var txt = $(this).attr('data-share-txt') || '';
	txt = txt.replace( '%URL%', url );
	title = title.replace( '%URL%', url );

	var app_id = $(this).attr('data-share-appid') || '';
	// var url = window.location.toString();
	// var url = encodeURI(window.location.toString());
	// var title = encodeURI($(document).find("title").text());
	// var titleb = $(document).find("title").text();
	var href = rs_urls[rs];

	href = href.replace( '%URL%', encodeURIComponent(url + '' + post_url) );
	href = href.replace( '%URL%', encodeURIComponent(url + '' + post_url) );
	href = href.replace( '%TITLE%', encodeURIComponent(title) );
	href = href.replace( '%PIC%', encodeURIComponent(img) );
	href = href.replace( '%TXT%', encodeURIComponent(txt) );
	href = href.replace( '%APPID%', encodeURIComponent(app_id) );
	console.log('href', href);
	if ( rs == 'mailtxt' ) {
		window.open(href, '_self');
	}
	else {
		popup( href, title, popup_w, popup_h );
	}
});

