var $ = require('jquery');
var reveals = require('./0reveals');

var shuffle = require('shuffle');

reveals('brands-header', function(el, tl){
    var lines = new SplitText($(el).find('.brands__header__txt'), {type:'lines'}).lines;
    // lines = lines.lines;
    lines.unshift( $(el).find('.brands__header__titre').get(0) );
    lines.push( $(el).find('.brands__header__accesrapide').get(0) );
    lines = $(lines).wrapp('ovh', true).parents;
    lines = $(lines).wrapp('test', true).parents;
    console.log('lines', lines);
    tl.staggerFromTo( lines, 0.8, {
        y: '100%',
        opacity: 1
    }, {
        y: '0%',
        opacity: 1,
        ease: Circ.easeOut
    }, 0.1 );

    tl.staggerFromTo( shuffle( $(el).find('.brands__logo__menu__logo').get() ), 0.5, {
        scale: 0.5,
        opacity: 0
    }, {
        scale: 1.001,
        opacity: 1,
        ease: Back.easeOut
    }, 0.075, 0.3 )


    // var line = $(el).find('.about__assocs__titre__line');
    // var bloc_left = $(el).find('.about__assocs__titre');
    // var bloc_right = $(el).find('.about__assocs__sstitre');
    // tl.fromTo( line, 1, {
    //     scaleY: 0,
    //     transformOrigin: '50% 0%'
    // }, {
    //     scaleY: 1,
    //     ease: Power3.easeInOut
    // });
    // tl.addLabel('end', '-=0.6');
    // tl.fromTo( bloc_left, 1.5, {
    //     opacity: 0,
    //     x: 20
    // }, {
    //     opacity: 1,
    //     x: 0,
    //     ease: Power2.easeOut
    // }, 'end');
    // tl.fromTo( bloc_right, 1.5, {
    //     opacity: 0,
    //     x: -20
    // }, {
    //     opacity: 1,
    //     x: 0,
    //     ease: Power2.easeOut
    // }, 'end+=0.15');
    return tl;
});
