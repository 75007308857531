var $ = require('jquery');

// $.fn.maskk = function(output_parent) {
// 	var out = [];
// 	var outp = [];
// 	$(this).each(function(i, el){
// 		var t = $('<div class="jq__mask" />').insertBefore(el);
// 		t.append(el);
// 		if ( output_parent === true ) {
// 			outp.push( t );
// 		}
// 		out.push( el );
// 	});

// 	if ( output_parent === true ) {
// 		return {
// 			'childs' : $(out),
// 			'parents' : $(outp)
// 		};
// 	}

// 	return $(out);
// };

/***

BASE : 
<div class="element">
	<div class="child"></div>
	<div class="child"></div>
</div>

V1 (insert outer) : 
<div class="NEWELEMENT">
	<div class="element">
		<div class="child"></div>
		<div class="child"></div>
	</div>
	<div class="MASK"></div>
</div>

V2 (insert inner) : 
<div class="element">
	<div class="NEWELEMENT">
		<div class="child"></div>
		<div class="child"></div>
	</div>
	<div class="MASK"></div>
</div>

opts = {
	cn: 'wrap',
	pos: 'outer',
	mask: 'mask',
}

***/
// $.fn.wrapmask = function(wrap_class, only_childs) {
$.fn.wrapmask = function(opts) {
	opts = $.extend({
		cn: 'wrap', // classname
		pos: 'outer', // position
		mask: '' // mask (optionnal)
	}, opts);
	var out = [];
	var outp = [];
	var outm = [];
	$(this).each(function(i, el){
		var t = $('<div class="'+opts.cn+'" />');
		// if ( only_childs === true ) {
		if ( opts.pos == 'inner' ) {
			var content = $(el).html();
			$(el).html('');
			t = t.appendTo(el);
			t.html( content );
			if ( opts.mask !== '' ) {
				outm.push( $('<div class="'+opts.mask+'" />').appendTo( el ).get(0) );
			}
		}
		else {
			t = t.insertBefore(el);
			t.append(el);
			if ( opts.mask !== '' ) {
				outm.push( $('<div class="'+opts.mask+'" />').appendTo( t ).get(0) );
			}
		}
		outp.push( t.get(0) );
		out.push( el );
	});

	console.log('outp', outp);

	return {
		'childs' : $(out),
		'parents' : $(outp),
		'masks' : $(outm)
	};

};

// for each element create mask element and rectangle element from bounding box of element with outline pushed with offset value
// create a g element, assign the mask to it and append the orignal element inside the create g element
var debug = false;
var svgns = "http://www.w3.org/2000/svg";
$.fn.wrapmasksvg = function(opts) {
	opts = $.extend({
		offset: 1, // positive = outer, negative = inner
		maskfill: '#ffffff', // mask color
		debug: false
	}, opts);
	var ret = {
		els: [],
		masks: [],
		masksrects: [],
		wraps: [],
	};
	$(this).each(function(i, g){

		var svg = $(g).closest('svg').get(0);
		var svgbb = svg.getBoundingClientRect();
		if ( debug || opts.debug ) {
			console.log('svgbb', svgbb);
		}

		var viewbox = svg.getAttribute('viewBox').split(' ');

		var svg_w = parseFloat( viewbox[2] ) - parseFloat( viewbox[0] );
		var svg_h = parseFloat( viewbox[3] ) - parseFloat( viewbox[1] );
		var fact_w = svg_w / svgbb.width;
		var fact_h = svg_h / svgbb.height;

		if ( debug || opts.debug ) {
			console.log('svg_w', svg_w, 'svg_h', svg_h);
		}

		var bb = g.getBoundingClientRect();
		if ( debug || opts.debug ) {
			console.log('bb', bb);
		}

		// make a simple rectangle
		var newMask = document.createElementNS(svgns, 'mask');
		var newRect = document.createElementNS(svgns, 'rect');

		var rand = Math.round( Math.random() * 999999999 );
		var id = 'mask' + rand;

		// set attributes of new rectangle
		gsap.set(newMask, {
			attr: { id: id }
		});
		gsap.set(newRect, {
			attr: { 
				x: ( ( bb.x - svgbb.x ) * fact_w ) - opts.offset, 
				y: ( ( bb.y - svgbb.y ) * fact_h ) - opts.offset, 
				width: ( bb.width * fact_w ) + ( opts.offset * 2 ), 
				height: ( bb.height * fact_h ) + ( opts.offset * 2 ), 
				fill: opts.maskfill
			}
		});

		var gp = document.createElementNS(svgns, 'g');
		gsap.set(gp, {
			attr: { mask: 'url(#'+id+')' }
		});

		newMask.appendChild(newRect);
		svg.appendChild(newMask);

		gp.appendChild(g);
		svg.appendChild(gp);

		ret.els.push( g );
		ret.masks.push( newMask );
		ret.masksrects.push( newRect );
		ret.wraps.push( gp );
	});

	return {
		els: $(ret.els),
		masks: $(ret.masks),
		masksrects: $(ret.masksrects),
		wraps: $(ret.wraps)
	};

};