var $ = require('jquery');
require('./mask.js');
var reveals = require('./0reveals');
var closeCtaAnim = require('./closectaanim.js');
var bulleAnim = require('./bulleanim.js');
var pictoAnim = require('./pictoanim.js');
var arrowAnim = require('./arrowanim.js');

reveals('citypage3anim', function(el, tl, start_label){
	// tl.to( el, 0.5, {
	// 	opacity: 1
	// }, start_label );

	tl.staggerFromTo( $(el).find('.we__title__bloc--city3 > div, .city__share__wrap'), 1, {
		opacity: 0,
		y: 30
	}, {
		opacity: 1,
		y: 0,
		ease: Power2.easeOut
	}, 0.07, start_label+'+=0.4');






	var ttl = new TimelineMax({paused:true});

	// ttl.fromTo( $(el).find('.city__slide__line'), 0.6, {
	// 	scaleY: 0,
	// 	transformOrigin: '50% 0%'
	// }, {
	// 	scaleY: 1,
	// 	ease: Power0.easeOut
	// } )

	// ttl.fromTo( $(el).find('.city__slide__line'), 1.2, {
	// 	y: '-100%',
	// 	transformOrigin: '50% 0%'
	// }, {
	// 	y: '100%',
	// 	ease: Power0.easeInOut
	// } )

	// ttl.addLabel( 'partb' )

	// ttl.to( $(el).find('.city__slide__line').eq(0), 1, {
	// 	x: '-0%',
	// 	ease: Power1.easeOut
	// }, 0.6 )

	// ttl.to( $(el).find('.city__slide__line'), 1, {
	// 	opacity: 0,
	// 	ease: Power1.easeIn
	// }, 0.6 )

	ttl.fromTo( $(el).find('.city__slide__mask').eq(0), 1, {
		x: '0%',
		ease: Power0.easeOut
	}, {
		x: '-99%',
		ease: Power0.easeOut
	}, 'partb' )

	// ttl.to( $(el).find('.city__slide__line').eq(1), 1, {
	// 	x: '100%',
	// 	ease: Power1.easeOut
	// }, 0.6 )

	ttl.fromTo( $(el).find('.city__slide__mask').eq(1), 1, {
		x: '0%',
		ease: Power0.easeOut
	}, {
		x: '99%',
		ease: Power0.easeOut
	}, 'partb' )

	ttl.addLabel( 'partc' )

	ttl.fromTo( $(el).find('.city__slide__mask'), 1, {
		scaleY: 1,
		ease: Power0.easeOut
	}, {
		scaleY: 0,
		transformOrigin: '50% 50%',
		ease: Power0.easeOut
	}, 'partc' )

	ttl.addLabel( 'partd', '-=0.5' )

	ttl.fromTo( arrowAnim( $(el).find('.city__slide__nav--left') ), 1, {
		progress:0
	}, {
		progress:1,
		ease: Power0.easeInOut
	}, 'partd' )

	ttl.fromTo( arrowAnim( $(el).find('.city__slide__nav--right') ), 1, {
		progress:0
	}, {
		progress:1,
		ease: Power0.easeInOut
	}, 'partd' )

	tl.fromTo( ttl, 1.5, {
		progress:0
	}, {
		progress:1,
		ease: Power1.easeInOut
	}, start_label+'+=0.8');

});
