require('gsap');
// require('gsap18/utils/SplitText.js');
// require('gsap18/plugins/DrawSVGPlugin');
// require('gsap18/plugins/MorphSVGPlugin');

var menuToggler = require('menu-toggler');
// require('menu-floating');
// require('lang-toggler');
// require('search-toggler');
// require('news-slide');
// require('home-slide');
// require('domaine-slide');
// require('presse-toggle');
// require('vins-hover');
require('cyma-video');
require('jquery-easing');
require('scrollto');
require('wpcf');
require('share');
require('init');
// require('age-gate');
require('reveal-events');
// require('nda-chrono');
// require('nda-points');
require('news-loader');
// require('docs-preview'); 
require('people-popup'); 
require('slides.js');
require('popupoutlinks.js');
// require('side-menu');
require('home-incident-popup');
require('home-video');
// require('drlocator'); 
require('sticky-kit');
require('embedvideoframe');


require('es6-promise/auto');
require('./sksmooth-anims.js');
require('./sksmooth-funcs.js');

require('cymasmooth/config-defaut');
var NProgress = require('cymasmooth/ajax-load-progress');
// require('./anims/mask.js');

var cymasmooth = require('cymasmooth');
cymasmooth.registerFuncMulti( 'onRenderPage', require('cymasmooth/update-wp-lang-menu') );
cymasmooth.registerFunc( 'onShow', require('cymasmooth/onshow-loading-data-attr') );

require('./leaflet-filters.js');

var imagesLoaded = require('imagesloadedwithsize');
cymasmooth.registerFunc( 'onHalf', function(callback){
	var testh = $('.home__intro__visus__ph').height();
	console.log('testh', testh);

	var src = [];
	$('[data-src]').each(function(i, el){
		src.push( $(el).attr('data-src') );
	})
	$('[data-bg-src]').each(function(i, el){
		src.push( $(el).attr('data-bg-src') );
	})
	console.log('srcsrc', src);
	imagesLoaded( src, function() {
		// alert('load done')
		$('[data-src], [data-bg-src]').each(function(i, el){
			// console.log('el', el);
			if ( $(el).attr('data-bg-src')  ) {
				$(el).css('background-image', 'url('+$(el).attr('data-bg-src')+')');
			}
			else {
				// console.log($(el).attr('data-src'));
				$(el).attr('src', $(el).attr('data-src'));
			}
		});
		var testh = $('.home__intro__visus__ph').height();
		console.log('testh', testh);
		console.log('done loading imgs');
		setTimeout(callback, 50);
	});
} );
var site = require('site');
var paralax = require('paralax');
var reveal = require('reveal');
require('fastclick');
var scrollmenu = require('scrollmenu');
// var scrollmenu = require('kojy-explo-slide');
// require('./cursor-follow.js');

var MQ = require('mq');

// require('./compo/mask.js');
require('wrapmask');

require('vh2');
// require('./homeslide.js');

// require('./codic-map.js');
// require('./testdb.js');

require('fancybox');
// require('wheel3');

var $ = require('jquery');

var emresizer = require('emresizer');
// var siteloader = require('./site-loader.js');
var siteloader = require('./site-loaderweimmo.js');

require('form-select2-wp');

var resize_done = 0;
var windowResizeHandler = function(){
	// if ( MQ(-767) && resize_done > 0 ) {
	if ( MQ(-767) ) {
		return;
	}
	// phplog('emresize');
	// console.log('emresize');
	emresizer.resizeb( $('[data-js-emresize]'), true );
	// console.log('homeintrowidth', $('.home__intro__content').width());
	// console.log('homeintrowidth', $('.home__intro__content').height());
	// emresizer.resizeb( $('[data-js-emresize="intro-head"]'), false, $('.site__intro__content').width(), $('.site__intro__content').height() );
	// emresizer.resizeb( $('[data-js-emresize="home-head"]'), false, $('.home__intro__content').width(), $('.home__intro__content').height() );
	// emresizer.resizeb( $('[data-js-emresize="menu-inner"]'), true, Math.min(2846, $(window).width()), $(window).height() );
	resize_done++;
};
$(window).on("cube:resizewoall", windowResizeHandler);
$(window).on("resize", windowResizeHandler);
$(window).on("site:initPage", windowResizeHandler);
$(window).on("vh:done", windowResizeHandler);
windowResizeHandler();



$(window).trigger('resize');
siteloader(function(){	
	// $(window).on('load', function(){
	// alert('start')
	// cymasmooth.initCurrentPageSimple();
	// site.initPage( $('html') );
	// NProgress();
	// $(window).trigger('cymasmooth:onAjaxLoad');
	cymasmooth.initCurrentPage();

	// Contact Form 7 callbacks
    document.addEventListener('wpcf7mailsent', function (e) {
		// $.fancybox.close();
		// $.fancybox.open('<div class="h3 popin is-alert">' + e.detail.apiResponse.message + '</div>');
		if ( $('.pagehead__form__content__success').length ){
			// return;
			var tl = gsap.timeline({paused : true});
			tl.to( $('.pagehead__form__content__form'), {
				opacity: 0,
				ease: 'none',
				duration: 0.6
			} );
			tl.add( function(){
				$('.pagehead__form__content__success').slideDown();
				$('.pagehead__form__content__form').slideUp();
				$('.pagehead__form--joinus').addClass('is-success');
			}, 0.45 );
			tl.from( $('.pagehead__form__content__success__inner > div'), {
				// scale: 0.85,
				opacity: 0,
				duration: 1.2,
				stagger: 0.13
			}, 0.7 );
			tl.play();
		}
		else if ( $('.home__devis__formulaire').length ){
			console.log('form val');
			console.log($('.home__devis__formulaire select').val());
			sessionStorage.setItem('heuresparsemaine', $('.home__devis__formulaire select').val());
			sessionStorage.setItem('villeoucodepostal', $('#ville').val());
			cymasmooth.goTo( $('.home__devis__formulaire').attr('data-goto-href') );
		}
    }, false);
});

$( document ).on('click', '.form__submit ', function(evt) {
	var el = $(evt.currentTarget);
	evt && evt.preventDefault();
	el.parents('form').eq(0).submit();
});

$(window).on('site:initPage', function(evt, el){
	if ( sessionStorage.getItem('heuresparsemaine') ){
		var form_heuresparsemaine_val = sessionStorage.getItem('heuresparsemaine');
		console.log('form_heuresparsemaine_val', form_heuresparsemaine_val);
		$('.heuressemaine select').val(form_heuresparsemaine_val);
		sessionStorage.removeItem('heuresparsemaine');
	}
	if ( sessionStorage.getItem('villeoucodepostal') ){
		var form_villeoucodepostal_val = sessionStorage.getItem('villeoucodepostal');
		console.log('form_villeoucodepostal_val', form_villeoucodepostal_val);
		$('#ville').val(form_villeoucodepostal_val);
		sessionStorage.removeItem('villeoucodepostal');
	}
});

// $( document ).on('click', '[data-js="login-cta"]', function(evt) {
// 	// var el = $(evt.currentTarget);
// 	// evt && evt.preventDefault();
// 	$('[data-js="login-bloc-wrap"]').toggleClass('is-active');
// 	if ( MQ(-767) ) {
// 		$('[data-js="login-bloc-items"]').slideToggle();
// 	}
// });

$( '[data-js="login-bloc-wrap"]' ).hover(function(evt) {
	// var el = $(evt.currentTarget);
	// evt && evt.preventDefault();
	$('[data-js="login-bloc-wrap"]').toggleClass('is-active');
	if ( MQ(-767) ) {
		$('[data-js="login-bloc-items"]').slideToggle();
	}
}, function(evt) {
	// var el = $(evt.currentTarget);
	// evt && evt.preventDefault();
	$('[data-js="login-bloc-wrap"]').toggleClass('is-active');
	if ( MQ(-767) ) {
		$('[data-js="login-bloc-items"]').slideToggle();
	}
});

$('.services__menu__content').clone().appendTo( $('.menu__item__service__placeholder') );

$( document ).on('click', '[data-js-toggle-modal="nosservicesmodal"]', function(evt) {
	var el = $(evt.currentTarget);
	evt && evt.preventDefault();
	$('[data-id="nosservicesmodal"]').toggleClass('is-active');
});

$('.header .menu__item__li--nos_services').hover(function() {
	$('.menu__item__service__placeholder').toggleClass('is-active');
}, function() {
	$('.menu__item__service__placeholder').toggleClass('is-active');
});

$(window).on('cymasmooth:onAjaxLoad', function(evt, el, url){
	$('[data-id="nosservicesmodal"]').removeClass('is-active');
});

$(window).on('is-main-menu-toggled', function(evt, is_open){
	$('[data-id="nosservicesmodal"]').removeClass('is-active');
});

$( document ).on('click', '.home__pourquoi__cta', function(evt) {
	var el = $(evt.currentTarget);
	evt && evt.preventDefault();
	el.parents('.home__pourquoi__blocs__item__wrap').toggleClass('is-active');
});

$( document ).on('click', '[data-js-slide-toggle="faq"]', function(evt) {
	var el = $(evt.currentTarget);
	evt && evt.preventDefault();
	var current_item = el.parents('.faq__blocs__questions__item');
	var current_rep = el.parents('.faq__blocs__questions__item').find('.faq__blocs__questions__reponse');
	$('.faq__blocs__questions__reponse').not(current_rep).slideUp();
	$('.faq__blocs__questions__item').not(current_item).removeClass('is-active');
	current_rep.slideToggle();
	current_item.toggleClass('is-active');
});

$( document ).on('click', '[data-goto-anchor]', function(evt) {
	var el = $(evt.currentTarget);
	var anchor = $(el).attr('data-goto-anchor');
	if ( $('#' + anchor).size() ) {
		menuToggler();
		console.log('aaaa',  $('#'+anchor).offset().top )
		$('html, body').animate({scrollTop:$('#' + anchor).offset().top}, 500, 'easeInOutQuart');
	}
	else {
		cymasmooth.gotopagewithanchor = anchor;
		cymasmooth.goTo( $(el).attr('data-goto-href') );
	}
});

$( document ).on('change', '.newss__head__cats__items__select', function(evt) {
	var el = $(evt.currentTarget);
	var val = $(el).val();
	cymasmooth.goTo( val );
});

$( document ).on('click', '.partenaires__blocs__more', function(evt) {
	var el = $(evt.currentTarget).closest('.partenaires__blocs__partenaire');
	$(el).toggleClass('is-active');
	
});

$( document ).on('click', '[data-js-acc-title]', function(evt) {
	var el = $(evt.currentTarget);
	if ( $(el).hasClass('is-active') == false ){
		var id = $(el).attr('data-js-acc-title');
		updateAcc(id);
	}
	
});

var updateAcc = function(id){
	$('.about__qualite__blocs__txt__item.only__pho .about__qualite__blocs__qualite__texte').slideUp();
	$('.about__qualite__blocs__txt__item.only__pho .about__qualite__blocs__qualite__texte').filter('[data-js-acc-txt="'+id+'"]').slideDown();
	$('.about__qualite__blocs__txt__items.not__pho .about__qualite__blocs__txt__item').removeClass('is-active');
	$('.about__qualite__blocs__txt__items.not__pho .about__qualite__blocs__txt__item').filter('[data-js-acc-txt="'+id+'"]').addClass('is-active');
	$('[data-js-acc-title]').removeClass('is-active').filter('[data-js-acc-title="'+id+'"]').addClass('is-active');
};

$(window).on('site:initPage', function(evt, el){
	$(el).find('.callmecheckbox input').each(function(i, el){
		$(el).on('change', function(){
			$('.wpcf7-form-control-wrap.horaires').toggleClass('is-active', $('.callmecheckbox input').prop('checked'));
		});
	})
});

$(window).on('site:initPage', function(evt, el){
	$(el).find('.about__qualite__blocs').each(function(i, el){
		var id = $(el).find('[data-js-acc-title]').eq(0).attr('data-js-acc-title');
		updateAcc( id );
	})
});

$.fancybox.defaults.hash = false;
$.fancybox.defaults.hideScrollbar = false;
// disable poster cause fancybox use the image inside the link and its not what we wants
$.fancybox.defaults.video.tpl = '<video class="fancybox-video" controls controlsList="nodownload" postero="{{poster}}">' +
  '<source src="{{src}}" type="{{format}}" />' +
  'Sorry, your browser doesn\'t support embedded videos, <a href="{{src}}">download</a> and watch with your favorite video player!' +
  "</video>";
// $.fancybox.defaults.arrows = false;
$.fancybox.defaults.buttons = [
	"zoom",
	//"share",
	// "slideShow",
	//"fullScreen",
	//"download",
	// "thumbs",
	"close"
];

$( document ).on('click', '[data-scrollto-with-hack]', function(evt) {
	var target_query = $(evt.currentTarget).attr('data-scrollto-with-hack');
	var y = parseFloat($(target_query).attr('data-decal-full'));
	console.log('scrollto y', y);
	$('html, body').animate({scrollTop:y + 10}, 1000, 'easeInOutQuart');
	// $('html, body').scrollTop(y);
});

$('[data-vector]').each(function(i, el){
	var val = $(el).attr('data-vector').split(',');
	gsap.set( el, {x:(parseFloat(val[0]) * 100) + 'rem',y:(parseFloat(val[1]) * -100) + 'rem'} );
});

var first_pass_app = true;
$(window).on('site:initPage', function(){
	$('body').removeClass('is-first-page');

	// var wrapmask = $('.reveal-faderightsml').wrapmask({cn:'ovh rel',mask:'',pos:'inner'});
	var delay = 0;
	// alert(first_pass_app)
	if ( first_pass_app ){
		delay = 500;
	}
	// alert(delay)
	setTimeout(function() {
		reveal( true );
		paralax();
	}, delay);

	first_pass_app = false;
	// $('.gsap-paralax').each(function(i, el){
	$('.gsap-paralax').each(function(i, el){
		var h = $(el).height();
		console.log('h', h);
		var ratio = 0.25;
		if ( $(el).attr('data-paralax') ){
			ratio = parseFloat($(el).attr('data-paralax'))
		}
		h = h * ratio;
		gsap.set( el, {y:h} );

		gsap.to(el, {
			y: -h,
			ease: "none",
			scrollTrigger: {
				trigger: el,
				marker: 1,
				scrub: 1
			}
		});

		var scale = 0.1;
		var img_scale = 1 + ( scale * 2 );
		var y_pc = ( scale / img_scale ) * -100;
		if ( $(el).hasClass('gsap-paralax-inner') ) {
			var img = $(el).find('img');
			gsap.set(img, {scale:img_scale, yPercent:y_pc});
			gsap.to(img, {
				yPercent: -y_pc,
				ease: "none",
				scrollTrigger: {
					trigger: el,
					marker: 1,
					scrub: 1
				}
			});
		}

	});

	setTimeout(function() {
		ScrollTrigger.refresh(true);
	}, 150); 
});

$(window).on('resize', function(){
	// alert('scroll trigger refresh')
	// ScrollTrigger.refresh();
})

$('.header__menu__span').on('click', function(){
	$('body').removeClass('is-main-menu-open')
});

var onRenderPageEvent = function(url, record_visit, undefined){
	// scrollmenu.setMenuItems($('.home__scroll__menu__item').get());

	var html = $(this.next_page.html);
	var index = html.find('.header .menu__item__li').filter('.is-current').eq(0).index();
	console.log('index', index);
	setTimeout(function() {
		$('.header  .menu__item__li').removeClass('is-current')
		console.log('index2', index);
		if ( index >= 0 ) {
			$('.header  .menu__item__li').eq(index).addClass('is-current');
		}
	}, 300);

	$('.header__contact__link').removeClass('is-current');
	var founded = html.find('.header__contact__link.is-current').size();
	// alert(founded);
	setTimeout(function() {
		if ( founded > 0 ) {
			$('.header__contact__link').addClass('is-current');
		}
	}, 300);
	

	var langs_a = $('a.lang__cta');

	// html.find('.header__langs a').each(function(i, el){
	html.find('a.lang__cta').each(function(i, el){
		langs_a.eq(i).attr('href', $(el).attr('href'))
	})
	
};

cymasmooth.registerFuncMulti( 'onRenderPage', onRenderPageEvent );

$(window).on('cymasmooth:statechanging', function(){
	console.log('$(window).scrollTop()', $(window).scrollTop());
	var h = $('.page__wrap').outerHeight();
	console.log('h', h);
	var scroll_top = $(window).scrollTop();
	$('<div class="pw__dummy" />').css({height:h,opacity:0}).insertBefore( $('.page__wrap') );
	$('.page__wrap').css({
		position: 'fixed',
		left: 0,
		right: 0,
		height: h,
		top: -scroll_top
	});
	// debugger;
});

$(window).on('site:initPage', function(evt, el){
	if ( MQ(768) ){
		$(el).find('[data-js="sticky"]').stick_in_parent({
			offset_top: $('.header').height(),
			parent: $(el).find('[data-js="sticky-parent"]')
		});
	}
});

$(window).trigger('reveal:changelimit', 0.95);

$( document ).on('click', '[data-quizz-cta]', function(evt) {
	var el = $(evt.currentTarget);
	el.closest('.cf__quiz__page').addClass('is-done');
	setTimeout(function() {
		$('.cf__quiz').scrollTop(0);
		el.closest('.cf__quiz__page')
			.next().addClass('is-active')
	}, 400);
	el.closest('.cf__quiz__page')
		.find('.cf__quiz__rep').removeClass('is-selected')
	if ( $(el).attr('data-val') ){
		$(el).addClass('is-selected');
	}
	updateResultPage();
});

$( document ).on('click', '[data-quiz-back]', function(evt) {
	var el = $(evt.currentTarget);
	el.closest('.cf__quiz__page').removeClass('is-active')
	setTimeout(function() {
		$('.cf__quiz').scrollTop(0);
		el.closest('.cf__quiz__page')
			.prev().removeClass('is-done').addClass('is-active');
	}, 400);
	updateResultPage();
});

var updateResultPage = function(){
	var score = 0;
	$('.cf__quiz__rep.is-selected').each(function(i, el){
		score += $(el).data('val');
	});
	console.log('score', score);
	$('.result__item').each(function(i, el){
		var min = $(el).data('res-min');
		var max = $(el).data('res-max');
		$(el).toggleClass('is-active', score >= min && score <= max );
	});
};

$( document ).on('click', '[data-js="play-video"]', function(evt) {
	$('video').get(0).play();
	$('video').attr('controls', 'controls');
	// var el = $(evt.currentTarget);
	$('.film__lecture').addClass('is-inactive');
});

$( document ).on('click', '[data-slide-toggle]', function(evt) {
	var el = $(evt.currentTarget);
	var q = el.attr('data-slide-toggle');
	$(q).slideToggle();
	el.toggleClass('is-active');
});


$(window).on('site:initPage', function(evt, el){
	// alert('x')
	if ( MQ(-767) ){
		return;
	}
	$(el).find('.home__slideshow__section, .immeuble__header__section').each(function(i, el){
		var stbb = ScrollTrigger.create({
			// animation: tlb,
			trigger: el,
			start: "top top",
			end: function(){
				return '+='+$(window).height() * 1
			},
			// markers: 1,
			pin: true,
			pinSpacing: false,
			// pinSpacing: true,
			// once: true,
			scrub: 1

		});
	});
});


