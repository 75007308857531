var $ = require('jquery');
require('./mask.js');
var reveals = require('./0reveals');
// var closeCtaAnim = require('./closectaanim.js');
// var arrowAnim = require('./arrowanim.js');

var arrowAnim = require('./arrowanim.js');
var pictoAnim = require('./pictoanim.js');
var bulleAnim = require('./bulleanim.js');
var titleAnim = require('./titleanim.js');

reveals('axomodelanim', function(el, tl, start_label){
	tl.to( el, 0.5, {
		opacity: 1
	}, start_label );

	tl.fromTo( titleAnim( $(el).find('.page__head') ), 1.2, {
		progress: 0
	}, {
		progress: 1,
		ease: Power0.easeOut
	}, start_label+'+=0.4');

	tl.fromTo( bulleAnim( $(el).find('.we__txt__bloc') ), 1.2, {
		progress: 0
	}, {
		progress: 1,
		ease: Power0.easeOut
	}, start_label+'+=1.4');

	tl.fromTo( pictoAnim( $(el).find('.axo__picto') ), 1.2, {
		progress: 0
	}, {
		progress: 1,
		ease: Power0.easeOut
	}, start_label+'+=1.6');

	tl.fromTo( arrowAnim( $(el).find('.axo__slide__nav--left') ), 1, {
		progress:0
	}, {
		progress:1,
		ease: Power0.easeInOut
	}, start_label+'+=2.6' )

	tl.fromTo( arrowAnim( $(el).find('.axo__slide__nav--right') ), 1, {
		progress:0
	}, {
		progress:1,
		ease: Power0.easeInOut
	}, start_label+'+=2.6' )



	tl.fromTo( $(el).find('.axo__slide__wrap'), 1, {
		opacity: 0
	}, {
		opacity: 1,
		ease: Power2.easeInOut
	}, start_label+'+=2' )

	tl.staggerFromTo( $(el).find('.axo__slide__item.is-active .axo__marker'), 0.7, {
		scale: 0,
		opacity: 0.5
	}, {
		scale: 1.05,
		opacity: 1,
		ease: Back.easeOut,
		clearProps: 'transform'
	}, 0.15, start_label+'+=2.6' )

});
