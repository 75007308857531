var copyToClipboard = function(txt){
	window.navigator.clipboard.writeText(txt);
};

var copyToClipboardPre2021 = function(txt){
	var dummy = document.createElement('input');
    dummy.style.position = 'absolute';
	document.body.appendChild(dummy);
	dummy.value = txt;
	dummy.select();
	document.execCommand('copy');
	document.body.removeChild(dummy);
};

var copyToClipboardOldFirefoxFix = function(txt){
	if (!window.getSelection) {
		alert('Please copy the URL from the location bar.');
		return;
	}
	var dummy = document.createElement('p');
	dummy.style.position = 'absolute';
	dummy.textContent = txt;
	document.body.appendChild(dummy);

	var range = document.createRange();
	range.setStartBefore(dummy);
	range.setEndAfter(dummy);

	var selection = window.getSelection();
	// First clear, in case the user already selected some other text
	selection.removeAllRanges();
	selection.addRange(range);

	document.execCommand('copy');
	document.body.removeChild(dummy);
};

module.exports = copyToClipboard;