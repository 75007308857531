(function(window, factory) { 'use strict';
	if (typeof exports !== 'undefined') {
		module.exports = factory(
			window,
			require('jquery')
		);
	} else {
		factory(window, jQuery);
	}

}(window, function(window, $) {

	var showCymaVideo = function(el){
		// var $this = $(this);
		var data = $(el).data();
		var src = $(el).data('src');
		// alert(data.src);
		setTimeout(function(){
			// $('<iframe src="'+data.src+'" width="'+data.w+'" height="'+data.h+'" frameborder="0" allowfullscreen="true" style="background-color:#000000;"/>').appendTo($this).load(function (){
			$('<iframe src="'+data.src+'" width="100%" height="100%" frameborder="0" allowfullscreen="true" style="background-color:#000000;"/>').appendTo($(el)).load(function (){
				    // do something once the iframe is loaded
				    // alert('iframe loaded')
				    // $(el).find('img').css('display','none');
				    // $(el).find('img').animate({'opacity':0}, 2000);
					$(el).find('div.vidprocontrolsoverlay').css('display','none');
				});
			// $(el).find('img').css('position','absolute');
		}, 400);
		$(el).find('img').css('opacity',0);
		$(el).find('div.vidprocontrolsoverlay').addClass('vidplayfadeout');
	};

	$( document ).on('click', '.jsvidpro', function(evt) {
		showCymaVideo(evt.currentTarget);
	});


	// init();


}));