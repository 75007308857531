var $ = require('jquery');
require('jquery-easing');

$( document ).on('click', '[data-scrollto]', function(evt) {
	var target_query = $(evt.currentTarget).attr('data-scrollto');
	var speed = 1000;
	if ( $(evt.currentTarget).attr('data-scrollto-speed') ){
		speed = parseInt( $(evt.currentTarget).attr('data-scrollto-speed'), 10 );
	}
	$('html, body').animate({scrollTop:$(target_query).offset().top}, speed, 'easeInOutQuart');
});



