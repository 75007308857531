
require('./homemodelanim.js');
require('./pagemodelanim.js');
require('./brochuremodelanim.js');
require('./axomodelanim.js');
require('./gallerymodelanim.js');
require('./filmmodelanim.js');
require('./biblimodelanim.js');
require('./biblipartagemodelanim.js');
require('./contactmodelanim.js');
require('./citymodelanim.js');
require('./citypage1anim.js');
require('./citypage2anim.js');
require('./citypage3anim.js');
require('./loginmodelanim.js');
require('./registermodelanim.js');
require('./pwdrecoverymodelanim.js');
require('./pwdchangemodelanim.js');
require('./menumodelanim.js');
require('./privatemodelanim.js');

var reveals = require('./0reveals.js');

module.exports = function(a, b, c, d, e, f){
    // console.log('get reveal anim', a, b);
    return reveals(a)(b, c, d, e, f);
};
