// shop 
// 
(function(window, factory) { 'use strict';
	// if (typeof exports !== 'undefined') {
		require('./scroll.js');
		require('resize');
		module.exports = factory(
			window,
			require('jquery')
		);
	// } else {
	// 	factory(window, jQuery);
	// }

}(window, function(window, $) {

var scrollItems,
	scrollItemsOffset,
	scrollMenuItems,
	scrollTop = -1,
	classname = 'is-selected',
	wh = 1000,
	bh = 3000,
	maxScroll = 2000;

var onScrollDelayed = function(evt, scroll_top) {
	// console.log('onScrollDelayed', scroll_top, arguments);
	scrollTop = scroll_top;
	updateMenuClasses();
};

var old = [3527, 7537, 8415, 10919];

var updateOffsets = function() {
	wh = $(window).height();
	bh = $('body').height();
	maxScroll = bh - wh;
	scrollTop = window.pageYOffset;
	scrollItemsOffset = [];
	var scroll_offset = 0;
	if ( $('#smooth-content').size() > 0 ) {
		scroll_offset = $('#smooth-content').offset().top;
		// console.log('scroll_offset', scroll_offset);
	}
	if ( scrollItems ) {
		var diff_offset = [];
		for(var i=0,j=scrollItems.length;i<j;i++){
			// scrollItemsOffset.push($(scrollItems[i]).offset().top);
			var offset = Math.floor($(scrollItems[i]).offset().top) - 10 - scroll_offset;
			// if hack scroll horizontal wrapper, and current scroll < XXXXX
			// then add hack scroll wrap height
			var scroll_hack_wrapper = $(scrollItems[i]).parents('[scroll-hack="1"]');
			var offset_for_click_on_menu = offset;
			if ( scroll_hack_wrapper.size() > 0 ) {
				console.log('has wrapper', i);
				// console.log('scroll_hack_wrapper', scroll_hack_wrapper.get(0));
				// console.log('last mvmt', scroll_hack_wrapper.attr('scroll-hack-last-mvmt'));
				// if ( scroll_hack_wrapper.css("position") === "fixed" || scrollTop < scroll_hack_wrapper.offset().top ) {
				var decal = parseFloat( scroll_hack_wrapper.attr('scroll-hack-end') );
				decal = decal - parseFloat( scroll_hack_wrapper.attr('scroll-hack-start') );
				if ( scroll_hack_wrapper.attr('scroll-hack-last-mvmt') == 'onEnter' || scroll_hack_wrapper.attr('scroll-hack-last-mvmt') == 'onLeaveBack' ) {
					// console.log('scrollTop', scrollTop);
					// console.log('decal', decal);
					// offset += decal;
				}
				if ( $(scrollItems[i]).attr('data-is-before-scroll-hack') != '1' ) {
					offset_for_click_on_menu += decal;
				}
				var progress = decal * parseFloat( scroll_hack_wrapper.attr('scroll-hack-progress') );
				console.log('progress', progress);
				offset_for_click_on_menu -= progress;
			}
			$(scrollItems[i]).attr('data-decal-full', offset_for_click_on_menu);
			// scrollItemsOffset.push(offset);
			scrollItemsOffset.push(offset_for_click_on_menu - 10);
			diff_offset[i] = scrollItemsOffset[i] - old[i];
		}
		console.log('scrollItemsOffset', wh, bh, scrollItemsOffset);
		console.log(diff_offset);
	}
};

if ( window.ResizeObserver ) {
	var resizeObserver = new ResizeObserver(function(entries){
		console.log('resizeObserver');
		updateOffsets();
		updateMenuClasses();
	});

	resizeObserver.observe($('body').get(0));
}


// $('[resize-observer="1"]').each(function(i, el){
// 	resizeObserver.observe(el);
// });

var resize = function() {
	updateOffsets();
	updateMenuClasses();
};

var update = function() {
	scrollTop = window.pageYOffset;
	updateMenuClasses();
};

var getCurrentVisibleMenuItemIndex = function() {
	var scroll_top_temp = Math.max(0, scrollTop);
	if ( scroll_top_temp === maxScroll ) {
		// console.log('---------------------------------------j last', 'scrollItemsOffset[j]', maxScroll, 'scroll_top_temp', scroll_top_temp);
		return scrollItemsOffset.length-1;
	}
	for(var i=0,j=scrollItemsOffset.length-1;i<=j;j--){
		if (scrollItemsOffset[j] <= scroll_top_temp) {
			// console.log('---------------------------------------j', j, 'scrollItemsOffset[j]', scrollItemsOffset, 'scroll_top_temp', scroll_top_temp);
			return j;
		}
	}
	// console.log('---------------------------------------j', '-1', scrollItemsOffset[0], scroll_top_temp); 
	return -1;
};

var last_index = -2;
var cb = false;
var updateMenuClasses = function() {
	var index = getCurrentVisibleMenuItemIndex();
	// console.log('index', index, classname, scrollMenuItems);
	var class_to_add = (index < 0) ? '' : classname;
	$(scrollMenuItems).removeClass(classname)
		.eq(index).addClass(class_to_add);
	if ( last_index != index && cb ) {
		last_index = index;
		cb( index, scrollMenuItems, scrollItems );
	}
};

var setEvents = function(){
	$(window).on('cube:resize', resize); 
	$(window).on('cube:scroll', onScrollDelayed);
	// $(window).on('cube:scrollmenu:update', update);
};

var removeEvents = function(){
	$(window).off('cube:resize', resize);
	$(window).off('cube:scroll', onScrollDelayed);
	// $(window).off('cube:scrollmenu:update', update);
};

var setMenuItems = function(menu_els, opts, undefined) {
	scrollMenuItems = menu_els;
	scrollItems = [];
	for(var i=0,j=scrollMenuItems.length;i<j;i++){
		var scroll_to_id = $(scrollMenuItems[i]).attr('data-js-scroll-to');
		console.log('scroll_to_id', scroll_to_id);
		scrollItems.push( $('[data-scroll-to-target="'+scroll_to_id+'"]').get(0) );
	}
	if ( opts !== undefined ) {
		if ( opts.scrollitems ) {
			scrollItems = opts.scrollitems;
		}
		if ( opts.classname ) {
			classname = opts.classname;
		}
		if ( opts.onIndexChange ) {
			cb = opts.onIndexChange;
		}
	}
	removeEvents();
	if ( scrollItems.length ) {
		setEvents();
	}
	console.log('scrollItems', scrollItems);
	resize();
};

var clear = function(){
	scrollMenuItems = [];
	scrollItems = [];
	cb = function(){};
	removeEvents();
	console.log('scroll items cleared');
};


return {
	'setMenuItems': setMenuItems,
	'update': update,
	'clear': clear
};


}));