var $ = require('jquery');
var easy_drop_down = require('easydropdown');
// alert('a')

var initPage = function(evt, el) {
	// alert('b')
	// alert( $(el).find('select').size() )
	$('select').not('.is-customized').each(function(i, el){
		easy_drop_down( el, {
		// $(el).find('.intro__selects__new select').each(function(i, select){
			// easy_drop_down( select, {
				behavior: {
					maxVisibleItems:6,
					useNativeUiOnMobile: false
				}
			// } );
		});
		$(el).addClass('is-customized');
		$(el).parent().addClass('is-customized');
		
	});
};

$(window).on('site:initPage', initPage);
