// (function(window, factory) { 'use strict';
// 	if (typeof exports !== 'undefined') {
		require('gsap');
		// require('gsap18/utils/SplitText.js');
		// require('gsap18/plugins/DrawSVGPlugin');
// 		module.exports = factory(
// 			window,
			var $ = require('jquery');
			var cymasmooth = require('cymasmooth');
			// require('utils'),
			var site = require('./site.js');
			var revealManager = require('./anims/0reveals-manager.js');
			// require('imagesloadedwithsize'),
			// require('./scrollmagic-anims.js'),
			// require('./frise.js'),
			// require('formcheck'),
			// require('./team-items.js'),
			// require('form-input-placeholder'),
			// require('resize-wo-toolbar'),
			// require('scrollmenu'),
			// require('./anims/anims.js'),
			// require('ismobile')
			// require('slick'),
			// require('nanoscroller'),
			// require('lightslide')
// 		);
// 	} else {
// 		factory(window, jQuery, cymasmooth, scrollMagicAnims, FormCheck);
// 	}

// }(window, function(window, $, cymasmooth, UTILS, site, realResize, is_mobile) {

var pageInit = function(next) {

	site.unfreezeUI();
	site.initPage( next );
};

var first_loading = true;
var hideAnim = function(next_page, prev_page){
	console.log('aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa');
	console.log('prev_page', prev_page.div);
	var old = $('.page__wrap').get();
	var tl = new TimelineMax({
		paused : true,
		onComplete: function(){
		}
	})
	if ( prev_page.model ) {
		tl.to( old, 0.45, {
			opacity: 0,
			ease: Power0.easeOut
		} )
	}
	// alert('bb')
	return tl;
};

var saveScroll = {};
var first__try = true;
var getShowNextPageAnim = function(el, old, actionFromBrowser, prev_page, next_page){
	console.log('actionFromBrowser, prev_page, next_page', actionFromBrowser, prev_page, next_page);
	var final_scrolltop = 0;
	if ( actionFromBrowser == false ) {
		saveScroll[prev_page.url] = $(window).scrollTop();
		console.log('saveScroll', saveScroll);
	}
	else {
		if ( saveScroll[next_page.url] && saveScroll[next_page.url] > 0 && cymasmooth.prev_page && cymasmooth.next_page && cymasmooth.prev_page.model == 'news-detail' && cymasmooth.next_page.model == 'home' ) {
			final_scrolltop = saveScroll[next_page.url];
		}
	}
	// console.log('cymasmooth.prev_page', cymasmooth.prev_page);
	// console.log('cymasmooth.next_page', cymasmooth.next_page);
	// force nav to go to actus blocs if coming from news detail and click on back in the browser, final action is done in site.js
	if ( actionFromBrowser && cymasmooth.prev_page && cymasmooth.next_page && cymasmooth.prev_page.model == 'news-detail' && cymasmooth.next_page.model == 'home' ) {
		cymasmooth.gotopagewithanchor = 'actualites';
	}
	if ( cymasmooth.gotopagewithanchor ) {
		var anchor = cymasmooth.gotopagewithanchor + '';
		var st = $('#'+anchor).offset().top;
		final_scrolltop = st;
		console.log('infoooooooooooooooooooooooooooos', $(el).offset().top);
	}
	// setTimeout(function() {
		var testh = $('.home__intro__visus__ph').height();
		console.log('testh', testh);
	// }, 100);
	console.log('final_scrolltop !!!!!!!!!!!!!!!', final_scrolltop);
	var timeline = new TimelineMax({
		// paused: true
	});
	timeline.addLabel('getShowNextPageAnim0');
	timeline.add(function(){
		$(el).css({visibility:'visible'});
	}, 'getShowNextPageAnim0');
	var appear_duration = 0.007;
	// timeline.to(el, appear_duration, {
	// 	opacity: 1
	// }, 'getShowNextPageAnim0');
	
	// timeline.to(old, appear_duration, {
	// 	opacity: 0
	// }, 'getShowNextPageAnim0');
	
	// if ( first_loading ) {
	// 	timeline.to($('[data-js="site-loader"]'), 0.007, {
	// 		opacity: 0
	// 	}, 'getShowNextPageAnim0');
	// }
	timeline.addLabel('getShowNextPageAnim');
	// timeline.to(window, 0, {
	// 	scrollTo: 0
	// }, 'getShowNextPageAnim');
	timeline.add(function(){
		$('html, body').scrollTop(final_scrolltop);
	}, 'getShowNextPageAnim');
	timeline.add(function(){
	}, 'getShowNextPageAnim');
	timeline.add(function(){
		$('html, body').scrollTop(final_scrolltop);
		// if ( !first__try ) {
			$(old).remove();
		// }
		// else {
		// 	first__try = false;
		// }
		$('.pw__dummy').remove();
		$(el).attr('style','').css({position:'relative'});
		$(window).trigger('page-is-relative');
	}, 'getShowNextPageAnim');
	// first__try = false;
	return timeline;
};

var createAnimNextDiv = function( el ){
	return $( el ).clone().css({
		opacity: 0,
		position: 'fixed',
		top: 0,
		left: 0,
		right: 0,
		// visibility: 'visible',
		zIndex: 5
	}).appendTo($('[data-js="content-wrap"]')).get(0);
};

var createAnimTimeline = function( el ){
	return new TimelineMax({
		paused : true,
		onComplete: function(){
			$('[data-js="site-loading"]').remove();
			$('[data-js="site-loader"]').remove();
			$('.trans-mover').remove();
			// $( '.link-with-mover-effect' ).removeClass( 'link-with-mover-effect' );
			console.log('on complete final');
			// $(old).remove();
			// alert('done')
			// TweenMax.set(next, {'className':'-=is-hidden',clearProps:'all'});
			// TweenMax.set($('.page__rect__mover').get(0), {'className':'-=is-hidden',clearProps:'all'});
		}
	});
};

var showAnim = function(next_page, prev_page){
	console.log('prev_page', prev_page);
	console.log('next_page', next_page);
	$('body').removeClass('body__'+prev_page.model).addClass('body__'+next_page.model);
	
	var old = $(this.contentQuery).get();
	// var next = createAnimNextDiv( next_page.div );
	var next = old;
	if ( prev_page && prev_page.model ) {
		console.log('createAnimNextDiv');
		next = createAnimNextDiv( next_page.div );
		// return;
	}
	else {
		old = $('<div/>');
	}
	$(window).trigger('resize');

	var ww = $(window).width();
	var wh = $(window).height();
	var tl = createAnimTimeline();
	tl.addLabel('prestart', 0);
	tl.add( function(){
		// alert('b')
		$(next).get(0).offsetWidth;
		$(window).trigger('resize');
	}, 'prestart+=0.1' );
	// return tl;
	tl.addLabel('transstart', 'prestart+=0.2' );

	// tl.to( $(next), 1, {
	// 	opacity: 1
	// // }, 'transstart+=1.2');
	// }, 'transstart');

	console.log('this.actionfrombrowser', this.actionfrombrowser);

	tl.add( getShowNextPageAnim( next, old, this.actionfrombrowser, prev_page, next_page ), '+=0.005' );

	

	// tl.add( function(){
	// 	// alert('b')
	// 	$(next).get(0).offsetWidth;
	// 	$(window).trigger('resize');
	// }, 'prestart+=0.1' );
	
	
	tl.add(function(){
		// alert('pageinit')
		pageInit(next);
		// $(window).trigger('sticky:resize');
	}, '+=0.1');



	tl.addLabel('nextmainpartstart', '+=0.1' );

	tl.to( $(old), 0.6, {
		opacity: 0
	}, 'nextmainpartstart' );

	var model = next_page.model;
	// alert(model)
	if ( model != 'home' ) {
		model = 'home';
	}
	// var has_reveal = revealManager( model + 'modelanim', $(next), tl, 'nextmainpartstart' );
	var has_reveal = revealManager( 'homemodelanim', $(next), tl, 'nextmainpartstart' );
	// if ( first_loading ) {
	// 	first_loading = false;
	// 	if ( $('.header__inner').size() ) {
	// 		tl.fromTo( $('.header__inner'), 0.5, {
	// 			opacity: 0
	// 		}, {
	// 			opacity: 1,
	// 			ease: Power0.easeOut
	// 		}, 'nextmainpartstart' )
	// 	}
	// }


	tl.add(function(){
		$(old).remove();
		$('.pw__dummy').remove();
		$(next).css({position:'relative',zIndex:'auto'});

		if ( $('[data-js="content-wrap"] .page__wrap ').size() > 1 ) {
			var total = $('[data-js="content-wrap"] .page__wrap ').size() - 1;
			for(var i=0;i<total;i++){
				$('[data-js="content-wrap"] .page__wrap ').eq(0).remove();
			}
		}
		// alert('y')
	}, '+=1');

	return tl;
};


var f = function() { return false; };

cymasmooth.registerAnim('show', showAnim);

cymasmooth.registerAnim('hide', hideAnim);

// }));
