var $ = require('jquery');
require('gsap');

var animBgSlideshowComplete = function(container, classname, prev, next, $items, cur_item) {
	$(container).removeClass(classname);
	// var next_index = ( ( ( cur_item + 1 + $items.size() ) % $items.size() ) + 1 );
	// alert(next_index)
	// $(container).closest('[data-js="people-slide"]').find('[data-js="slide-current-item-nb"]').text(next_index);
	// alert(cur_item)
	// alert($items.size())
	// $('.home__slideshow__nav--left .nav__txt').text( '0' + ( ( ( cur_item - 1 + $items.size() ) % $items.size() ) + 1 ) )
	// $('.home__slideshow__nav--right .nav__txt').text( '0' + ( ( ( cur_item + 1 + $items.size() ) % $items.size() ) + 1 ) )
	// $(prev).hide().css('z-index', 2);
	// $(prev).css('z-index', 2);
	// // $(prev).css('visibility', 'hidden');
	// $(prev).css('display', 'none');
	// $(prev).removeClass('is-active');
	// $(next).css('position', 'relative');
	// $(next).css('z-index', 'auto');
	// $(next).addClass('is-active');
};

var animBgSlideshowStart = function(container, classname, prev, next, $items, cur_item) {
	console.log('cur_item', cur_item);
	var next_index = ( ( ( cur_item + $items.size() ) % $items.size() ) + 0 );
	// alert(next_index)
	// $(container).closest('[data-js="people-slide"]').find('[data-js="slide-current-item-nb"]').text(next_index);
	$(container).closest('[data-js="people-slide"]').find('.about__equipe__blocs__name__item').removeClass('is-active').eq(next_index).addClass('is-active');
	$(container).closest('[data-js="home-slide"]').find('[data-js="slick-nb-item"]').removeClass('is-active').eq(next_index).addClass('is-active');
	// alert(cur_item)
	// alert($items.size())
	// $('.home__slideshow__nav--left .nav__txt').text( '0' + ( ( ( cur_item - 1 + $items.size() ) % $items.size() ) + 1 ) )
	// $('.home__slideshow__nav--right .nav__txt').text( '0' + ( ( ( cur_item + 1 + $items.size() ) % $items.size() ) + 1 ) )
	// $(prev).hide().css('z-index', 2);
	// $(prev).css('z-index', 2);
	// // $(prev).css('visibility', 'hidden');
	// $(prev).css('display', 'none');
	// $(prev).removeClass('is-active');
	// $(next).css('position', 'relative');
	// $(next).css('z-index', 'auto');
	// $(next).addClass('is-active');
};

var isInit = false;
var prevIndex = 0;
var a = function($container, prev_item, cur_item, options, $items, datas, way) {
	if ( isInit == false ){
		isInit = true;
		// $items.each(function(i, el){
		// 	var label = $(el).find('.home__slideshow__slides__page__link__label');
		// 	new SplitText(label, {type:'chars'});
		// 	$(el).find('.home__slideshow__slides__sous__titre > div').wrapmask({
		// 		pos: 'outer'
		// 	}).childs.addClass('inner');
		// });
	}
	var ww = $(window).width();
	var wh = $(window).height();
	var cs = ['.bloc__slideshow__imgs__item', '.home__slideshow__txt__item__wrap', '.home__slideshow__nav__item__wrap'];
	// var cs = ['.bloc__slideshow__imgs__item', '.bloc__slideshow__txts__item__titre', '.bloc__slideshow__txts__item__txt'];
	// var cs = ['.bloc__slideshow__txts__item__titre', '.bloc__slideshow__txts__item__txt'];
	var $prev = $items.get(prev_item);
	var $next = $items.get(cur_item);
	// var origw = (way < 0) ? 0 : ww;
	var prevs = [];
	var nexts = [];
	for(var i=0,j=cs.length;i<j;i++){
		// prevs.push( $($prev).find(cs[i]).get(0) );
		// nexts.push( $($next).find(cs[i]).get(0) );
		prevs.push( $container.find(cs[i]).get(prev_item) );
		nexts.push( $container.find(cs[i]).get(cur_item) );
	}
	var dist = 10; // 10%
	// var is_mobile = /ipad|iphone|ipod|android|blackberry|windows phone|opera mini|silk/i.test(navigator.userAgent);
	// is_mobile = true;
	var tl = new TimelineMax({
		paused: true,
		onComplete: animBgSlideshowComplete,
		onCompleteParams: [$container.get(0), options.cantAnimClass, $prev, $next, $items, cur_item]
	})

	tl.call( animBgSlideshowStart, [$container.get(0), options.cantAnimClass, $prev, $next, $items, cur_item], 0);
	console.log('cur_item', cur_item);

	// way *= -1;

	// $(datas.navItems).removeClass('is-active').eq(cur_item).addClass('is-active')

	// tl.set( [prevs[1], prevs[2]], {
	// 	className: '-=is-active'
	// } )
	// tl.set( [nexts[1], nexts[2]], {
	// 	className: '+=is-active'
	// } )
	
	// tl.set( $('.titre__a__wrap'), {
	// 	className: '+=is-hidden'
	// }, 0 )
	console.log(way);
	if ( way > 0 ){
		var to_hide_index = ( cur_item + $items.length + -1 ) % $items.length;
		var to_hide_index = prevIndex;
		var to_show_index = ( cur_item + $items.length + 0 ) % $items.length;
		var to_show_behind_index = ( cur_item + $items.length + 1 ) % $items.length;
		console.log('to_hide_index', to_hide_index, to_show_index, to_show_behind_index);
	}
	else if ( way < 0 ){
		var to_hide_behind_index = ( cur_item + $items.length + 2 ) % $items.length;
		var to_hide_index = ( cur_item + $items.length + 1 ) % $items.length;
		var to_hide_index = prevIndex;
		var to_show_index = ( cur_item + $items.length - 0 ) % $items.length;
	}
		// tl.set($items.eq(to_show_behind_index), {zIndex: 3});
		tl.set($items.eq(to_show_index), {zIndex: 4});
		tl.set($items.eq(to_hide_index), {zIndex: 5});
		tl.fromTo( $items.eq(to_hide_index).find('.home__slideshow__slides__visuel'), {
			scale: 1.001
		}, {
			duration: 3,
			scale: 1.15,
			opacity: 0,
			ease: 'power1.in'
		}, 0 );
		tl.to( $items.eq(to_hide_index).find('.home__slideshow__slides__item__nb__wrap, .home__slideshow__slides__page__link__label div, .home__slideshow__slides__page__link__arrow'), {
			duration: 1.5,
			opacity: 0
		}, 0 );
		tl.fromTo( $items.eq(to_hide_index).find('.home__slideshow__slides__sous__titre .inner'), {
			y: '0%'
		}, {
			duration: 1.5,
			y: '-101%',
			stagger: 0.2,
			ease: 'power2.in'
		}, 0 );
		tl.fromTo( $items.eq(to_show_index).find('.home__slideshow__slides__visuel'), {
			scale: 1.1,
			opacity: 1
		}, {
			duration: 3,
			scale: 1.001,
			opacity: 1
		}, 0 );
		tl.fromTo( $items.eq(to_show_index).find('.home__slideshow__slides__item__nb__wrap'), {
			opacity: 0
		}, {
			duration: 1.5,
			opacity: 1
		}, 1.5 );
		tl.fromTo( $items.eq(to_show_index).find('.home__slideshow__slides__sous__titre .inner'), {
			y: '101%'
		}, {
			duration: 1.5,
			y: '0%',
			stagger: 0.2
		}, 2 );
		tl.fromTo( $items.eq(to_show_index).find('.home__slideshow__slides__page__link__label div, .home__slideshow__slides__page__link__arrow'), {
			opacity: 0
		}, {
			duration: 2,
			stagger: 0.07,
			opacity: 1,
			ease: 'none'
		}, 2.7 );
		// tl.set($items.eq(to_show_behind_index), {zIndex: 4});
		tl.set($items.eq(to_show_index), {zIndex: 5});
		tl.set($items.eq(to_hide_index), {zIndex: 2});
	prevIndex = to_show_index;
	$items.removeClass('is-current')
		.eq(to_show_index).addClass('is-current');
	// }
	// else if ( way < 0 ){
	// 	var to_hide_behind_index = ( cur_item + $items.length + 2 ) % $items.length;
	// 	var to_hide_index = ( cur_item + $items.length + 1 ) % $items.length;;
	// 	var to_show_index = ( cur_item + $items.length - 0 ) % $items.length;
	// 	console.log('to_hide_index', to_hide_index, to_show_index, to_hide_behind_index);
	// 	tl.set($items.eq(to_hide_behind_index), {zIndex: 3});
	// 	tl.set($items.eq(to_hide_index), {zIndex: 4});
	// 	tl.set($items.eq(to_show_index), {zIndex: 5});
	// 	tl.fromTo( $items.eq(to_hide_behind_index), {
	// 		rotation: 20,
	// 		opacity: 1,
	// 		scale: 0.9
	// 	}, {
	// 		rotation: 0,
	// 		opacity: 0,
	// 		scale: 0.8
	// 	}, 0 );
	// 	tl.fromTo( $items.eq(to_hide_index), {
	// 		rotation: 0,
	// 		opacity: 1,
	// 		scale: 1.001
	// 	}, {
	// 		rotation: 20,
	// 		opacity: 1,
	// 		scale: 0.9
	// 	}, 0 );
	// 	tl.fromTo( $items.eq(to_show_index), {
	// 		rotation: -20,
	// 		opacity: 0,
	// 		scale: 1.1
	// 	}, {
	// 		rotation: 0,
	// 		opacity: 1,
	// 		scale: 1.001
	// 	}, 0 );
	// 	tl.set($items.eq(to_hide_behind_index), {zIndex: 2});
	// 	tl.set($items.eq(to_show_index), {zIndex: 5});
	// 	tl.set($items.eq(to_hide_index), {zIndex: 4});
	// }

	// tl.set( $next, {
	// 	className: '+=is-moving',
	// 	opacity: 1
	// }, 0 )
	// tl.staggerFromTo( $($prev).find('[data-js="slide-anim"]'), 0.5, {
	// 	y: 0,
	// 	opacity: 1
	// }, {
	// 	y: 0,
	// 	opacity: 0,
	// 	ease: 'power3.out'
	// }, 0, 0 );
	// tl.staggerFromTo( $($next).find('[data-js="slide-anim"]'), 1.5, {
	// 	y: 20,
	// 	opacity: 0
	// }, {
	// 	y: 0,
	// 	opacity: 1,
	// 	ease: 'power3.out'
	// }, 0.1, 0.2 );
	// tl.set( [$prev], {
	// 	className: '-=is-active',
	// 	opacity: 0
	// } )
	// tl.set( [$next], {
	// 	className: '+=is-active'
	// } )
	// tl.set( $next, {
	// 	className: '-=is-moving'
	// } )
	// tl.staggerFromTo( nexts, 1, {
	// 	opacity: 0,
	// 	x: ww*0.1*-way
	// }, {
	// 	x : 0,
	// 	opacity: 1,
	// 	ease: Power1.easeOut
	// }, 0.1, 0.75 );
	tl.timeScale(1.6)
	tl.play();
};

// return a;

module.exports = a;

// }));