var $ = require('jquery');
var reveals = require('./0reveals');

reveals('bloc-comite', function(el, tl){
    tl.fromTo( $(el).find('.bloc__comite__square--b'), 0.5, {
        transformOrigin: '100% 0%',
        scaleX: 0
    }, {
        scaleX: 1,
        ease: Circ.easeInOut
    }, 0);
    tl.fromTo( $(el).find('.bloc__comite__img__wrap'), 0.8, {
        transformOrigin: '100% 0%',
        x: '-100%'
    }, {
        x: '0%',
        ease: Circ.easeInOut
    }, 0); 
    tl.fromTo( $(el).find('.bloc__comite__img__wrap img'), 0.8, {
        transformOrigin: '100% 0%',
        x: '100%'
    }, {
        x: '0%',
        ease: Circ.easeInOut
    }, 0);
    tl.fromTo( $(el).find('.bloc__comite__square--a'), 0.5, {
        transformOrigin: '0% 0%',
        scaleX: 0
    }, {
        scaleX: 1,
        ease: Circ.easeInOut
    }, 0.05);
    tl.staggerFromTo( $(el).find('.bloc__comite__titre, .bloc__comite__sstitre, .bloc__comite__txt, .bloc__comite__cta').get(), 0.5, {
        x: '-10%',
        opacity: 0
    }, {
        x: '0%',
        opacity: 1,
        ease: Circ.easeOut
    }, 0.1, 0.75);
    // tl.fromTo( $(el).find('.bloc__about__img__square--d'), 0.5, {
    //     transformOrigin: '0% 0%',
    //     scaleX: 0
    // }, {
    //     scaleX: 1,
    //     ease: Circ.easeInOut
    // }, 1.05);
    // var line = $(el).find('.about__assocs__titre__line');
    // var bloc_left = $(el).find('.about__assocs__titre');
    // var bloc_right = $(el).find('.about__assocs__sstitre');
    // tl.fromTo( line, 1, {
    //     scaleY: 0,
    //     transformOrigin: '50% 0%'
    // }, {
    //     scaleY: 1,
    //     ease: Power3.easeInOut
    // });
    // tl.addLabel('end', '-=0.6');
    // tl.fromTo( bloc_left, 1.5, {
    //     opacity: 0,
    //     x: 20
    // }, {
    //     opacity: 1,
    //     x: 0,
    //     ease: Power2.easeOut
    // }, 'end');
    // tl.fromTo( bloc_right, 1.5, {
    //     opacity: 0,
    //     x: -20
    // }, {
    //     opacity: 1,
    //     x: 0,
    //     ease: Power2.easeOut
    // }, 'end+=0.15');
    return tl;
});
