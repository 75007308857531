var $ = require('jquery');
var reveals = require('./0reveals');

reveals('bloc-news-detail-head', function(el, tl){
    var lines = new SplitText($(el).find('.news__detail__title'), {type:'lines,words,chars'}).lines;
    $(lines).addClass('news__detail__title__line');
    tl.staggerFromTo( $(el).find('.news__detail__item__first__tags, .news__detail__title__line, .news__detail__date, .news__detail__header__share__title, .news__detail__share'), 0.5, {
        opacity: 0,
        cycle: {
            x: function(i){
                var ww = $(window).width() * 0.1;
                var way = i % 2 == 0 ? 1 : -1;
                return ww * way;
            }
        }
    }, {
        opacity: 1,
        x: 0,
        ease: Circ.easeOut
    }, 0.1, 0 );
    tl.fromTo( $(el).find('.news__detail__visu__img'), 0.8, {
        y: '-100%'
    }, {
        y: '0%',
        ease: Circ.easeInOut
    }, 0.6);
    tl.fromTo( $(el).find('.news__detail__visu__img img'), 0.8, {
        y: '100%'
    }, {
        y: '0%',
        ease: Circ.easeInOut
    }, 0.6);
    // var line = $(el).find('.about__assocs__titre__line');
    // var bloc_left = $(el).find('.about__assocs__titre');
    // var bloc_right = $(el).find('.about__assocs__sstitre');
    // tl.fromTo( line, 1, {
    //     scaleY: 0,
    //     transformOrigin: '50% 0%'
    // }, {
    //     scaleY: 1,
    //     ease: Power3.easeInOut
    // });
    // tl.addLabel('end', '-=0.6');
    // tl.fromTo( bloc_left, 1.5, {
    //     opacity: 0,
    //     x: 20
    // }, {
    //     opacity: 1,
    //     x: 0,
    //     ease: Power2.easeOut
    // }, 'end');
    // tl.fromTo( bloc_right, 1.5, {
    //     opacity: 0,
    //     x: -20
    // }, {
    //     opacity: 1,
    //     x: 0,
    //     ease: Power2.easeOut
    // }, 'end+=0.15');
    return tl;
});
