var $ = require('jquery');
var reveals = require('./0reveals');

reveals('bloc-vieprivee', function(el, tl){
    

    tl.fromTo( $(el).find('.bloc__vieprivee__titre'), 0.8, {
        x: '-5%',
        opacity: 0
    }, {
        x: '0%',
        opacity: 1,
        ease: Circ.easeOut
    }, 0);

    tl.fromTo( $(el).find('.bloc__vieprivee__video__video__wrap'), 0.8, {
        transformOrigin: '100% 0%',
        x: '-100%'
    }, {
        x: '0%',
        ease: Circ.easeInOut
    }, 0.1); 
    tl.fromTo( $(el).find('.bloc__vieprivee__video'), 0.8, {
        transformOrigin: '100% 0%',
        x: '100%'
    }, {
        x: '0%',
        ease: Circ.easeInOut
    }, 0.1);

    // tl.fromTo( $(el).find('.bloc__vieprivee__titre'), 0.8, {
    //     transformOrigin: '100% 0%',
    //     scaleX: 0.8
    // }, {
    //     scaleX: 1,
    //     ease: Circ.easeInOut
    // }, 0);

    tl.staggerFromTo( $(el).find('.bloc__vieprivee__menu__list .menu-item'), 0.5, {
        x: '-5%',
        opacity: 0
    }, {
        x: '0%',
        opacity: 1,
        ease: Circ.easeOut
    }, 0.15, 0.43);
    // tl.fromTo( $(el).find('.bloc__about__img__square--d'), 0.5, {
    //     transformOrigin: '0% 0%',
    //     scaleX: 0
    // }, {
    //     scaleX: 1,
    //     ease: Circ.easeInOut
    // }, 1.05);
    // var line = $(el).find('.about__assocs__titre__line');
    // var bloc_left = $(el).find('.about__assocs__titre');
    // var bloc_right = $(el).find('.about__assocs__sstitre');
    // tl.fromTo( line, 1, {
    //     scaleY: 0,
    //     transformOrigin: '50% 0%'
    // }, {
    //     scaleY: 1,
    //     ease: Power3.easeInOut
    // });
    // tl.addLabel('end', '-=0.6');
    // tl.fromTo( bloc_left, 1.5, {
    //     opacity: 0,
    //     x: 20
    // }, {
    //     opacity: 1,
    //     x: 0,
    //     ease: Power2.easeOut
    // }, 'end');
    // tl.fromTo( bloc_right, 1.5, {
    //     opacity: 0,
    //     x: -20
    // }, {
    //     opacity: 1,
    //     x: 0,
    //     ease: Power2.easeOut
    // }, 'end+=0.15');
    return tl;
});
