var $ = require('jquery');

var cb_bu = false;
var loop = 2;

var start = function(cb){
	// cb_bu = cb;
	startAnim();
};

var startAnim = function(){
	// loop--;
	// if ( loop > 0 ) {
		createAnim();
	// }
	// else {
	// 	createEndAnim();
	// }
};

var ca = function (tl, val, ea) {
	var vala = val.split('-');
	vala[1] = parseInt( vala[1], 10 );
	vala[2] = parseInt( vala[2], 10 );
	tl.fromTo( $('#'+val), vala[2] - vala[1], {
		drawSVG:'0% 0%'
	}, {
		drawSVG:'0% 100%',
		ease: ea
	}, vala[1] );
};

var createAnim = function(){
	var tl = new TimelineMax({paused : true});
	tl.set( $('.home__head__logo'), {
		opacity: 1
	}, 0 );
	ca(tl, 'mask-1-11', Power0.easeOut);
	ca(tl, 'mask-11-13', Power0.easeOut);
	ca(tl, 'mask-13-15', Power0.easeOut);
	ca(tl, 'mask-15-19', Power0.easeOut);
	ca(tl, 'mask-19-23', Power0.easeOut);
	ca(tl, 'mask-21-25', Power0.easeOut);
	ca(tl, 'mask-25-28', Power0.easeOut);
	ca(tl, 'mask-27-30', Power0.easeOut);
	ca(tl, 'mask-30-32', Power0.easeOut);
	ca(tl, 'mask-32-39', Power0.easeOut);
	ca(tl, 'mask-39-44', Power0.easeOut);
	ca(tl, 'mask-41-43', Power0.easeOut);
	ca(tl, 'mask-43-47', Power0.easeOut);
	ca(tl, 'mask-47-52', Power0.easeOut);
	ca(tl, 'mask-52-54', Power0.easeOut);
	ca(tl, 'mask-54-56', Power0.easeOut);
	ca(tl, 'mask-56-60', Power0.easeOut);
	ca(tl, 'mask-58-65', Power0.easeOut);
	ca(tl, 'mask-65-68', Power0.easeOut);
	ca(tl, 'mask-68-73', Power0.easeOut);
	ca(tl, 'mask-73-78', Power0.easeOut);
	ca(tl, 'mask-76-79', Power0.easeOut);
	ca(tl, 'mask-85-88', Power0.easeOut);
	ca(tl, 'mask-90-93', Power0.easeOut);
	ca(tl, 'mask-100-115', Power0.easeOut);
	tl.timeScale(25);
	// tl.call(cb_bu, null, null, '+=5')
	// tl.timeScale(200);
	// tl.timeScale(2000);
	tl.play();
};

var createEndAnim = function(){
	var tttl = new TimelineMax({repeat:0});
	tttl.to($('.site__loader'), 1, {
		opacity: 0
	})
	// tttl.staggerFrom($('.header__logo, .header__baseline, .header__toggler'), 1, {
	// 	opacity: 0,
	// 	y: -30,
	// 	ease: Power3.easeOut
	// }, 0.15, '-=0.5')
	tttl.addLabel('headeranim', '-=0.5');
	tttl.staggerFrom($('.header'), 1, {
		// opacity: 0,
		y: '-100%',
		ease: Power3.easeOut
	}, 0.15, 'headeranim')
	tttl.from( $('.bd__items__inner'), 1, {
		y:'-100%',
		ease: Power3.easeOut
	}, 'headeranim')
	tttl.call(cb_bu, null, null, '-=0.75')
	tttl.call(function(){
		$('.site__loader').remove();
	}, null, null)
};

module.exports = start;
