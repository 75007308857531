// (function(window, factory) { 'use strict';
// 	if (typeof exports !== 'undefined') {
		require('history-new'); 
		require('cymasmooth/onshow-simple');
		// module.exports = factory(
		// 	window,
			var $ = require('jquery');
			var cymasmooth = require('cymasmooth');
			var site = require('./site.js');
			// require('imagesloadedwithsize'),
			// require('./head-svg-mover.js'),
			// require('ismobile'),
			// require('nprogresscustom'),
			// require('raf'),
			// var paralax = require('./compo/paralax.js');
			var paralax = require('reveal');
			// var menuToggler = require('./menu-toggler.js');
			var cymasmoothcache = require('cymasmooth-cache');
			var popup = require('popup');
			var mq = require('mq');	
			var menutoggler = require('menu-toggler');
			// require('mainmenu')
// 		);
// 	} else {
// 		factory(window, jQuery, window.cymasmooth, imagesLoaded);
// 	}

// }(window, function(window, $, cymasmooth, site, imagesLoaded, is_mobile, NProgress, raf, paralax, menuToggler, cymasmoothcache, popup) {

if ( !site.cymaSmooth ) {
	return;
}

var debug = false;

cymasmooth.registerFunc('linkClickedForce', function(evt, el){
	alert('hihihi')
	if ( $(el).attr('target') == '_blank' ) return true;
	if ( $(el).attr('data-no-ajax') == '1' ) return true;
	if ( $(el).attr('role') == 'button' ) return false;
	if ( el.id == 'cookie_action_close_header' ) return false;
	if ( el.id == 'cn-accept-cookie' ) return false;
	if ( $(el).attr('data-link-film') == '1' ) {
		// show film popup
		evt.preventDefault();
		$('[data-js="popup-video"]').popupvideo('start');
		return false;
	}
	if ( $(el).attr('data-share-link') == '1' ) {
		var href = $(el).attr('href');
		var title = $(document).find("title").text();
		// show film popup
		evt.preventDefault();
		popup(href, title, 580, 470);
		return false;
	}
	evt.preventDefault();
	var url = $(el).attr('href');
	if ( url == History.getState().url && $('[data-is-webapp="1"]').size() === 0 ) {
		return false;
	}
	if ( cymasmooth.animatorObj ) {
		// console.log('stop and complete animation');
		cymasmooth.animatorObj.stopAndComplete();
	}
	cymasmooth.goTo( url );
	alert('13')
	paralax( false );
	return false;
});

cymasmooth.registerFunc('updateFromHtml', function(next_page){
	alert('updateFromHtml')
	var html = $(next_page.html);
	// update head menu class
	var index = html.find('.header__menu__ul__a > li.current-menu-item').index();
	if ( index < 0 ) {
		index = html.find('.header__menu__ul__a > li.current_page_parent').index();
	}
	$('.header__menu__ul__a > li').removeClass('current-menu-item current_page_parent');
	if ( index >= 0 )  {
		$('.header__menu__ul__a > li').eq( index ).addClass('current-menu-item');
	}
	alert(index)

	// update breadcrumb
	var bc = html.find('.bd__items__wrap');
	$('.bd__items__wrap').remove();
	if ( bc.size() ) {
		bc.insertAfter( $('.header__inner') );
	}

	// update lang switch cta href
	var new_lg = html.find('.lang__cta').attr('href');
	$('.lang__cta').attr('href', new_lg);

	// update meta
	$('head meta').filter('[name="description"], [name="keywords"], [property="og:image"]').remove();
	html.find('meta').filter('[name="description"], [name="keywords"], [property="og:image"]').insertAfter('head meta[name="viewport"]');
	$('head title').text(html.find('title').first().text());
	
	// update GA
	if ( typeof _gaq != 'undefined' ) {
		_gaq.push(['_trackPageview'], next_page.url);
	}
	else if ( window['GoogleAnalyticsObject'] && window[window['GoogleAnalyticsObject']] ) {
		window[window['GoogleAnalyticsObject']]('send', 'pageview');
	}
	else if ( typeof gtag != 'undefined' ) {
		gtag('config', 'UA-XXXXXXXXXXX', {
			'page_title': html.find('title').text(),
			'page_path': window.location.pathname
		});
	}
});

var first_pass = false; // pass useless
cymasmooth.registerFunc('ajaxLoad', function(){
	var url = window.location.toString();
	alert('12')
	paralax( false );
	menuToggler();

	$('.header').addClass('force-show-menu');
	var tl = new TimelineMax({paused:true});
	var h = $('.header__inner').height();
	if ( h < 10 ) {
		h = $('.header__bloca').height();
	}
	else {
		h = $('.header__inner').outerHeight();
	}

	$('.page__loader__inner').css('top', h);

	tl.fromTo( $('.page__loader__mover__wrap'), 1, {
		// height:'100%',
		x:'-160%',
		opacity:1
	}, {
		// height:'100%',
		x:'-20%',
		opacity:1,
		ease: Power3.easeInOut
	} );
	tl.call(function(){
		if ( cymasmoothcache.onAjaxLoadBlocking(url) ) {
			$.ajax({url: url, context:{url: url}, success: this.ajaxSuccessCB( url )});
		}
	}, null, this);
	alert('aaaa')
	tl.play();
});

// cymasmooth.registerFunc('onSameUrlClicked', function(){
// 	alert('same')
// 	cymasmooth.initCurrentPage();
// });

// cymasmooth.registerFuncMulti('onAjaxLoadBlocking', function(){
// cymasmooth.registerFuncMulti('onLinkClickedBlocking', function(evt, el, url){
// cymasmooth.registerFuncMulti('onLinkClickedDone', function(evt, el, url){
// cymasmooth.registerFuncMulti('onLinkClickedDone', function(evt, el, url){
$(window).on('cymasmooth:onAjaxLoad', function(evt, el, url){
	console.log('this', this);
	console.log('arguments', arguments);
	paralax( false )
	evt.preventDefault();
	// alert('xx')
	menutoggler();
	return false;
	var tl = new TimelineMax({paused:true});
	var from = '-180%';
	var to = '-20%';
	if ( mq(-766) ) {
		from = '-250%';
		to = '-50%';
	}
	tl.fromTo( $('.page__loader__mover__wrap'), 0.6, {
		// height:'100%',
		x: from
	}, {
		// height:'100%',
		x: to,
		ease: Power2.easeIn
	}, 0.1 );
	// alert('aaaa')
	// tl.call(function(evt, el, url){
	// 	this.goTo( url );
	// }, [evt, el, url], this, '+=0.2');
	tl.call(function(evt, el, url){
		menutoggler();
	}, [], this, '+=0.2');
	tl.play();
	// call( callback, [params], scope, position)
	return false;
	// cymasmooth.initCurrentPage();
});

// }));