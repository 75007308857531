
require('./reveals/bloc-about-1.js');
require('./reveals/bloc-about.js');
require('./reveals/bloc-slideshow.js');
require('./reveals/bloc-jobs.js');
require('./reveals/bloc-group.js');
require('./reveals/bloc-comite.js');
require('./reveals/bloc-histoire.js');
require('./reveals/bloc-vieprivee.js');
require('./reveals/bloc-news-grid.js');
require('./reveals/bloc-comite-0.js');
require('./reveals/bloc-comite-1.js');
require('./reveals/brands-header.js');
require('./reveals/bloc-brand-detail-0.js');
require('./reveals/bloc-brand-detail-1.js');
require('./reveals/bloc-discojobs.js');
require('./reveals/bloc-news-list-head.js');
require('./reveals/bloc-news-list-txts-head.js');
require('./reveals/bloc-news-detail-head.js');
require('./reveals/bloc-news-parag.js');
require('./reveals/bloc-news-detail-foot.js');
require('./reveals/bloc-contact-1.js');
require('./reveals/bloc-contact-us.js');
require('./reveals/bloc-brands.js');
require('./reveals/bloc-accueil-1.js');
require('./reveals/bloc-simple-head.js');
// require('./reveals/svg-mask_reveal.js');
// require('./reveals/bloc-accueil-1-arrow.js');
// require('./reveals/title-arrow.js');
// require('./reveals/bloc-accueil-2.js');
// require('./reveals/bloc-accueil-3.js');
// require('./reveals/nlform.js');
// require('./reveals/bloc-texte-flottant.js');
// require('./reveals/bloc-people-list.js');
// require('./reveals/fade-in.js');

var reveals = require('./reveals/0reveals.js');

module.exports = function(a, b, c){
    // console.log('get reveal anim', a, b);
    return reveals(a)(b, c, arguments);
};
