var $ = require('jquery');
require('./mask.js');
var reveals = require('./0reveals');
var closeCtaAnim = require('./closectaanim.js');
var bulleAnim = require('./bulleanim.js');
var pictoAnim = require('./pictoanim.js');

reveals('citypage2anim', function(el, tl, start_label){
	// tl.to( el, 0.5, {
	// 	opacity: 1
	// }, start_label );

	tl.staggerFromTo( $(el).find('.city__map__full__bg, .city__map__full').get().reverse(), 1, {
		y: -20,
		x: 20,
		opacity: 0
	}, {
		y: 0,
		x: 0,
		opacity: 1,
		ease: Power2.easeOut
	}, 0.2, start_label+'+=0.8');

	tl.fromTo( pictoAnim( $(el).find('.city__picto') ), 1.2, {
		progress: 0
	}, {
		progress: 1,
		ease: Power0.easeOut
	}, start_label+'+=0.4');

	tl.staggerFromTo( $(el).find('.we__title__bloc--city2 > div'), 1, {
		opacity: 0,
		y: 30
	}, {
		opacity: 1,
		y: 0,
		ease: Power2.easeOut
	}, 0.07, start_label+'+=0.5');

});
