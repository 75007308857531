var $ = require('jquery');
require('./mask.js');
var reveals = require('./0reveals');
var closeCtaAnim = require('./closectaanim.js');
var bulleAnim = require('./bulleanim.js');
var titleAnim = require('./titleanim.js');

reveals('filmmodelanim', function(el, tl, start_label){
	tl.to( el, 0.5, {
		opacity: 1
	}, start_label );

	tl.fromTo( titleAnim( $(el).find('.page__head') ), 1.2, {
		progress: 0
	}, {
		progress: 1,
		ease: Power0.easeOut
	}, start_label+'+=0.4');

	tl.fromTo( bulleAnim( $(el).find('.we__txt__bloc') ), 1, {
		progress: 0
	}, {
		progress: 1,
		ease: Power0.easeOut
	}, start_label+'+=0.6');

	

	tl.staggerFromTo( $(el).find('.film__wrap, .film__bg').get().reverse(), 1, {
		y: -20,
		x: -20,
		opacity: 0
	}, {
		y: 0,
		x: 0,
		opacity: 1,
		ease: Power2.easeOut
	}, 0.2, start_label+'+=0.8');

	tl.fromTo( $(el).find('.play__circle'), 0.8, {
		drawSVG:"0% 0%"
	}, {
		drawSVG:"0% 100%",
		ease: Power1.easeOut
	}, start_label+'+=1.2' );

	tl.fromTo( $(el).find('.play__triangle'), 0.8, {
		drawSVG:"0% 0%"
	}, {
		drawSVG:"0% 100%",
		ease: Power1.easeOut
	}, start_label+'+=1.2' );
	
});
