var $ = require('jquery');
var imagesLoaded = require('imagesloadedwithsize');

module.exports = function(el, callback){ // onShow
	// console.log('ON SHOW DATA ATTR');
	// console.log('ON SHOW DATA ATTR');
	// console.log('ON SHOW DATA ATTR');
	// console.log('ON SHOW DATA ATTR');
	// console.log('ON SHOW DATA ATTR');
	// console.log('ON SHOW DATA ATTR');
	// console.log('ON SHOW DATA ATTR');
	// console.log('ON SHOW DATA ATTR');
	// console.log('ON SHOW DATA ATTR');
	// console.log('ON SHOW DATA ATTR');
	// console.log('ON SHOW DATA ATTR');
	// console.log('ON SHOW DATA ATTR');
	// console.log('ON SHOW DATA ATTR');
	// console.log('ON SHOW DATA ATTR');
	// console.log('ON SHOW DATA ATTR');
	// console.log('ON SHOW DATA ATTR');
	// console.log('ON SHOW DATA ATTR');
	var els = $(el).find('[data-src], [data-bg-src]').get();
	var src = [];
	$(el).find('img[data-src]').each(function(i, el){ // add img to avoid pb with fancybox cause it use data-src too
		console.log('el', el);
		src.push( $(el).attr('data-src') );
	})
	$(el).find('[data-bg-src]').each(function(i, el){
		console.log('elbg', el);
		src.push( $(el).attr('data-bg-src') );
	})
	console.log('src', src);
	imagesLoaded( src, function() {
		// alert('load done')
		$('[data-src], [data-bg-src]').each(function(i, el){
			// console.log('el', el);
			if ( $(el).attr('data-bg-src')  ) {
				$(el).css('background-image', 'url('+$(el).attr('data-bg-src')+')');
			}
			else {
				// console.log($(el).attr('data-src'));
				$(el).attr('src', $(el).attr('data-src'));
			}
		});
		setTimeout(callback, 50);
	});
};

