var $ = require('jquery');
require('intersection');
var paralax = require('./compo/paralax.js');

var io = false;

var ajaxDone = function(html) {
	console.log('arguments', arguments);
	console.log('this', this);
	var div = $('<div />').insertBefore( this.target ).html(html.replace('data-src', 'src'));
	paralax( false );
	setTimeout(function() {
		paralax( true );
	}, 10);
	if ( div.find('article').length > 0 ) {
		this.io.observe( this.target );
	}
};

var callback = function(entries, ioagain) {
	console.log('entries', entries);
	for(var i=0,j=entries.length;i<j;i++){
		var d = entries[i];
		if ( d.isIntersecting ) {
			d = d.target;
			var step = d.loadstep || 0;
			step++;
			d.loadstep = step;
			$.ajax({
				type: 'POST',
				url: d.getAttribute('data-ajax-url'),
				data: {
					action: 'news_loader',
					first_ids: d.getAttribute('data-first-ids'),
					tag_id: d.getAttribute('data-tag-id'),
					step: step,
					steps: d.getAttribute('data-steps')
				}, 
				success: ajaxDone, 
				dataType: 'html',
				context: {target:d, io:ioagain}
			});
			// d.target.style.color = '#f00';
			ioagain.unobserve( d );
			// console.log('ajax done');
		}
	}
};

var options = {
	// rootMargin: '25% 0px 50% 0px'
	rootMargin: '0px 0px -100px 0px'
};

$(window).on('site:initPage', function(evt, el){
	if ( io === false ) {
		io = new IntersectionObserver(callback, options);
	}
	else {
		io.disconnect();
		// TODO check if it works if we change page and come back again
	}
	if ( $(el).find('[data-js="more-news-loader"]').size() ) {
		io.observe( $(el).find('[data-js="more-news-loader"]').get(0) );
	}
	
});

