var $ = require('jquery');
var reveals = require('./0reveals');

reveals('bloc-simple-head', function(el, tl){
    var way = 1;
    tl.fromTo( $(el).find('.news__detail__title'), 0.5, {
        opacity: 0,
        x: ( 5 * way )  + '%'
    }, {
        opacity: 1,
        x : '0%'        ,
        ease: Circ.easeOut
    }, 0 );
    // var line = $(el).find('.about__assocs__titre__line');
    // var bloc_left = $(el).find('.about__assocs__titre');
    // var bloc_right = $(el).find('.about__assocs__sstitre');
    // tl.fromTo( line, 1, {
    //     scaleY: 0,
    //     transformOrigin: '50% 0%'
    // }, {
    //     scaleY: 1,
    //     ease: Power3.easeInOut
    // });
    // tl.addLabel('end', '-=0.6');
    // tl.fromTo( bloc_left, 1.5, {
    //     opacity: 0,
    //     x: 20
    // }, {
    //     opacity: 1,
    //     x: 0,
    //     ease: Power2.easeOut
    // }, 'end');
    // tl.fromTo( bloc_right, 1.5, {
    //     opacity: 0,
    //     x: -20
    // }, {
    //     opacity: 1,
    //     x: 0,
    //     ease: Power2.easeOut
    // }, 'end+=0.15');
    return tl;
});
