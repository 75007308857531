var $ = require('jquery');
var shuffle = require('shuffle');

module.exports = function(el) {
	// photomasks
	for(var i=0,j=4;i<j;i++){
		for(var m=0,n=4;m<n;m++){
			$('<div class="home__picto__mask">').css({
				left: ( i * 25 ) + '%',
				top: ( m * 25 ) + '%',
			}).appendTo( $(el) )
			if ( i < 3 && m < 3 ) {
				$('<div class="home__picto__mask home__picto__mask--45">').css({
					left: ( i * 25 + 12.5 ) + '%',
					top: ( m * 25 + 12.5 ) + '%',
				}).appendTo( $(el) )
			}
		}
	}

	var masks = shuffle($(el).find('.home__picto__mask').get());
	// tl.staggerFromTo( masks, 0.7, {
	// 	scaleX: 1
	// }, {
	// 	scaleX: 0,
	// 	ease: Power2.easeIn
	// }, 0.05, 'lines' )
	return new TimelineMax({paused:true})
	.staggerTo( masks, 0.7, {
		cycle: {
			scaleX: function( i, el ) {
				// console.log('el', this);
				if ( $(this).hasClass('home__picto__mask--45') ) {
					return 0;
				}
				if ( i % 2 == 0 ) {
					return 0;
				}
				return 1;
			},
			scaleY: function( i, el ) {
				if ( $(this).hasClass('home__picto__mask--45') ) {
					return 0;
				}
				if ( i % 2 == 0 ) {
					return 1;
				}
				return 0;
			},
			transformOrigin: function( i, el ) {
				if ( $(this).hasClass('home__picto__mask--45') ) {
					return '50% 50%';
				}
				var rand = Math.random();
				rand = Math.round( rand ) * 100;
				var randb = Math.random();
				randb = Math.round( randb ) * 100;
				// console.log('rand', rand, randb);
				return rand+'% '+randb+'%';
				if ( i % 2 == 0 ) {
					return '0% 0%';
				}
				return '100% 100%';
			}
		}
	}, 0.01 )
	return new TimelineMax({paused:true})
	.fromTo( $(el).find('.svg__arrow__right__lineh'), 0.5, {
		drawSVG:"100% 100%"
	}, {
		drawSVG:"0% 100%",
		ease: Power0.easeIn
	} )

	.fromTo( $(el).find('.svg__arrow__right__curvebot, .svg__arrow__right__curvetop'), 0.5, {
		drawSVG:"100% 100%"
	}, {
		drawSVG:"0% 100%",
		ease: Power0.easeOut
	}, 0.5 )

	.fromTo( $(el).find('.svg__arrow__right__pointe'), 0.5, {
		drawSVG:"50% 50%"
	}, {
		drawSVG:"0% 100%",
		ease: Power0.easeOut
	}, 0.5 )

	.fromTo( $(el).find('.svg__arrow__right__circle'), 0.75, {
		opacity: 0
	}, {
		opacity: 1,
		ease: Power0.easeOut
	}, 0.85 )

};