var $ = require('jquery');
require('./mask.js');
var reveals = require('./0reveals');
// var closeCtaAnim = require('./closectaanim.js');
// var arrowAnim = require('./arrowanim.js');

var arrowAnim = require('./arrowanim.js');
var pictoAnim = require('./pictoanim.js');
var bulleAnim = require('./bulleanim.js');
var titleAnim = require('./titleanim.js');

reveals('gallerymodelanim', function(el, tl, start_label){
	tl.to( el, 0.5, {
		opacity: 1
	}, start_label );

	tl.fromTo( titleAnim( $(el).find('.page__head') ), 1.2, {
		progress: 0
	}, {
		progress: 1,
		ease: Power0.easeOut
	}, start_label+'+=0.4');

	tl.fromTo( bulleAnim( $(el).find('.we__txt__bloc') ), 1, {
		progress: 0
	}, {
		progress: 1,
		ease: Power0.easeOut
	}, start_label+'+=0.6');

	var els = $(el).find('.gallery__bloc__titre, .gallery__bloc__item ').get();
	if ( els.length > 10 ) {
		els.splice( 10, 99999 );
	}

	tl.staggerFromTo( els, 1, {
		opacity: 0,
		y: 30
	}, {
		opacity: 1,
		y: 0,
		ease: Power2.easeOut
	}, 0.07, start_label+'+=0.8');

});
