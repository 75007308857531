var $ = require('jquery');
require('./mask.js');
var reveals = require('./0reveals');
var closeCtaAnim = require('./closectaanim.js');
var pictoAnim = require('./pictoanim.js');
var bulleAnim = require('./bulleanim.js');
var titleAnim = require('./titleanim.js');

reveals('biblimodelanim', function(el, tl, start_label){
	tl.to( el, 0.5, {
		opacity: 1
	}, start_label );

	tl.fromTo( titleAnim( $(el).find('.page__head') ), 1.2, {
		progress: 0
	}, {
		progress: 1,
		ease: Power0.easeOut
	}, start_label+'+=0.4');

	tl.staggerFromTo( $(el).find('.bibli__submenu__item'), 1, {
		opacity: 0
	}, {
		opacity: 1,
		ease: Power2.easeOut
	}, 0.07, start_label+'+=0.5');

	tl.fromTo( pictoAnim( $(el).find('.bibli__picto') ), 1.2, {
		progress: 0
	}, {
		progress: 1,
		ease: Power0.easeOut
	}, start_label+'+=0.6');

	tl.fromTo( bulleAnim( $(el).find('.we__txt__bloc') ), 1, {
		progress: 0
	}, {
		progress: 1,
		ease: Power0.easeOut
	}, start_label+'+=0.8');

	tl.staggerFromTo( $(el).find('.bibli__subcats__item, .bibli__subitems__item '), 1, {
		opacity: 0,
		y: 30
	}, {
		opacity: 1,
		y: 0,
		ease: Power2.easeOut
	}, 0.07, start_label+'+=1.2');

	tl.staggerFromTo( $(el).find('.bibli__cta__wrap'), 1, {
		opacity: 0,
		y: 30
	}, {
		opacity: 1,
		y: 0,
		ease: Power2.easeOut
	}, 0.07, start_label+'+=1.6');
	
});
