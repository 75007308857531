(function(window, factory) {
	'use strict';
	if (typeof exports !== 'undefined') {
		require('slick');
		module.exports = factory(
			window,
			require('jquery'),
			require('leaflet07'),
			require('ismobile')
		);
	} else {
		factory(window, jQuery);
	}

}(window, function(window, $, L, ismobile) {

	L.Marker.include({
		setInfos: function(datas) {
			this.infosDatas = datas;
			return this;
		},
		popupHover: function() {
			// if ( $(this.infosDatas.html).find('div').size() ) {
				this
					.setPopupContent(this.infosDatas.html)
					.openPopup();
			// }
		},
		popupLeave: function() {
			this.closePopup();
		},
		popupClicked: function() {
			
		// 	// this.canUseHistory = true;
		// 	if ( this.infosDatas.urlcustom && this.infosDatas.urlcustom.length > 0 ) {
		// 		window.location = this.infosDatas.urlcustom;
		// 	}
		// 	else {
		// 		TAMap.getInstance().canUseHistory = true;
		// 		History.pushState(null, null, this.infosDatas.url);
		// 	}

		},
		bindPopup: function (options) {
			var anchor = L.point(this.options.icon.options.popupAnchor || [0, 0]);

			anchor = anchor.add(L.Popup.prototype.options.offset);

			if (options && options.offset) {
				anchor = anchor.add(options.offset);
			}

			options = L.extend({offset: anchor}, options);

			if (!this._popupHandlersAdded) {
				console.log('add marker events');
				// this
					// .on('mouseover', this.popupHover, this)
					// .on('mouseout', this.popupLeave, this)
					// .on('click', this.popupClicked, this)
					// .on('remove', this.closePopup, this)
					// .on('move', this._movePopup, this);
				if ( ismobile ) {
					console.log('added');
					this.on('click', this.popupClicked, this)
				}
				this._popupHandlersAdded = true;
			}

			this._popup = new L.Popup(options, this);

			return this;
		},
		_updateClass: function (opt) {
			if (this._icon) {
				// console.log(this._icon.className);
				if ( this._icon.className.indexOf('marker__panel__wfoot') >= 0 ) {
					// console.log('zzzz update');
					if ( !this.prevZoom ) {
						this.prevZoom = -9999;
					}
					var z = this._map.getZoom();
					if ( opt ) {
						// body...
						z = opt.zoom;
					}
					if ( z != this.prevZoom ) {
						// body...
						// console.log(z);
						// if ( z >= -3 ) {
							L.DomUtil.removeClass(this._icon, 'marker-panel-small9');
							L.DomUtil.removeClass(this._icon, 'marker-panel-small8');
							L.DomUtil.removeClass(this._icon, 'marker-panel-small7');
							L.DomUtil.removeClass(this._icon, 'marker-panel-small6');
						// }
						if ( z == -4 ) {
							L.DomUtil.addClass(this._icon, 'marker-panel-small9');
						}
						if ( z == -5 ) {
							L.DomUtil.addClass(this._icon, 'marker-panel-small8');
						}
						if ( z == -6 ) {
							L.DomUtil.addClass(this._icon, 'marker-panel-small7');
						}
						if ( z == -7 ) {
							L.DomUtil.addClass(this._icon, 'marker-panel-small6');
						}
						this.prevZoom = z;
					}
				}
			}
		},
		// _animateZoom: function (opt) {
		// 	// console.log('az');
		// 	var pos = this._map._latLngToNewLayerPoint(this._latlng, opt.zoom, opt.center).round();

		// 	this._setPos(pos);
		// 	this._updateClass(opt);
		// },
		update: function () {
			// console.log('this._map.zoom', this._map.getZoom());
			// console.log(this._map);
			// console.log(this._map.options.crs.scale( this._map.getZoom() ));
			// console.log(this._map.options.crs.scale( 0 ));
			if (this._icon) {
				// alert('u')
				console.log('this._map.latLngToLayerPoint(this._latlng).round()', this._map.latLngToLayerPoint(this._latlng).round());
				var origin = this._map.latLngToLayerPoint(this._latlng).round();
				var scale = this._map.options.crs.scale( this._map.getZoom() );
				// this._setPos(this._map.latLngToLayerPoint(this._latlng).round());
				this._icon.style[L.DomUtil.TRANSFORM] =
			        L.DomUtil.getTranslateString(origin) + ' scale(' + scale + ') ';
				this._updateClass();
			}
			return this;
		},
		_animateZoom: function (e) {
			// console.log('e.zoom', e.zoom, this._map.zoom);
			var map = this._map,
			    // image = this._image,
			    // scale = map.getZoomScale(e.zoom),
			    scale = this._map.options.crs.scale( e.zoom ),
			    // nw = this._bounds.getNorthWest(),
			    nw = this._latlng,
			    // se = this._bounds.getSouthEast(),
			    se = this._latlng,

			    topLeft = map._latLngToNewLayerPoint(nw, e.zoom, e.center),
			    size = map._latLngToNewLayerPoint(se, e.zoom, e.center)._subtract(topLeft),
			    origin = topLeft._add(size._multiplyBy((1 / 2) * (1 - 1 / scale)));

			// console.log('origin', origin);
			// console.log('L.DomUtil.getTranslateString(origin)', L.DomUtil.getTranslateString(origin));
			// origin = this._map.latLngToLayerPoint(this._latlng).round();
			// image.style[L.DomUtil.TRANSFORM] =
			this._icon.style[L.DomUtil.TRANSFORM] =
			        L.DomUtil.getTranslateString(origin) + ' scale(' + scale + ') ';

			// ok during animation but once anim end, scale is removed and final marker is not correct
		},
	});

	L.Popup.include({
		_updatePosition: function () {
			if (!this._map) { return; }
			// console.log(this.options.popupBottom);

			var pos = this._map.latLngToLayerPoint(this._latlng),
			    animated = this._animated,
			    offset = L.point(this.options.offset);

			if (animated) {
				L.DomUtil.setPosition(this._container, pos);
			}

			this._containerBottom = -offset.y - (animated ? 0 : pos.y);
			this._containerLeft = -Math.round(this._containerWidth / 2) + offset.x + (animated ? 0 : pos.x);

			// bottom position the popup in case the height of the popup changes (images loading etc)
			if ( this.options.popupBottom ) {
				this._container.className += ' popup-popup__bottom'
				// this._container.style.top = this._containerBottom + 'px';
				this._container.style.top = 0 + 'px';
			}
			else {
				this._container.style.bottom = this._containerBottom - 20 + 'px';
			}
			this._container.style.left = this._containerLeft + 'px';
			// console.log('zzzz');
		}
	});

}));