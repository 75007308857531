var $ = require('jquery');
var reveals = require('./0reveals');

reveals('bloc-news-list-head', function(el, tl){
    tl.fromTo( $(el), 0.8, {
        x: '100%'
    }, {
        x: '0%',
        ease: Circ.easeInOut
    }, 0);
    tl.fromTo( $(el).find('.news__list__head__fig__inner'), 0.8, {
        x: '-110%'
    }, {
        x: '0%',
        ease: Circ.easeInOut
    }, 0);
    // var line = $(el).find('.about__assocs__titre__line');
    // var bloc_left = $(el).find('.about__assocs__titre');
    // var bloc_right = $(el).find('.about__assocs__sstitre');
    // tl.fromTo( line, 1, {
    //     scaleY: 0,
    //     transformOrigin: '50% 0%'
    // }, {
    //     scaleY: 1,
    //     ease: Power3.easeInOut
    // });
    // tl.addLabel('end', '-=0.6');
    // tl.fromTo( bloc_left, 1.5, {
    //     opacity: 0,
    //     x: 20
    // }, {
    //     opacity: 1,
    //     x: 0,
    //     ease: Power2.easeOut
    // }, 'end');
    // tl.fromTo( bloc_right, 1.5, {
    //     opacity: 0,
    //     x: -20
    // }, {
    //     opacity: 1,
    //     x: 0,
    //     ease: Power2.easeOut
    // }, 'end+=0.15');
    return tl;
});
