(function(window, factory) {
	'use strict';
	// if (typeof exports !== 'undefined') {
		module.exports = factory(
			window,
			require('jquery'),
			require('ismobile')
		);
	// } else {
	// 	factory(window, jQuery);
	// }

}(window, function(window, $, is_mobile) {
	var canResize = true,
		hasRequestedResize = false,
		$window = $(window),
		resize = 'resize',
		namespace = 'cube',
		width = 'width',
		height = 'height',
		ww = $window[width](),
		wh = $window[height](),
		prev_w = -100,
		prev_h = -100;

	

	var onResize = function() {
		if (canResize) {
			canResize = false;
			resizeHandler();
			setTimeout(function() {
				if (hasRequestedResize) {
					hasRequestedResize = false;
					resizeHandler();
				}
				canResize = true;
			}, 300);
		} else {
			hasRequestedResize = true;
		}
	};

	var resizeHandler = function() {
		ww = $window[width]();
		wh = $window[height]();

		var diff_w = Math.abs(prev_w - ww);
		var diff_h = Math.abs(prev_h - wh);

		if ( !is_mobile || !(diff_w == 0 && diff_h > 0 && diff_h < 60) ) {
		// $window.trigger('extra:resize', [ww, wh]);
			$window.trigger(namespace+':'+resize, [ww, wh]);
		}
	};
	$window.on(resize, onResize);
	$window.trigger(resize);



}));