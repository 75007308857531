var $ = require('jquery');

$.fn.mask = function(output_parent) {
	var out = [];
	var outp = [];
	$(this).each(function(i, el){
		var t = $('<div class="jq__mask" />').insertBefore(el);
		t.append(el);
		if ( output_parent === true ) {
			outp.push( t );
		}
		out.push( el );
	});

	if ( output_parent === true ) {
		return {
			'childs' : $(out),
			'parents' : $(outp)
		};
	}

	return $(out);
};

$.fn.wrapp = function(wrap_class) {
	var out = [];
	var outp = [];
	$(this).each(function(i, el){
		var t = $('<div class="'+wrap_class+'" />').insertBefore(el);
		t.append(el);
		outp.push( t );
		out.push( el );
	});

	return {
		'childs' : $(out),
		'parents' : $(outp)
	};

};


