// alert('pp');
 

module.exports = function(t, r, i, s) {
  var e = window;
  var o = e.screenLeft !== false ? e.screenLeft : screen.left,
    u = e.screenTop !== false ? e.screenTop : screen.top,
    a = e.innerWidth ? e.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width,
    f = e.innerHeight ? e.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height,
    l = a / 2 - i / 2 + o,
    c = f / 3 - s / 3 + u,
    h = e.open(t, r, "scrollbars=yes, width=" + i + ", height=" + s + ", top=" + c + ", left=" + l);
  e.focus && h.focus()
};