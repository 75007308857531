// require('gsap310/src/index.js');
require('gsap310/minified/gsap.min.js');
require('gsap310/minified/DrawSVGPlugin.min.js');
require('gsap310/minified/MorphSVGPlugin.min.js');
require('gsap310/minified/SplitText.min.js');
var scrollTo = require('gsap310/minified/ScrollToPlugin.min.js');
require('gsap310/minified/ScrollTrigger.min.js');
require('gsap310/minified/ScrollSmoother.min.js');
var Draggable = require('gsap310/minified/Draggable.min.js');
require('gsap310/minified/InertiaPlugin.min.js');
// require('gsap310/minified/CustomEase.min.js');
var CustomEase = require('gsap310/minified/CustomEase.min.js');
require('gsap310/minified/MotionPathPlugin.min.js');
// require('gsap310/minified/MotionPathHelper.min.js');
// var ScrollSmoother = require('gsap310/src/ScrollSmoother.js');
// console.log('SplitText', SplitText);
// console.log('ScrollSmoother', ScrollSmoother);
// console.log('x', x);
gsap.registerPlugin(ScrollTrigger, SplitText);
gsap.registerPlugin(Draggable, InertiaPlugin);
gsap.registerPlugin(Draggable, CustomEase);
gsap.registerPlugin(MotionPathPlugin);
gsap.registerPlugin(ScrollSmoother);
gsap.registerPlugin(scrollTo);
var $ = require('jquery');



// console.log('gsap.Timeline.prototype', gsap.Timeline.prototype);

// window.TimelineMax = function(a,b,c,d,e,f,g){
// 	return gsap.timeline(a,b,c,d,e,f,g);
// };

// console.log('TweenMax.set', TweenMax.set);
// TweenMax.set = gsap.set;
// console.log('TimelineMax', TimelineMax);

// register the effect with GSAP:
// alert('a')
gsap.registerEffect({
	name: "svgline",
	extendTimeline: true,
	defaults: {
		duration: 1,
		ease: 'power2.easeOut',
	}, //defaults get applied to the "config" object passed to the effect below
	effect: function(targets, config){
		// return gsap.fromTo(targets, {duration: config.duration, opacity:0});
			// gsap.set(targets, {
			// 	opacity:0, y:config.yOrigin
			// });
			// $(targets).addClass('wc');
		return gsap.fromTo(targets, {
			drawSVG:'0% 0%',
			duration: config.duration,
			ease: config.ease
		}, {
			drawSVG:'0% 100%',
			duration: config.duration,
			ease: config.ease
		});
	}
});

gsap.registerEffect({
	name: "fadeUp",
	extendTimeline: true,
	defaults: {
			duration: 1,
			opacityDelay: 0.1,
			yOrigin: 30,
			stagger: 0.1
	}, //defaults get applied to the "config" object passed to the effect below
	effect: function(targets, config){
		// return gsap.fromTo(targets, {duration: config.duration, opacity:0});
			gsap.set(targets, {
				opacity:0, y:config.yOrigin
			});
			$(targets).addClass('wc');
		return gsap.to(targets, {
				keyframes: [
					{y:0, duration:config.duration, ease:Power2.easeOut},
					{opacity:1, duration:config.duration + config.opacityDelay, delay:-config.duration}
				],
				stagger: config.stagger
			});
	}
});

require('wrapmask');
gsap.registerEffect({
	name: "deroul",
	extendTimeline: true,
	defaults: {
		direction: 'down',
		factor: 0.8,
		duration: 1,
		ease: Power2.easeOut,
		cn: 'ovh rel'
	}, //defaults get applied to the "config" object passed to the effect below
	effect: function(targets, config){
		var wrapmask = $(targets).wrapmask({cn:config.cn,mask:''});
		var p = wrapmask.parents;
		var wrapmask2 = wrapmask.parents.wrapmask({cn:config.cn,mask:''});
		// var tl = new TimelineMax({paused : true});
		var tl = gsap.timeline({paused : true});

		var k = 'yPercent';
		if ( config.direction == 'left' || config.direction == 'right' ) {
			k = 'xPercent';
		}
		var way = 1;
		if ( config.direction == 'left' || config.direction == 'top' || config.direction == 'up' ) {
			way = -1;
		}
		var opts_a = {};
		opts_a[k] = -1 * way * 100;
		var opts_b = {};
		opts_b[k] = config.factor * way * 100;
		console.log('opts_a', opts_a);
		tl.fromTo( p, opts_a, {yPercent:0,xPercent:0, duration: 1, ease: "power0"}, 0 );
		tl.fromTo( targets, opts_b, {yPercent:0,xPercent:0, duration: 1, ease: "power0"}, 0 );
		return gsap.to( tl, {
			duration: config.duration,
			progress: 1,
			ease: config.ease
		});
	}
});

gsap.registerEffect({
	name: "morphTo",
	extendTimeline: true,
	defaults: {
		origin: false,
		target: false,
		duration: 1,
		ease: "power2.inOut",
		originFinalOpacity: 0
	}, //defaults get applied to the "config" object passed to the effect below
	effect: function(targets, config){
		var tl = gsap.timeline({paused : true});

		$target = config.target;
		$target_bb = $target.get(0).getBoundingClientRect();
		$origin = config.origin;
		$origin_bb = $origin.get(0).getBoundingClientRect();
		var decal_x = $target_bb.x - $origin_bb.x;
		var decal_y = $target_bb.y - $origin_bb.y;
		var scale_x = $target_bb.width / $origin_bb.width;
		var scale_y = $target_bb.height / $origin_bb.height;

		tl.to( $origin, {
			duration: 1,
			scaleX: scale_x,
			scaleY: scale_y,
			x: decal_x,
			y: decal_y,
			transformOrigin: '0% 0%',
			opacity: config.originFinalOpacity,
			ease: "power0"
		}, 0 )

		tl.from( $target, {
			duration: 1,
			scaleX: 1 / scale_x,
			scaleY: 1 / scale_y,
			x: -decal_x,
			y: -decal_y,
			transformOrigin: '0% 0%',
			opacity: 0,
			ease: "power0"
		}, 0 )

		return gsap.to( tl, {
			duration: config.duration,
			progress: 1,
			ease: config.ease
		});
	}
});

// now we can use it like this:
// gsap.effects.fadeUp(".box");

// ClassNamePlugin START (requires GSAP 3.2.2 or later)
gsap.registerPlugin({
	name: "className",
	init: true,
	register: function(gsap, Plugin) {
		var CSSPlugin = gsap.plugins.css,
			cssCore = CSSPlugin.core || console.warn("Requires GSAP 3.2.1 or later") || {},
			_removeLinkedListItem = gsap.core._removeLinkedListItem,
			_removeProperty = cssCore._removeProperty,
			PropTween = gsap.core.PropTween,
			_getAllStyles = function(target, uncache) {
				var styles = {},
					computed = getComputedStyle(target),
					cache = target._gsap,
					p;
				for (p in computed) {
					if (isNaN(p) && p !== "cssText" && p !== "length") {
						styles[p] = computed[p];
					}
				}
				uncache && cache && (cache.uncache = true);
				gsap.getProperty(target, "x");
				cache = target._gsap;
				for (p in cache) {
					styles[p] = cache[p];
				}
				return styles;
			};
		Plugin.prototype.init = function(target, endValue, tween) {
			var startClassList = target.getAttribute("class"),
				style = target.style,
				cssText = style.cssText,
				cache = target._gsap,
				classPT = cache.classPT,
				inlineToRemoveAtEnd = {},
				end = (endValue.charAt(1) !== "=") ? endValue : startClassList.replace(new RegExp("(?:\\s|^)" + endValue.substr(2) + "(?![\\w-])"), "") + ((endValue.charAt(0) === "+") ? " " + endValue.substr(2) : ""),
				plugin = this,
				changingVars = {},
				startVars = _getAllStyles(target),
				transformRelated = /(transform|perspective)/i,
				css = new CSSPlugin(),
				_renderClassName = function(ratio) {
					css.render(ratio, css);
					if (!ratio || ratio === 1) {
						target.setAttribute("class", ratio ? end : startClassList);
						for (var p in inlineToRemoveAtEnd) {
							_removeProperty(target, p);
						}
					}
				},
				endVars, p;
			if (classPT) {
				classPT.r(1, classPT.d);
				_removeLinkedListItem(classPT.d, classPT, "_pt");
			}
			target.setAttribute("class", end);
			endVars = _getAllStyles(target, true);
			target.setAttribute("class", startClassList);
			for (p in endVars) {
				if (endVars[p] !== startVars[p] && !transformRelated.test(p)) {
					changingVars[p] = endVars[p];
					if (!style[p] && style[p] !== "0") {
						inlineToRemoveAtEnd[p] = 1;
					}
				}
			}
			cache.classPT = plugin._pt = new PropTween(null, target, "className", 0, 0, _renderClassName, plugin, 0, -11);
			if (style.cssText !== cssText) {
				style.cssText = cssText;
			}
			cache.uncache = true;
			gsap.getProperty(target, "x");
			css.init(target, changingVars, tween);
			plugin._props.push.apply(plugin._props, css._props);
			return 1;
		};
	}
});

var blurProperty = gsap.utils.checkPrefix("filter"),
        blurExp = /blur\((.+)?px\)/,
        getBlurMatch = function(target){(gsap.getProperty(target, blurProperty) || "").match(blurExp) || []};

  gsap.registerPlugin({
    name: "blur",
    get: function(target) {
      return +(getBlurMatch(target)[1]) || 0;
    },
    init: function(target, endValue) {
      var data = this,
          filter = gsap.getProperty(target, blurProperty),
          endBlur = "blur(" + endValue + "px)",
          match = getBlurMatch(target)[0],
          index;
      if (filter === "none") {
        filter = "";
      }
      if (match) {
        index = filter.indexOf(match);
        endValue = filter.substr(0, index) + endBlur + filter.substr(index + match.length);
      } else {
        endValue = filter + endBlur;
        filter += filter ? " blur(0px)" : "blur(0px)";
      }
      data.target = target;
      data.interp = gsap.utils.interpolate(filter, endValue);
    },
    render: function(progress, data) {
      data.target.style[blurProperty] = data.interp(progress);
    }
  });
// ClassNamePlugin END