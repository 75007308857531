var $ = require('jquery');
var revealManager = require('./reveal-manager.js');

var canReveal = false;
var scrollTop = false;
var previousScrollTop = false;
var windowWidth = false;
var windowHeight = false;
var maxScrollTop = false;
var head = $('.header');
var revealFounded = false;
var is_intro = true;
var scrollHandler = function() {
    scrollTop = $(window).scrollTop();
    if (scrollTop > previousScrollTop && scrollTop > 50) {
        head.addClass('is-scrolling-down');
    } else {
        head.removeClass('is-scrolling-down');
    }
    // scrollRevealTrigger(!1);
    scrollRevealTrigger(is_intro);
    is_intro = false;
    $('.parallax').each(function() {
        var container = $(this);
        var c_offset = container.offset();
        var c_height = container.outerHeight();
        c_offset.top = c_offset.top - c_height;
        var total = windowHeight + scrollTop;
        var totalb = c_offset.top + c_height + c_height;
        // console.log('paralx infos', c_offset.top, c_height, scrollTop, windowHeight, maxScrollTop, total, totalb);
        if ( total > c_offset.top && scrollTop < totalb ) {
            // console.log('do');
            var pc = ( total - c_offset.top ) / ( windowHeight + c_height + c_height );
            pc = pc - 0.5;
            pc = pc * 2;
            // pc = pc * parseFloat( container.attr('data-parallax-factor') || 1 );
            container.css({
                transform: 'translate3d(0, ' + (pc * -100) + '%, 0)'
            })
            // console.log('pc', pc);
        }
        // var title = container.find('.title');
        // var target = container.siblings('.page-header, .scroller-background').last();
        // if (container.offset().top + container.height() / 2 > target.offset().top + target.outerHeight()) {
        //     container.addClass('page-scroller--blue')
        // } else {
        //     container.removeClass('page-scroller--blue')
        // }
        // var y = scrollTop / maxScrollTop * 100;
        // title.css({
        //     transform: 'translate3d(0, ' + y + '%, 0)'
        // })
    });
    previousScrollTop = scrollTop;
};

var scrollRevealTrigger = function(intro) {
    if (canReveal) {
        var limit = 0.75;
        if (intro)
            limit = 1;
        var revealLimit = windowHeight * limit + scrollTop;
        var toReveal = [];
        revealFounded = false;
        // $('.scroll-reveal').not('.is-revealed, .to-reveal').each(function() {
        $('[data-reveal]').not('.is-revealed, .to-reveal').each(function() {
            var element = $(this);
            var top = element.offset().top - Number(element.css('marginTop').replace('px', ''));
            var bottom = top + element.outerHeight();
            if (revealLimit >= top || scrollTop >= maxScrollTop - 10) {
                if (top <= scrollTop + windowHeight && bottom >= scrollTop) {
                    toReveal.push(element);
                    revealFounded = true;
                }
                else {
                    element.addClass('is-revealed');
                }
            }
        });
        if ( revealFounded ) {
            var delay = 0;
            var mtl = new TimelineMax({
                paused : true,
                onComplete: function(){
                }
            });
            toReveal.forEach(function(element, index) {
                delay += 0.100;
                mtl.call(function() {
                    element.addClass('is-revealed');
                    element.removeClass('to-reveal');
                    var tl = new TimelineMax({
                        paused : true,
                        onComplete: function(){}
                    });
                    revealManager( element.attr('data-reveal'), element, tl ).play();
                }, null, null, delay);
                element.addClass('to-reveal');
            });
            mtl.play();
        }
    }
};

var resizeHandler = function() {
    windowWidth = $(window).width();
    if (windowHeight != window.innerHeight) {
        var vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', vh + 'px');
        var vw = $('body').width() * 0.01;
        document.documentElement.style.setProperty('--vw', vw + 'px');
        windowHeight = window.innerHeight
    }
    maxScrollTop = $(document).height() - windowHeight;
    scrollHandler()
};

$(window).on('resize orientationchange', resizeHandler);
$(window).on('scroll', scrollHandler);

module.exports = function(to_set) {
    console.log('paralaxxxxxxxxxxxxxxxxx', to_set);
    canReveal = to_set;
    if ( to_set === true ) {
        is_intro = true;
        resizeHandler();
    }
};

