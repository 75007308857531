var $ = require('jquery');
require('./mask.js');
var reveals = require('./0reveals');
var closeCtaAnim = require('./closectaanim.js');
var arrowAnim = require('./arrowanim.js');
var pictoAnim = require('./pictoanim.js');
var bulleAnim = require('./bulleanim.js');
var titleAnim = require('./titleanim.js');

reveals('pwdchangemodelanim', function(el, tl, start_label){
	tl.to( el, 0.5, {
		opacity: 1
	}, start_label );

});
