var $ = require('jquery');

$(window).on('site:initPage', function(evt, el){

	$(el).find('[data-js="approche-titre"]').each(function(i, el){
		// alert(el)

		// create Timeline
		var tl = new TimelineMax({paused : true, repeat:-1})

		var all_chars = [];

		$('.vocation__approche__titre__4__item').each(function(i, eel){
			all_chars.push( new SplitText(eel, {type:'chars'}).chars )
		});

		$('.vocation__approche__titre__4__item').each(function(i, eel){
			var delay = i * 2;
			var delayb = 0.3;
			
			var wait = 2;
			tl.staggerFromTo( all_chars[i], 0.5, {
				rotationX:0
			}, {
				rotationX:90
			}, 0.05, wait + delay )
			tl.staggerFromTo( all_chars[i], 0.1, {
				opacity:1
			}, {
				opacity:0
			}, 0.05, wait + delay + 0.5 )

			//

			tl.staggerFromTo( all_chars[((i+1)%all_chars.length)], 0.1, {
				opacity:0
			}, {
				opacity:1
			}, 0.05, wait + delay + delayb - 0.1 )
			tl.staggerFromTo( all_chars[((i+1)%all_chars.length)], 0.5, {
				rotationX:-90
			}, {
				rotationX:0
			}, 0.05, wait + delay + delayb )
		})

		tl.play()


	});


	
});

