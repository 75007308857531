var $ = require('jquery');
var ismobile = require('ismobile');

var globalInit = function() {
	var body_class = ismobile ? 'ismobile' : 'is-desktop';
	$('body').addClass(body_class);

	// startSiteLoader(function(){
	// 	site.initPage( $('html') );
	// });
	
}; // globalInit()

$(function(){
	// if ( site.cymaSmooth ) {
	// 	// site.freezeUI();
	// 	$('html').addClass('has-cymamooth');
	// }

	globalInit();

	// if ( site.cymaSmooth ) {

	// 	cymasmooth.enableHistory( true );
	// 	cymasmooth.initCurrentPageSimple();
	// 	// setTimeout(function() {
	// 		// cymasmoothcache.init();
	// 	// }, 1000);

	// }

});

// window.onbeforeunload = function() {
// 	window.scrollTo(0, 0);
// };
