var $ = require('jquery');
var reveals = require('./0reveals');
var mq = require('mq');

reveals('bloc-news-grid', function(el, tl){


    tl.fromTo( $(el).find('.news__slide__rect--a'), 0.8, {
        transformOrigin: '0% 100%',
        scaleX: 0
    }, {
        scaleX: 1,
        ease: Circ.easeInOut
    }, 0.2);


    tl.fromTo( $(el).find('.news__slide__rect--b'), 0.5, {
        transformOrigin: '00% 100%',
        scaleX: 0
    }, {
        scaleX: 1,
        ease: Circ.easeInOut
    }, 0);
    

    tl.fromTo( $(el).find('.news__slide__title'), 0.8, {
        x: '-5%',
        opacity: 0
    }, {
        x: '0%',
        opacity: 1,
        ease: Circ.easeOut
    }, 0);

    // $(el).find('.news__list__item__fig').each(function(i, eel) {
    //     tl.fromTo( $(eel), 40.8, {
    //         scale: 0.5
    //     }, {
    //         scale: 1.001,
    //         ease: Power0.easeOut
    //     }, 0.5 + ( i * 0.15 ) );
    //     tl.fromTo( $(eel).find('> .news__list__item__fig__inner'), 40.8, {
    //         scale: 2
    //     }, {
    //         scale: 1.001,
    //         ease: Power0.easeOut
    //     }, 0.5 + ( i * 0.15 ) );
    // });

    var sizesize = $(el).find('.news__list__item ').size();

    $(el).find('.news__list__item ').each(function(i, eel) {
    // $.each( $(el).find('.news__list__item__fig').get().slice(4, 4), function(i, eel) {
        // console.log('i', i);
        // eel = $(eel);
        var decal = 4;
        var start = 3;
        var end = 8;
        if ( mq(-766) ) {
            // alert('mq')
            decal = 0;
            start = 0;
            end = 2;
        }
        if ( $(el).hasClass('news__slide--no-slide') || $(el).hasClass('news__slide--no-slide') ) {
            decal = 0;
            start = -1;
            end = 999;
        }
        else if ( sizesize < 5 && mq(767) ) {
            decal = 0;
            start = -1;
            end = 999;
        }
        console.log('decal, start, end', decal, start, end);
        if ( i > start && i < end ) {
            var way = i % 2 > 0 ? 1 : -1;
            // tl.fromTo( $(eel).find('.news__list__item__fig'), 0.8, {
            //     y: ( way * 100 * 1 ) + '%'
            // }, {
            //     y: '0%',
            //     ease: Circ.easeInOut
            // }, 0 + ( Math.max(0, i-decal) * 0.18 ) );
            // tl.fromTo( $(eel).find('.news__list__item__fig__inner'), 0.8, {
            //     y: ( way * 100 * -1 ) + '%'
            // }, {
            //     y: '0%',
            //     ease: Circ.easeInOut
            // }, 0 + ( Math.max(0, i-decal) * 0.18 ) );
            tl.fromTo( $(eel).find('.news__list__item__fig'), 0.8, {
                y: ( way * 10 * 1 ) + '%',
                opacity: 0
            }, {
                y: '0%',
                opacity: 1,
                ease: Circ.easeInOut
            }, 0 + ( Math.max(0, i-decal) * 0.18 ) );
            tl.staggerFromTo( $(eel).find('.news__list__item__tags, .news__list__item__title').get(), 0.5, {
                x: '-5%',
                opacity: 0
            }, {
                x: '0%',
                opacity: 1,
                ease: Circ.easeOut
            }, 0.05, ( 0.5 + ( Math.max(0, i-decal)  )* 0.18 ) )
        }
    });

    tl.addLabel( 'arrows', 0.95 );
    tl.fromTo( $(el).find('.bloc__slideshow__arrow__wrap--left').get(), 0.5, {
        x: '100%',
        scale: 0.5,
        opacity: 0
    }, {
        x: '0%',
        scale: 1.01,
        opacity: 1,
        ease: Circ.easeOut
    }, 'arrows+=0.1');
    tl.fromTo( $(el).find('.bloc__slideshow__arrow__wrap--right').get(), 0.5, {
        x: '-100%',
        scale: 0.5,
        opacity: 0
    }, {
        x: '0%',
        scale: 1.01,
        opacity: 1,
        ease: Circ.easeOut
    }, 'arrows+=0');

    // tl.fromTo( $(el).find('.bloc__about__img__square--d'), 0.5, {
    //     transformOrigin: '0% 0%',
    //     scaleX: 0
    // }, {
    //     scaleX: 1,
    //     ease: Circ.easeInOut
    // }, 1.05);
    // var line = $(el).find('.about__assocs__titre__line');
    // var bloc_left = $(el).find('.about__assocs__titre');
    // var bloc_right = $(el).find('.about__assocs__sstitre');
    // tl.fromTo( line, 1, {
    //     scaleY: 0,
    //     transformOrigin: '50% 0%'
    // }, {
    //     scaleY: 1,
    //     ease: Power3.easeInOut
    // });
    // tl.addLabel('end', '-=0.6');
    // tl.fromTo( bloc_left, 1.5, {
    //     opacity: 0,
    //     x: 20
    // }, {
    //     opacity: 1,
    //     x: 0,
    //     ease: Power2.easeOut
    // }, 'end');
    // tl.fromTo( bloc_right, 1.5, {
    //     opacity: 0,
    //     x: -20
    // }, {
    //     opacity: 1,
    //     x: 0,
    //     ease: Power2.easeOut
    // }, 'end+=0.15');
    return tl;
});
