var $ = require('jquery');
require('./mask.js');
var reveals = require('./0reveals');
var closeCtaAnim = require('./closectaanim.js');
var bulleAnim = require('./bulleanim.js');
var pictoAnim = require('./pictoanim.js');

reveals('citypage1anim', function(el, tl, start_label){
	tl.fromTo( bulleAnim( $(el).find('.we__txt__bloc') ), 1.2, {
		progress: 0
	}, {
		progress: 1,
		ease: Power0.easeOut
	}, start_label+'+=0.4');

	tl.fromTo( pictoAnim( $(el).find('.city__map__simple') ), 1.2, {
		progress: 0
	}, {
		progress: 1,
		ease: Power0.easeOut
	}, start_label+'+=0.6');

	tl.staggerFromTo( $(el).find('.we__title__bloc--city > div, .we__shad__bloc--city'), 1, {
		opacity: 0,
		y: 30
	}, {
		opacity: 1,
		y: 0,
		ease: Power2.easeOut
	}, 0.07, start_label+'+=0.5');

});
