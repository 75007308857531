var $ = require('jquery');
var to = false;
var check_video_is_playing = function(){
	if ( $('[data-video-with-image="image"]').length > 0 ) {
		var timing = $('[data-video-with-image="video"]').get(0).currentTime;
		console.log('timing', timing);
		if ( timing > 0 ) {
			$('[data-video-with-image="image"]').addClass('is-hidden');
		}
		else {
			to = setTimeout(check_video_is_playing, 500);
		}
	}
};

$(window).on('site:initPage', function(evt, el){
	clearTimeout(to);
	to = setTimeout(check_video_is_playing, 500);
	if ( $(el).find('[data-video-with-image="image"]').length > 0 ) {
		check_video_is_playing();
	}
});
