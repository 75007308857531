var $ = require('jquery');
var imagesLoaded = require('imagesloadedwithsize');

module.exports = function(el, callback){ // onShow
	imagesLoaded( $(el).find('[data-asset="page"]').get(), function() {
		// alert('load done')
		setTimeout(callback, 50);
	});
};

