var $ = require('jquery');
require('slick');
var Swiper = require('swiper');

require('lightslide.js');

var bloc_slide_change = require('./home-slide-change.js');

$(window).on('site:initPage', function(evt, el){

	$(el).find('[data-js="home-slide"]').each(function(i, el){
		
		$(el).find('[data-js="slideshow-items"]').lightSlider({
			'itemsSelector' : '.home__slideshow__slides__item',
			'arrowsItem' : $( el ).find( '[data-js="slideshow-arrow"]' ).get(),
			'arrowLeftClass' : 'prev',
			'autoPlay' : true,
			'stopOnHover' : false,
			'onshowitem': bloc_slide_change,
			'autoPlayDelay' : 8000,
			'navsItem': $(el).find('[data-js="slick-nav-item"]'),
			'navsClassSelected': 'is-active'
		});

		$(el).find('.home__slideshow__slides__item').first().addClass('is-active');
		$(el).find('.home__slideshow__slides__item').first().addClass('is-current');
		$(el).find('[data-js="slick-nav-item"]').first().addClass('is-active');
		$(el).find('[data-js="slick-nb-item"]').first().addClass('is-active');

	});

	$(el).find('[data-js="pourquoi-slide"]').each(function(i, el){
		var slick_options = {
			'prevArrow' : $(el).find('[data-js="slideshow-arrow"]').filter('.prev'),
			'nextArrow' : $(el).find('[data-js="slideshow-arrow"]').filter('.next'),
			autoplay: false,
			// arrows: false,
			// fade: true,
			infinite: true,
			// autoplaySpeed: 4000,
			pauseOnHover: true,
			speed: 1000,
			slidesToShow: 3,
			centerMode: false,
			variableWidth: true,
			slidesToScroll: 1,
			draggable: false,
			responsive: [
			    {
			      breakpoint: 80000,
			      settings: "unslick"
			    },
			    {
			      breakpoint: 768,
			      settings: {
			      	slidesToShow: 3,
			        centerMode: false,
			        variableWidth: true,
			        infinite: true
			      }
			    }
			    // You can unslick at a given breakpoint now by adding:
			    // settings: "unslick"
			    // instead of a settings object
			  ]
			// asNavFor: '.imm__slide__titres__items, .imm__slide__txts__items'
			// appendDots: $(el).prev(),
			// appendDots: $(el).parent().find('[data-js="slickdots"]'),
			// dots: true,
			// dotsClass : 'slickslide__navs'
		};
		$(el).find('[data-js="slideshow-items"]').slick(slick_options);

		// On before slide change
		$(el).find('[data-js="slideshow-items"]').on('beforeChange', function(event, slick, currentSlide, nextSlide){
			// console.log(slick);
			// console.log(currentSlide);
			// console.log(nextSlide);
			$(el).find('[data-js="slick-nb-item"]').removeClass('is-active')
				.eq( nextSlide ).addClass('is-active')
		});

		$(el).find('[data-js="slick-nb-item"]').eq(0).addClass('is-active')
		
		// var options = $(el).attr('data-js-options');
		
		// if ( $(el).attr('data-js-options') == 'slick-with-navs' ) {
			var size = $(el).find('[data-js="slick-nav-item"]').size();
			// custom navs
			// $(el).find('[data-js="slick-nav-item"]').click(function(){
			// 	var index = $(this).index();
			// 	// console.log('index', index);
			// 	$(el).find('[data-js="slideshow-items"]').slick('slickGoTo', index);
			// });
			// On before slide change
			$(el).find('[data-js="slideshow-items"]').on('beforeChange', function(event, slick, currentSlide, nextSlide){
				// console.log(slick);
				// console.log(currentSlide);
				// console.log(nextSlide);
				gsap.to($(el).find('[data-js="slick-nav-item-cursor"]'), {
					x: ( nextSlide / slick.$slides.length * 100 ) + '%'
				});
			});

			// $(el).find('[data-js="slick-nav-item"]').first().addClass('is-active').css({'opacity': 1});
		// }

		// $(el).find('[data-fancybox]').each(function(i, el){
		// 	$(el).attr('data-fancybox', 'xxx'+(Math.round(Math.random() * 999999)))
		// })
	});

	$(el).find('[data-js="slideshow-fade"]').each(function(i, el){
		var slick_options = {
			'prevArrow' : $(el).find('[data-js="slideshow-arrow"]').filter('.prev'),
			'nextArrow' : $(el).find('[data-js="slideshow-arrow"]').filter('.next'),
			autoplay: true,
			// arrows: false,
			fade: true,
			infinite: true,
			autoplaySpeed: 2000,
			pauseOnHover: false,
			speed: 1000
			// slidesToShow: 3,
			// centerMode: false,
			// variableWidth: true,
			// responsive: [
			//     {
			//       breakpoint: 8000,
			//       settings: {
			//         centerMode: false,
			//         variableWidth: true,
			//       }
			//     },
			//     {
			//       breakpoint: 768,
			//       settings: {
			//         centerMode: false,
			//         variableWidth: false,
			//         infinite: true
			//       }
			//     }
			//     // You can unslick at a given breakpoint now by adding:
			//     // settings: "unslick"
			//     // instead of a settings object
			//   ]
			// asNavFor: '.imm__slide__titres__items, .imm__slide__txts__items'
			// appendDots: $(el).prev(),
			// appendDots: $(el).parent().find('[data-js="slickdots"]'),
			// dots: true,
			// dotsClass : 'slickslide__navs'
		};
		$(el).find('[data-js="slideshow-items"]').slick(slick_options);

		// On before slide change
		$(el).find('[data-js="slideshow-items"]').on('beforeChange', function(event, slick, currentSlide, nextSlide){
			// console.log(slick);
			// console.log(currentSlide);
			// console.log(nextSlide);
			$(el).find('[data-js="slick-nb-item"]').removeClass('is-active')
				.eq( nextSlide ).addClass('is-active')
		});

		$(el).find('[data-js="slick-nb-item"]').eq(0).addClass('is-active')
		
		var options = $(el).attr('data-js-options');
		
		if ( $(el).attr('data-js-options') == 'slick-with-navs' ) {
			var size = $(el).find('[data-js="slick-nav-item"]').size();
			// custom navs
			$(el).find('[data-js="slick-nav-item"]').click(function(){
				var index = $(this).index();
				// console.log('index', index);
				$(el).find('[data-js="slick-items"]').slick('slickGoTo', index);
			});
			// On before slide change
			$(el).find('[data-js="slick-items"]').on('beforeChange', function(event, slick, currentSlide, nextSlide){
				// console.log(slick);
				// console.log(currentSlide);
				// console.log(nextSlide);
				$(el).find('[data-js="slick-nav-item"]').removeClass('is-active')
					.eq( nextSlide ).addClass('is-active')
			});

			$(el).find('[data-js="slick-nav-item"]').first().addClass('is-active').css({'opacity': 1});
		}

		// $(el).find('[data-fancybox]').each(function(i, el){
		// 	$(el).attr('data-fancybox', 'xxx'+(Math.round(Math.random() * 999999)))
		// })
	});

	$(el).find('[data-js="slideshow-mono"]').each(function(i, el){
		var slick_options = {
			'prevArrow' : $(el).find('[data-js="slideshow-arrow"]').filter('.prev'),
			'nextArrow' : $(el).find('[data-js="slideshow-arrow"]').filter('.next'),
			autoplay: true,
			// arrows: false,
			// fade: true,
			infinite: true,
			autoplaySpeed: 2000,
			pauseOnHover: true,
			speed: 1000
			// slidesToShow: 3,
			// centerMode: false,
			// variableWidth: true,
			// responsive: [
			//     {
			//       breakpoint: 8000,
			//       settings: {
			//         centerMode: false,
			//         variableWidth: true,
			//       }
			//     },
			//     {
			//       breakpoint: 768,
			//       settings: {
			//         centerMode: false,
			//         variableWidth: false,
			//         infinite: true
			//       }
			//     }
			//     // You can unslick at a given breakpoint now by adding:
			//     // settings: "unslick"
			//     // instead of a settings object
			//   ]
			// asNavFor: '.imm__slide__titres__items, .imm__slide__txts__items'
			// appendDots: $(el).prev(),
			// appendDots: $(el).parent().find('[data-js="slickdots"]'),
			// dots: true,
			// dotsClass : 'slickslide__navs'
		};
		$(el).find('[data-js="slideshow-items"]').slick(slick_options);

		// On before slide change
		$(el).find('[data-js="slideshow-items"]').on('beforeChange', function(event, slick, currentSlide, nextSlide){
			// console.log(slick);
			// console.log(currentSlide);
			// console.log(nextSlide);
			$(el).find('[data-js="slick-nb-item"]').removeClass('is-active')
				.eq( nextSlide ).addClass('is-active')
		});

		$(el).find('[data-js="slick-nb-item"]').eq(0).addClass('is-active')
		
		var options = $(el).attr('data-js-options');
		
		if ( $(el).attr('data-js-options') == 'slick-with-navs' ) {
			var size = $(el).find('[data-js="slick-nav-item"]').size();
			// custom navs
			$(el).find('[data-js="slick-nav-item"]').click(function(){
				var index = $(this).index();
				// console.log('index', index);
				$(el).find('[data-js="slick-items"]').slick('slickGoTo', index);
			});
			// On before slide change
			$(el).find('[data-js="slick-items"]').on('beforeChange', function(event, slick, currentSlide, nextSlide){
				// console.log(slick);
				// console.log(currentSlide);
				// console.log(nextSlide);
				$(el).find('[data-js="slick-nav-item"]').removeClass('is-active')
					.eq( nextSlide ).addClass('is-active')
			});

			$(el).find('[data-js="slick-nav-item"]').first().addClass('is-active').css({'opacity': 1});
		}

		// $(el).find('[data-fancybox]').each(function(i, el){
		// 	$(el).attr('data-fancybox', 'xxx'+(Math.round(Math.random() * 999999)))
		// })
	});

	$(el).find('[data-js="slideshow-plan"]').each(function(i, el){
		var slick_options = {
			'prevArrow' : $(el).find('[data-js="slideshow-arrow"]').filter('.prev'),
			'nextArrow' : $(el).find('[data-js="slideshow-arrow"]').filter('.next'),
			// autoplay: true,
			// arrows: false,
			fade: true,
			infinite: true,
			// autoplaySpeed: 2000,
			pauseOnHover: true,
			speed: 1000
			// slidesToShow: 3,
			// centerMode: false,
			// variableWidth: true,
			// responsive: [
			//     {
			//       breakpoint: 8000,
			//       settings: {
			//         centerMode: false,
			//         variableWidth: true,
			//       }
			//     },
			//     {
			//       breakpoint: 768,
			//       settings: {
			//         centerMode: false,
			//         variableWidth: false,
			//         infinite: true
			//       }
			//     }
			//     // You can unslick at a given breakpoint now by adding:
			//     // settings: "unslick"
			//     // instead of a settings object
			//   ]
			// asNavFor: '.imm__slide__titres__items, .imm__slide__txts__items'
			// appendDots: $(el).prev(),
			// appendDots: $(el).parent().find('[data-js="slickdots"]'),
			// dots: true,
			// dotsClass : 'slickslide__navs'
		};
		$(el).find('[data-js="slideshow-items"]').slick(slick_options);

		// On before slide change
		$(el).find('[data-js="slideshow-items"]').on('beforeChange', function(event, slick, currentSlide, nextSlide){
			// console.log(slick);
			// console.log(currentSlide);
			// console.log(nextSlide);
			$(el).find('[data-js="slick-nb-item"]').removeClass('is-active')
				.eq( nextSlide ).addClass('is-active')
		});

		$(el).find('[data-js="slick-nb-item"]').eq(0).addClass('is-active')
		
		var options = $(el).attr('data-js-options');
		
		// if ( $(el).attr('data-js-options') == 'slick-with-navs' ) {
			var size = $(el).find('[data-js="slick-nav-item"]').size();
			// custom navs
			$(el).find('[data-js="slick-nav-item"]').click(function(){
				var index = $(this).index();
				// console.log('index', index);
				$(el).find('[data-js="slideshow-items"]').slick('slickGoTo', index);
			});
			// On before slide change
			$(el).find('[data-js="slideshow-items"]').on('beforeChange', function(event, slick, currentSlide, nextSlide){
				// console.log(slick);
				// console.log(currentSlide);
				// console.log(nextSlide);
				$(el).find('[data-js="slick-nav-item"]').removeClass('is-active')
					.eq( nextSlide ).addClass('is-active')
			});

			$(el).find('[data-js="slick-nav-item"]').first().addClass('is-active').css({'opacity': 1});
		// }

		// $(el).find('[data-fancybox]').each(function(i, el){
		// 	$(el).attr('data-fancybox', 'xxx'+(Math.round(Math.random() * 999999)))
		// })
	});

	$(el).find('[data-js="insta-slideshow"]').each(function(i, el){
		var slick_options = {
			'prevArrow' : $(el).find('[data-js="home-slideshow-arrow"]').filter('.prev'),
			'nextArrow' : $(el).find('[data-js="home-slideshow-arrow"]').filter('.next'),
			autoplay: false,
			// arrows: false,
			// fade: true,
			infinite: true,
			autoplaySpeed: 4000,
			pauseOnHover: true,
			speed: 1000,
			slidesToShow: 3,
			slidesToScroll: 3,
			swipeToSlide: true,
			responsive: [
			    {
			      breakpoint: 8000,
			      settings: {
			        slidesToShow: 5,
			        slidesToScroll: 1
			      }
			    },
			    {
			      breakpoint: 768,
			      settings: {
			        slidesToShow: 2,
			        slidesToScroll: 1
			      }
			    }
			    // You can unslick at a given breakpoint now by adding:
			    // settings: "unslick"
			    // instead of a settings object
			  ]
			// asNavFor: '.imm__slide__titres__items, .imm__slide__txts__items'
			// appendDots: $(el).prev(),
			// appendDots: $(el).parent().find('[data-js="slickdots"]'),
			// dots: true,
			// dotsClass : 'slickslide__navs'
		};
		$(el).find('[data-js="slideshow-items"]').slick(slick_options);

		// On before slide change
		$(el).find('[data-js="home-slideshow-items"]').on('beforeChange', function(event, slick, currentSlide, nextSlide){
			// console.log(slick);
			// console.log(currentSlide);
			// console.log(nextSlide);
			$(el).find('.home__video__txts').removeClass('is-active')
				.eq( nextSlide ).addClass('is-active')
		});

		$(el).find('.home__video__txts').eq(0).addClass('is-active')
		
		var options = $(el).attr('data-js-options');
		
		if ( $(el).attr('data-js-options') == 'slick-with-navs' ) {
			var size = $(el).find('[data-js="slick-nav-item"]').size();
			// custom navs
			$(el).find('[data-js="slick-nav-item"]').click(function(){
				var index = $(this).index();
				// console.log('index', index);
				$(el).find('[data-js="slick-items"]').slick('slickGoTo', index);
			});
			// On before slide change
			$(el).find('[data-js="slick-items"]').on('beforeChange', function(event, slick, currentSlide, nextSlide){
				// console.log(slick);
				// console.log(currentSlide);
				// console.log(nextSlide);
				$(el).find('[data-js="slick-nav-item"]').removeClass('is-active')
					.eq( nextSlide ).addClass('is-active')
			});

			$(el).find('[data-js="slick-nav-item"]').first().addClass('is-active').css({'opacity': 1});
		}

		// $(el).find('[data-fancybox]').each(function(i, el){
		// 	$(el).attr('data-fancybox', 'xxx'+(Math.round(Math.random() * 999999)))
		// })
	});


	$(el).find('[data-js="page-slideshow"]').each(function(i, el){
		var slick_options = {
			'prevArrow' : $(el).find('[data-js="home-slideshow-arrow"]').filter('.prev'),
			'nextArrow' : $(el).find('[data-js="home-slideshow-arrow"]').filter('.next'),
			// autoplay: true,
			arrows: true,
			fade: true,
			infinite: true,
			// autoplaySpeed: 4000,
			pauseOnHover: false,
			speed: 1000
			// asNavFor: '.imm__slide__titres__items, .imm__slide__txts__items'
			// appendDots: $(el).prev(),
			// appendDots: $(el).parent().find('[data-js="slickdots"]'),
			// dots: true,
			// dotsClass : 'slickslide__navs'
		};
		$(el).find('[data-js="page-slideshow-items"]').slick(slick_options);

		// On before slide change
		$(el).find('[data-js="page-slideshow-items"]').on('beforeChange', function(event, slick, currentSlide, nextSlide){
			// console.log(slick);
			// console.log(currentSlide);
			// console.log(nextSlide);
			$(el).find('.page__video__txts').removeClass('is-active')
				.eq( nextSlide ).addClass('is-active')

			if ( slick.$slides.eq(nextSlide).find('.slideshow__item__video').size() > 0 ){
				var video_wrap = slick.$slides.eq(nextSlide).find('.slideshow__item__video');
				if ( video_wrap.find('[data-video-simple="cta"]').size() ){
					// html 5 video
					$(window).trigger('playhtml5video', video_wrap.find('[data-video-simple="cta"]'));
				}
				if ( video_wrap.find('div[data-action="embedvideoframe"]').size() ){
					// html 5 video
					$(window).trigger('playevfvideo', video_wrap.find('div[data-action="embedvideoframe"]'));
				}
			}

			if ( slick.$slides.eq(currentSlide).find('.slideshow__item__video').size() > 0 ){
				var video_wrap = slick.$slides.eq(currentSlide).find('.slideshow__item__video');
				if ( video_wrap.find('[data-video-simple="cta"]').size() ){
					// html 5 video
					setTimeout(function() {
						$(window).trigger('pausehtml5video', video_wrap.find('[data-video-simple="cta"]'));
					}, 1000);
				}
				if ( video_wrap.find('div[data-action="embedvideoframe"]').size() ){
					// html 5 video
					setTimeout(function() {
						$(window).trigger('pauseevfvideo', video_wrap.find('div[data-action="embedvideoframe"]'));
					}, 1000);
				}
			}
		});

		$(el).find('.page__video__txts').eq(0).addClass('is-active')
		
		var options = $(el).attr('data-js-options');
		// alert('a')
		
		if ( $(el).attr('data-js-options') == 'slick-with-navs' ) {
			// alert('v')
			var size = $(el).find('[data-js="slick-nav-item"]').size();
			// custom navs
			$(el).find('[data-js="slick-nav-item"]').click(function(){
				var index = $(this).index();
				// alert(index)
				// console.log('index', index);
				$(el).find('[data-js="page-slideshow-items"]').slick('slickGoTo', index);
			});
			// On before slide change
			$(el).find('[data-js="page-slideshow-items"]').on('beforeChange', function(event, slick, currentSlide, nextSlide){
				// console.log(slick);
				// console.log(currentSlide);
				// console.log(nextSlide);
				$(el).find('[data-js="slick-nav-item"]').removeClass('is-active')
					.eq( nextSlide ).addClass('is-active')
			});

			$(el).find('[data-js="slick-nav-item"]').first().addClass('is-active').css({'opacity': 1});
		}

		// $(el).find('[data-fancybox]').each(function(i, el){
		// 	$(el).attr('data-fancybox', 'xxx'+(Math.round(Math.random() * 999999)))
		// })
	});

});

$(window).on('site:initPage', function(evt, el){

	$(el).find('[data-js="nbs-slide"]').each(function(i, el){
		var slick_options = {
			'prevArrow' : $(el).find('[data-js="nbs-slide-arrow"]').filter('.prev'),
			'nextArrow' : $(el).find('[data-js="nbs-slide-arrow"]').filter('.next'),
			// autoplay: true,
			// arrows: false,
			fade: false,
			infinite: true,
			// autoplaySpeed: 5000,
			pauseOnHover: false
			// asNavFor: '.imm__slide__titres__items, .imm__slide__txts__items'
			// appendDots: $(el).prev(),
			// appendDots: $(el).parent().find('[data-js="slickdots"]'),
			// dots: true,
			// dotsClass : 'slickslide__navs'
		};
		$(el).find('[data-js="nbs-slide-items"]').slick(slick_options);

		// On before slide change
		// $(el).find('[data-js="nb-slide-items"]').on('beforeChange', function(event, slick, currentSlide, nextSlide){
		// 	// console.log(slick);
		// 	// console.log(currentSlide);
		// 	// console.log(nextSlide);
		// 	$(el).find('[data-js="nb-slide-nb"]').removeClass('is-active')
		// 		.eq( nextSlide ).addClass('is-active')
		// });
		
		var options = $(el).attr('data-js-options');
		
		if ( $(el).attr('data-js-options') == 'slick-with-navs' ) {
			var size = $(el).find('[data-js="slick-nav-item"]').size();
			// custom navs
			$(el).find('[data-js="slick-nav-item"]').click(function(){
				var index = $(this).index();
				// console.log('index', index);
				$(el).find('[data-js="slick-items"]').slick('slickGoTo', index);
			});
			// On before slide change
			$(el).find('[data-js="slick-items"]').on('beforeChange', function(event, slick, currentSlide, nextSlide){
				// console.log(slick);
				// console.log(currentSlide);
				// console.log(nextSlide);
				$(el).find('[data-js="slick-nav-item"]').removeClass('is-active')
					.eq( nextSlide ).addClass('is-active')
			});

			$(el).find('[data-js="slick-nav-item"]').first().addClass('is-active').css({'opacity': 1});
		}

		// $(el).find('[data-fancybox]').each(function(i, el){
		// 	$(el).attr('data-fancybox', 'xxx'+(Math.round(Math.random() * 999999)))
		// })
	});

});


$(window).on('site:initPage', function(evt, p){

	$(p).find('[data-js="people-slide"]').each(function(i, el){
		
		$(el).find('[data-js="people-slide-items"]').lightSlider({
			'itemsSelector' : '.about__equipe__blocs__item',
			// 'arrowsItem' : $( el ).find( '.bloc__slideshow__arrow' ).get(),
			// 'arrowLeftClass' : 'bloc__slideshow__arrow--left',
			// 'autoPlay' : true,
			'autoPlay' : false,
			'stopOnHover' : false,
			'onshowitem': bloc_slide_change,
			// 'autoPlayDelay' : 8000,
			// 'navsItem': $(el).find('.team__slide__nav'),
			arrowsItem: $(el).find('[data-js="people-slide-arrow"]'),
			arrowLeftClass: 'prev'
		});

		// $(el).find('.about__equipe__blocs__item').first().addClass('is-active').css({'opacity': 1});
		// $(el).find('[data-js="slick-nav-item"]').first().addClass('is-active');
		var currentIndex = 0;
		var els = $(el).find('.about__equipe__blocs__item');
		gsap.set( els, {
			opacity: 0,
			zIndex: 2
		} );
		gsap.set( els[0], {
			opacity: 1,
			zIndex: 5
		} );
		gsap.set( els[1], {
			opacity: 1,
			rotation: 20,
			zIndex: 4,
			scale: 0.9
			// blur: 8
		} );
		$(el).find('.about__equipe__blocs__name__item').eq(0).addClass('is-active');
	});

});

$(window).on('site:initPage', function(evt, el){

	$(el).find('[data-js="history-slideshow"]').each(function(i, el){
		var slick_options = {
			'prevArrow' : $(el).find('[data-js="history-slideshow-arrow"]').filter('.prev'),
			'nextArrow' : $(el).find('[data-js="history-slideshow-arrow"]').filter('.next'),
			// autoplay: true,
			// arrows: false,
			// fade: true,
			infinite: true,
			// autoplaySpeed: 5000,
			pauseOnHover: false,
			vertical: true,
			verticalSwiping: true
			// asNavFor: '.imm__slide__titres__items, .imm__slide__txts__items'
			// appendDots: $(el).prev(),
			// appendDots: $(el).parent().find('[data-js="slickdots"]'),
			// dots: true,
			// dotsClass : 'slickslide__navs'
		};
		$(el).find('[data-js="history-slideshow-items"]').slick(slick_options);

		// On before slide change
		// $(el).find('[data-js="history-slideshow-items"]').on('beforeChange', function(event, slick, currentSlide, nextSlide){
		// 	// console.log(slick);
		// 	// console.log(currentSlide);
		// 	// console.log(nextSlide);
		// 	$(el).find('[data-js="history-slideshow-nb"]').removeClass('is-active')
		// 		.eq( nextSlide ).addClass('is-active')
		// });
		
		var options = $(el).attr('data-js-options');
		
		if ( $(el).attr('data-js-options') == 'slick-with-navs' ) {
			var size = $(el).find('[data-js="slick-nav-item"]').size();
			// custom navs
			$(el).find('[data-js="slick-nav-item"]').click(function(){
				var index = $(this).index();
				// console.log('index', index);
				$(el).find('[data-js="slick-items"]').slick('slickGoTo', index);
			});
			// On before slide change
			$(el).find('[data-js="slick-items"]').on('beforeChange', function(event, slick, currentSlide, nextSlide){
				// console.log(slick);
				// console.log(currentSlide);
				// console.log(nextSlide);
				$(el).find('[data-js="slick-nav-item"]').removeClass('is-active')
					.eq( nextSlide ).addClass('is-active')
			});

			$(el).find('[data-js="slick-nav-item"]').first().addClass('is-active').css({'opacity': 1});
		}

		// $(el).find('[data-fancybox]').each(function(i, el){
		// 	$(el).attr('data-fancybox', 'xxx'+(Math.round(Math.random() * 999999)))
		// })
	});

});

