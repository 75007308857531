var $ = require('jquery');

module.exports = function(evt, el){
	// alert( 'yy' )
	// return true; // disabled
	// console.log($(el).attr('target'), el); 
	if ( $(el).attr('data-is-logout') == '1' ) {
		evt.preventDefault();
		localStorage.setItem('islogged', 0);
		performOfflineLogout();
		return false;
	}
	// last_el_clicked = el;
	// alert( $(el).attr('data-no-ajax') )
	// alert($(el).attr('role'))
	if ( $(el).attr('target') == '_blank' ) return true;
	if ( $(el).attr('data-no-ajax') == '1' ) return true;
	if ( $(el).attr('role') == 'button' ) return false;
	if ( $(el).attr('data-link-film') == '1' ) {
		// show film popup
		evt.preventDefault();
		$('[data-js="popup-video"]').popupvideo('start');
		return false;
	}

	var url = $(el).attr('href');
	// alert( url )
	// alert( url.indexOf('tel:') )
	if ( url.indexOf('tel:') >= 0 || url.indexOf('mailto:') >= 0 ) {
		return true;
	}

	// var scroll_to_id = $(el).attr('data-js-scroll-to');
	// if ( scroll_to_id && scroll_to_id.length ) {
	// 	var offset = $('[data-scroll-to-target="'+scroll_to_id+'"]').offset().top;
	// 	// console.log('offset', offset);
	// 	var duration = 1;
	// 	if ( window.matchMedia("only screen and (max-width: 767px)").matches ) {
	// 		duration = 0.01
	// 	}
	// 	TweenMax.to(window, duration, {
	// 		scrollTo: offset,
	// 		ease: Power2.easeInOut
	// 	});
	// 	setTimeout(function() {
	// 		$(window).trigger("site:needto:closemenu");
	// 	}, duration * 1000);
	// 	// return false;
	// }
	// disabled for mobile device
	// if ( window.matchMedia("only screen and (max-width: 767px)").matches ) {
	// 	return true;
	// }

	// if ( funcs.linkClicked ) {
	// 	var ret = funcs.linkClicked($(el));
	// 	if ( ret === true ) {
	// 		// alert('will return true')
	// 		return true;
	// 	}
	// }
	evt.preventDefault();
	var url = $(el).attr('href');
	// console.log('url', url, History.getState().url);
	if ( url == History.getState().url && $('[data-is-webapp="1"]').size() === 0 ) {
		// if ( funcs.onSameUrlClicked ) {
			// alert('z')
			// this.initCurrentPage();
		return false;
		// }
	}
	if ( this.animatorObj ) {
		console.log('stop and complete animation');
		this.animatorObj.stopAndComplete();
	}
	if ( $( el ).hasClass( 'prod__cat__item__link' ) ) {
		// alert( 'add class' )
		// alert( $( el ).size(  ) )
		// alert( $( '.link-with-mover-effect' ).size(  ) )
		$( el ).addClass( 'link-with-mover-effect' );
		// alert( $( '.link-with-mover-effect' ).size(  ) )
	}
	// paralax( false );
	this.goTo( url );
	// History.pushState(null, null, url);
	return false;
};