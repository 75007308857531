var $ = require('jquery');

var is_ui_freezed = false;

var blockScroll = function (event) {
	event.preventDefault();
	event.stopPropagation();
	return false;
};

var change = function(){
	// is_ui_freezed = !is_ui_freezed;
	// alert('freeze ui')
	// alert(is_ui_freezed)
	$('html')
		.toggleClass('is-ui-freezed', is_ui_freezed);
	if ( $('html').hasClass('no-ui-freeze') == false ){
		$('html')[is_ui_freezed ? 'on' : 'off']("scroll touchmove mousewheel", blockScroll);
	}
};

var freeze = function(){
	is_ui_freezed = true;
	change();
};

var unfreeze = function(){
	is_ui_freezed = false;
	change();
};

module.exports = unfreeze;

$(window).on('cymasmooth:onAjaxLoad', freeze);
$(window).on('cymasmooth:onEnd', unfreeze);
