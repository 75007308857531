var $ = require('jquery');
// var $f = require('frogaloop');
var loadScript = require('load-script');
var MQ = require('mq');

var player;
var youtube_api_ready = false;

var evfPlay = function(){
	console.log('evfPlay');
	var el = this;
	$(window).trigger('playevfvideo', el);
};

$(window).on('playevfvideo', function(evt, el){
	if ( $(el).attr('evf-init') == '1' ){
		$(el).data('evfplayer').playVideo();
		return;
	}
	$(el).attr('evf-init', '1');
	$(el).find('div.evf__mask').fadeOut();
	var datas = $(el).data();
	var sep = (datas.vid+''.indexOf('?')>=0)?'&':'?';
	var id = datas.vid+''.split('#')[0];
	var suffix = datas.suffix || '';
	var src = '';
	var iframe_id = 'evfiframe'+Math.round(Math.random()*1000000);
	if (datas.vpr == 'YT') src = 'https://www.youtube-nocookie.com/embed/'+id+'?autoplay=1&controls=1&showinfo=0&enablejsapi=1';
	else if (datas.vpr == 'VM') src = 'https://player.vimeo.com/video/'+id+'?api=1&title=0&byline=0&portrait=0&color=#000&&player_id='+iframe_id;
	else if (datas.vpr == 'DM') src = 'https://www.dailymotion.com/embed/video/'+id+sep+'info=0&autoplay=1';
	else if (datas.vpr == 'WT') src = 'https://www.wat.tv/embedframe/'+id+'?autoStart=1';
	// if (datas.vpr == 'VM') {
	var iframe_html = '<iframe src="'+src+suffix+'" id="'+iframe_id+'" width="100%" height="100%" frameborder="0" allowfullscreen="true" style="background-color:#000000;"  allow="autoplay"/>';
	if ( datas.vpr == 'YT' ) {
		iframe_html = '<div class="evf__fake__iframe" id="'+iframe_id+'"></div>';
	}
	var iframe_el = $(iframe_html)
		.appendTo($(el).find('div.evf__video'));
	if ( datas.vpr != 'YT' && datas.vpr != 'VM' ) {
		iframe_el.on('load', function(){
			iframeLoaded(el);
		});
	}
	if ( datas.vpr == 'VM' ){
		player = new Vimeo.Player(iframe_el.get(0));
		player.play();
		iframeLoaded(el);
	}
	if ( datas.vpr == 'YT' && youtube_api_ready ){
		if ( player ) {
			// player.stopVideo();
			// player.destroy();
			// container.find('div.evf__video').html('');
			// container.find('div.evf__mask').show();
			// container.find('div.evf__image').show();
		}
		// container = container;
		// alert('p')
		player = new YT.Player(iframe_id, {
			videoId: id,
			width: '100%',
			height: '100%',
			events: {
				'onReady': function(event){
					console.log('rady');
					event.target.playVideo();
					console.log('iframeLoaded', iframeLoaded);
					iframeLoaded(el);
				},
				'onStateChange': function(event){
					if(event.data === 0) {  
						// alert('stop')
						// player.destroy();        
					 //    // container.find('div.evf__video').html('');
					 //    container.find('div.evf__mask').show();
					 //    container.find('div.evf__image').show();
					 //    $('iframe').remove();
					 //    player = false;
		            }
					// console.log('sc');
				}
			}
		});
		$(el).data('evfplayer', player);
	}
});



$(window).on('pauseevfvideo', function(evt, el){
	if ( $(el).attr('evf-init') == '1' ){
		$(el).data('evfplayer').pauseVideo();
		return;
	}
});

var iframeLoaded = function(el){
	$(el).find('div.evf__image').fadeOut();
	$(el).addClass('is-video-playing');
};

var videoHtmlPlay = function(){
	var el = this;
	$(window).trigger('playhtml5video', el);
};

$(function(){
	// if ( MQ(768) ){
		$( document ).on('click', 'div[data-action="embedvideoframe"]', evfPlay);
	// }
	$( document ).on('click', '[data-video-simple="cta"]', videoHtmlPlay);
});

$(window).on('playhtml5video', function(evt, el){
	var p = $(el).closest('[data-js="video-simple"]');
	var video = $(p).find('[data-video-simple="video"]').get(0);
	$(video).attr('controls', 'controls');
	// video.currentTime  = 0;
	video.play();
	$(el).addClass('is-video-playing');
});

$(window).on('pausehtml5video', function(evt, el){
	var p = $(el).closest('[data-js="video-simple"]');
	var video = $(p).find('[data-video-simple="video"]').get(0);
	// $(video).attr('controls', 'controls');
	// video.currentTime  = 0;
	video.pause();
	$(el).addClass('is-video-playing');
});



var loaderDone = {};
$(window).on('site:initPage', function(evt, page){
	$(page).find('div[data-action="embedvideoframe"]').each(function(i, el){
		var datas = $(el).data();
		if ( datas.vpr == "YT" && !loaderDone["YT"] ){
			loadScript("https://www.youtube.com/player_api");
			window.onYouTubeIframeAPIReady = function() {
				youtube_api_ready = true;
			};
			loaderDone["YT"] = true;
		}
		if ( datas.vpr == "VM" && !loaderDone["VM"] ){
			loadScript("https://player.vimeo.com/api/player.js");
			// window.onYouTubeIframeAPIReady = function() {
			// 	youtube_api_ready = true;
			// };
			loaderDone["VM"] = true;
		}
	});
});

